import { Fragment, useRef, useState } from "react";
import {
	correctDataUTC,
	getDanfe,
	getDanfeVST,
	getStatusPedidos,
	getXMLString,
	locationDev,
	updateTableNew,
	valorMoeda,
} from "../../../services/Servicos";
import ModalAtualizarRomaneios from "../../modal/ModalAtualizarRomaneios";
import ModalListarProdutos from "../../modal/ModalListarProdutos";
import { QRCodeSVG } from "qrcode.react";

import "./BodyRow.scss";
import { useSelector } from "react-redux";
import ModalImprimir from "../../modal/ModalImprimir";
import ModalVisualizarPDF from "../../modal/ModalVisualizarPDF";
import { Card, Spinner } from "react-bootstrap";
import ModalUploadImg from "../../modal/ModalUploadImg";
import ModalFotoReport from "../../modal/ModalFotoReport";
import ModalVisualizarPDFComputer from "../../modal/ModalVisualizarPDFComputer";
import ModalError from "../../modal/ModalError";
import ModalVisualizarDanfe from "../../modal/ModalVisualizarDanfe";
import ModalControleFoto from "../../modal/ModalControleFoto";
import ModalEditarRow from "../../modal/ModalEditarRow";

export default function BodyRowRomaneios({
	headList,
	bodyList,
	index,
	tableLength,
	setCargaSelecionada = () => {},
	setArrayFilters = () => {},
}) {
	const refRow = useRef(null);
	const refEditar = useRef(null);
	const refExcluir = useRef(null);
	const user = useSelector((state) => state.userSlice);
	const filiaisList = user.filiaisList.data;
	const [loading, setLoading] = useState(false);

	const [adminFunction, setAdminFunction] = useState({
		error: {
			flag: false,
			title: "",
			msg: "",
			confirmar: "",
		},
	});
	const [editarRow, setEditarRow] = useState({ show: false, title: "" });

	const [produtosModal, setProdutosModal] = useState({
		flag: false,
		numero_pedido: "",
	});
	const [imprimir, setImprimir] = useState({
		flag: false,
		title: "",
		msg_obj: {},
	});

	const onClickExcluirRow = (e) => {
		e.preventDefault();

		setAdminFunction({
			error: {
				flag: true,
				title: "Excluir Romaneio",
				msg: {
					title: `Deseja excluir o romaneio:`,
					// msg: `Tipo: ${ref1}; Nome: ${ref2}; CPF: ${ref3}; ID: ${ref4}`
					msg: {
						ROMANEIO: bodyList.romaneio,
						PEDIDO: bodyList.numero_pedido,
						RAZAO: bodyList.razao_social,
						CPF_CNPJ: bodyList.cpf_cnpj,
						CARGAS_ID: bodyList.cargas_id,
					},
					bodyList,
				},
				confirmar: "Excluir",
			},
		});
	};
	const onClickEditarRow = (e) => {
		e.preventDefault();
		setEditarRow({ show: true, title: "Editar Romaneio", row: bodyList });
	};
	const fnRetornoEditRow = async (bodyEdit, romaneio) => {
		try {
			await updateTableNew(`pedidos?romaneio=${romaneio}`, bodyEdit);
		} catch (error) {}
	};

	const onClickPlusRow = (e) => {
		e.preventDefault();

		setProdutosModal({
			flag: true,
			pedido: bodyList,
		});
	};

	const onClickTableRow = (e) => {
		e.preventDefault();
		refRow.current.classList = "bg-selected";
		let refRowNumber = index;

		let i = refRowNumber;
		let actualSibling = refRow.current.nextSibling;

		while (i < tableLength) {
			if (actualSibling === null) {
				break;
			}
			actualSibling.classList = "";

			actualSibling = actualSibling.nextSibling;
			i++;
		}

		i = index;
		actualSibling = refRow.current.previousSibling;
		while (0 < i) {
			if (actualSibling === null) {
				break;
			}
			actualSibling.classList = "";
			actualSibling = actualSibling.previousSibling;
			i--;
		}

		const id = refRow.current.children[1].innerText;
		const nome = refRow.current.children[2].innerText;
		setCargaSelecionada({ id, nome });
	};

	const onClickPrint = (e) => {
		setImprimir({
			flag: true,
			msg_obj: bodyList,
			title: "Imprimir Romaneio",
		});
	};

	const [verPDF, setVerPDF] = useState({
		show: false,
		PDF: "",
		title: "",
	});
	const [verPDFAssinado, setVerPDFAssinado] = useState({
		show: false,
		PDF: "",
		title: "",
	});
	const [fotoProdutoEntregue, setFotoProdutoEntregue] = useState({
		flag: false,
		imgSrc: "",
	});

	const [error, setError] = useState({
		flag: false,
	});

	const findDanfe = async (e, bodyList_) => {
		setLoading(true);

		const { numero_pedido, id_filial, apelidoFantasia } = bodyList_;

		setVerPDF({
			show: true,
			PDF: await getDanfeVST(id_filial, numero_pedido),
			title: "Danfe",
		});

		setError((last) => ({
			...last,
			numero_pedido: "",
		}));
		setLoading(false);
	};

	const [handlerControleFoto, setHandlerControleFoto] = useState({
		flag: false,
	});

	return (
		<Fragment>
			{
				<tr ref={refRow} onClick={onClickTableRow}>
					{headList.map((item, _index) => {
						if (item === "index") {
							return <td key={_index}> {index + 1}</td>;
						} else if (item === "suporte") {
							return (
								<td key={_index}>
									<div className="admin" style={{ alignItems: "baseline" }}>
										<span className="excluir">
											<i
												className="bi bi-x-lg"
												title="Excluir"
												onClick={onClickExcluirRow}
												ref={refExcluir}
											></i>
										</span>
										<span className="imprimir">
											<i
												className="bi bi-printer"
												title="Imprimir Romaneio"
												onClick={onClickPrint}
												ref={refEditar}
											></i>
										</span>
										<span className="verificar">
											<i
												className="bi bi-plus-circle"
												title="Verificar Produtos"
												onClick={onClickPlusRow}
												ref={refEditar}
											></i>
										</span>

										{
											loading ? (
												<Spinner animation="border" size="sm" />
											) : (
												<i
													className="bi bi-file-earmark-pdf"
													onClick={(e) => {
														findDanfe(e, bodyList);
													}}
													style={{ cursor: "pointer" }}
												></i>
											)
											// <i className="bi bi-file-earmark-pdf" onClick={e => {findDanfe(e, bodyList)}} style={{cursor:'pointer'}}></i>
										}
									</div>
								</td>
							);
						} else if (item === "admin") {
							return (
								<td key={_index} style={{ whiteSpace: "nowrap" }}>
									<div className="admin" style={{ alignItems: "baseline" }}>
										{bodyList.status != 0 &&
										user.email.toUpperCase() != "CODJI.SUPORTE@GMAIL.COM" ? (
											""
										) : (
											<>
												<span className="excluir">
													<i
														className="bi bi-x-lg"
														title="Excluir"
														onClick={onClickExcluirRow}
														ref={refExcluir}
													></i>
												</span>
												{user.email.toUpperCase() ==
												"CODJI.SUPORTE@GMAIL.COM" ? (
													<span className="editar">
														<i
															className="bi bi-pencil"
															title="Editar"
															onClick={onClickEditarRow}
															ref={refExcluir}
														></i>
													</span>
												) : (
													""
												)}
											</>
										)}
										<span className="imprimir">
											<i
												className="bi bi-printer"
												title="Imprimir Romaneio"
												onClick={onClickPrint}
												ref={refEditar}
											></i>
										</span>
										<span className="verificar">
											<i
												className="bi bi-plus-circle"
												title="Verificar Produtos"
												onClick={onClickPlusRow}
												ref={refEditar}
											></i>
										</span>

										{
											loading ? (
												<Spinner animation="border" size="sm" />
											) : (
												<i
													className="bi bi-file-earmark-pdf"
													onClick={(e) => {
														findDanfe(e, bodyList);
													}}
													style={{ cursor: "pointer" }}
												></i>
											)
											// <i className="bi bi-file-earmark-pdf" onClick={e => {findDanfe(e, bodyList)}} style={{cursor:'pointer'}}></i>
										}
									</div>
								</td>
							);
						} else if (item === "id_filial") {
							/* primary key can not be modified */
							return (
								<td key={_index}>
									{filiaisList.filter(
										(item) => item.idFilial == bodyList.id_filial
									)[0]?.identificacaoInterna ?? "NÃO INFORMADO"}
								</td>
							);
						} else if (item === "danfe") {
							return (
								<td key={_index}>
									<div className="admin">
										<span className="excluir">
											{!bodyList[item] ? (
												"Sem Arquvio"
											) : (
												<i
													className="bi bi-file-earmark-pdf"
													onClick={(e) => {
														setVerPDFAssinado({
															show: true,
															PDF: locationDev + "/danfe/" + bodyList[item],
															title: "Danfe Assinada",
														});
													}}
													style={{ cursor: "pointer" }}
												>
													Assinado
												</i>
											)}
										</span>
									</div>
								</td>
							);
						} else if (item === "assinar") {
							return (
								<td key={_index}>
									<div className="admin">
										<span className="excluir">
											<i className="bi bi-pencil-square" title="Assinar"></i>
										</span>
									</div>
								</td>
							);
						} else if (item === "romaneio") {
							return (
								<td key={_index} className={item}>
									{" "}
									{String(bodyList[item]).padStart(7, "0")}
								</td>
							);
						} else if (item === "emissao") {
							return <td key={_index}> {correctDataUTC(bodyList.emissao)}</td>;
						} else if (item === "updated_at") {
							return (
								<td key={_index} className="rowDataWidth">
									{" "}
									{correctDataUTC(bodyList.updatedAt)}
								</td>
							);
						} else if (item === "created_at") {
							return (
								<td key={_index} className="rowDataWidth">
									{" "}
									{correctDataUTC(bodyList.createdAt)}
								</td>
							);
						} else if (item === "valor_total") {
							return (
								<td key={_index} className="rowDataWidth">
									{" "}
									{valorMoeda(bodyList.valor_total)}
								</td>
							);
						} else if (item === "status") {
							return (
								<td key={_index} style={{ whiteSpace: "nowrap" }}>
									{bodyList.status >= 24 ? (
										<span className="">
											<i
												className="bi bi-shop"
												title="Finalizado"
												style={{ color: "black" }}
											></i>
										</span>
									) : (
										""
									)}
									{bodyList.status >= 13 && bodyList.status <= 14 ? (
										<span className="">
											<i
												className="bi bi-bookmark-check-fill"
												title="Finalizado"
												style={{ color: "green" }}
											></i>
										</span>
									) : (
										""
									)}
									{bodyList.status < 13 && bodyList.status > 9 ? (
										<span className="">
											<i
												className="bi bi-truck"
												title="Finalizado"
												style={{ color: "black" }}
											></i>
										</span>
									) : (
										""
									)}
									{bodyList.status <= 9 ? (
										<span className="">
											<i
												className="bi bi-boxes"
												title="Finalizado"
												style={{ color: "black" }}
											></i>
										</span>
									) : (
										""
									)}
									{getStatusPedidos(bodyList.status)}
								</td>
							);
						} else if (item === "peso_nf") {
							/* primary key can not be modified */
							return (
								<td key={_index} className="rowDataWidth">
									{Number(bodyList[item]).toLocaleString("pt-br")}
								</td>
							);
						} else if (
							item === "nome_razao" ||
							item === "endereco" ||
							item === "cpf_cnpj" ||
							item === "cep"
						) {
							/* primary key can not be modified */
							return (
								<td key={_index} className="rowDataWidth">
									{bodyList[item]}
								</td>
							);
						} else if (item === "tipo") {
							return (
								<td key={_index} className={item}>
									{bodyList[item] == "ENTREGAR AO CLIENTE" ? (
										<div className="div-status">
											<span
												className="div-status-ativo"
												style={{ backgroundColor: "#F8A232" }}
											>
												{bodyList[item]}
											</span>
										</div>
									) : (
										""
									)}
									{bodyList[item] == "ENTREGAR NA LOJA" ? (
										<div className="div-status">
											<span
												className="div-status-ativo"
												style={{ backgroundColor: "#2D9DD1" }}
											>
												{bodyList[item]}
											</span>
										</div>
									) : (
										""
									)}
									{bodyList[item] == "RETIRAR NA LOJA" ? (
										<div className="div-status">
											<span
												className="div-status-ativo"
												style={{ backgroundColor: "#d1822d" }}
											>
												{bodyList[item]}
											</span>
										</div>
									) : (
										""
									)}
								</td>
							);
						} else if (item === "foto_pedido_entregue") {
							/* primary key can not be modified */

							return (
								<td key={_index} style={{ minWidth: "100px" }}>
									<span className="excluir">
										<i
											className="bi bi-gear me-2"
											style={{ cursor: "pointer" }}
											onClick={() =>
												setHandlerControleFoto({
													flag: true,
													...bodyList,
												})
											}
										></i>
										{bodyList[item] ? bodyList[item] : 0}
									</span>
								</td>
							);
						} else if (item === "qr_code_id") {
							return (
								<td key={_index} className="rowDataWidth">
									<QRCodeSVG value={"{\n" + bodyList.qr_code_id + "}"} />
								</td>
							);
						} else if (["tel_1", "tel_2"].includes(item)) {
							return (
								<td key={_index} style={{ whiteSpace: "nowrap" }}>
									{bodyList[item]}
								</td>
							);
						}
						return (
							<td key={_index} className={item}>
								{" "}
								{bodyList[item]}
							</td>
						);
					})}
				</tr>
			}
			{adminFunction.error.flag ? (
				<ModalAtualizarRomaneios
					title={adminFunction.error.title}
					msg={adminFunction.error.msg}
					show={adminFunction}
					setShow={setAdminFunction}
					msgButton={adminFunction.error.confirmar}
					{...adminFunction}
				/>
			) : (
				""
			)}
			{produtosModal.flag ? (
				<ModalListarProdutos
					msg={""}
					title={"Produtos do Pedido"}
					show={produtosModal}
					setShow={setProdutosModal}
					{...produtosModal}
				/>
			) : (
				""
			)}
			{imprimir.flag ? (
				<ModalImprimir
					title={imprimir.title}
					show={imprimir.flag}
					setShow={setImprimir}
					msg_obj={imprimir.msg_obj}
				/>
			) : (
				""
			)}
			{/* {
                verPDF.show ? <ModalVisualizarPDF title={verPDF.title} pdf={verPDF.PDF} setShow={setVerPDF} show={verPDF.show} /> : ""
            } */}
			{fotoProdutoEntregue.flag ? (
				<ModalFotoReport
					imgSrc={fotoProdutoEntregue.imgSrc}
					setShow={setFotoProdutoEntregue}
					show={fotoProdutoEntregue.flag}
					alternative={fotoProdutoEntregue.alternative}
					title={fotoProdutoEntregue.title}
				/>
			) : (
				""
			)}
			{verPDF.show ? (
				<ModalVisualizarDanfe
					title={verPDF.title}
					pdf={verPDF.PDF}
					setShow={setVerPDF}
					show={verPDF.show}
				/>
			) : (
				""
			)}
			{verPDFAssinado.show ? (
				<ModalVisualizarPDFComputer
					title={verPDFAssinado.title}
					pdf={verPDFAssinado.PDF}
					setShow={setVerPDFAssinado}
					show={verPDFAssinado.show}
				/>
			) : (
				""
			)}
			{error.flag ? (
				<ModalError
					title={error.title}
					msg={error.msg}
					setShow={setError}
					show={error.flag}
				/>
			) : (
				""
			)}

			{handlerControleFoto.flag ? (
				<ModalControleFoto
					show={handlerControleFoto.flag}
					setShow={setHandlerControleFoto}
					{...handlerControleFoto}
				/>
			) : (
				""
			)}
			{editarRow.show ? (
				<ModalEditarRow
					setShow={setEditarRow}
					{...editarRow}
					fnRetorno={fnRetornoEditRow}
				/>
			) : (
				""
			)}
		</Fragment>
	);
}
