import { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./ModalError.scss";

import {
	cancelarManifestoFunc,
	encerrarManifestoFunc,
	getAllData,
	getData,
	getManifestoId,
	getManifestoPDF,
	getManifestoStatus,
	locationDev,
	savalDanfeAssinada,
	uploadImg,
} from "../../services/Servicos";

import "./Modal.scss";
import ModalMontaManifesto from "./ModalMontaManifesto";
import ModalError from "./ModalError";
import ModalVisualizarPDFComputer from "./ModalVisualizarPDFComputer";
import ModalVisualizarDanfe from "./ModalVisualizarDanfe";
import axios from "axios";
import ModalPDF_VerDanfe from "./ModalPDF_VerDanfe";
import { danfeController, fotosController } from "../../redux/slicers/carga";
import ModalErrorPretty from "./ModalErrorPretty";
import ModalSuccessPretty from "./ModalSuccessPretty";

export default function ModalControleFoto(props) {
	const user = useSelector((state) => state.userSlice);
	let fotos = useSelector((state) => state.cargaSlicer.listasFotos);
	fotos = fotos.filter((item) => item.romaneio == props.romaneio);
	const refImgUpload = useRef(null);
	const dispatch = useDispatch();

	const [loadingEnviar, setLoadingEnviar] = useState({
		flag: false,
		msg: "Enviar Fotos",
	});
	const [loadingStatus, setLoadingStatus] = useState({
		location: "pdf",
		flag: true,
	});
	const [error, setError] = useState({ show: false, message: "" });
	const [success, setSuccess] = useState({ show: false, message: "" });
	const [isOnline, setIsOnline] = useState(true);

	const [verPDF, setVerPDF] = useState({
		flag: false,
		PDF: "",
		title: "",
	});

	const handlerSimpleClose = () => {
		props.setShow({ flag: false });
	};

	const [showFotos, setShowFotos] = useState({ show: false });
	const [showFotosEnviadas, setShowFotosEnviadas] = useState({ show: false });

	const handlerEnviarFoto = async () => {
		if (!isOnline)
			return setError({
				show: true,
				message: "Você esta sem intertet. Tente novamente mais tarde.",
			});
		if (!fotos.find((item) => item.status != "Enviada"))
			return setError({
				show: true,
				message: "Nenhuma foto precisa ser enviada!",
			});

		setLoadingEnviar({
			flag: true,
			msg: "Salvando",
		});

		function dataURLtoFile(dataurl, filename) {
			var arr = dataurl.split(","),
				mime = arr[0].match(/:(.*?);/)[1],
				bstr = atob(arr[arr.length - 1]),
				n = bstr.length,
				u8arr = new Uint8Array(n);
			while (n--) {
				u8arr[n] = bstr.charCodeAt(n);
			}
			return new File([u8arr], filename, { type: mime });
		}

		// })))
		// return
		let error = null;

		for await (let fotoL of fotos.filter((item) => item.status != "Enviada")) {
			try {
				const data = await uploadImg(
					"entregas/pedido/upload_img",
					fotoL.cargas_id,
					fotoL.numero_pedido,
					{ foto: dataURLtoFile(fotoL.imgUrl, `Image-${props.numero_pedido}`) }
				);
				dispatch(
					fotosController({
						action: "update",
						id: fotoL.id,
						dados: {
							status: "Enviada",
						},
					})
				);
			} catch (error) {
				error = error;
				break;
			}
		}
		if (error) {
			setError({
				show: true,
				message: "Falha na tentativa de envio das fotos",
			});
			return;
		}
		setLoadingEnviar({
			flag: false,
			msg: "Enviar Fotos",
		});

		setSuccess({ show: true, message: "Todas as Fotos foram Salvas" });
		setTimeout(() => {
			window.location.reload();
		}, 1500);
	};

	const [foto, setFoto] = useState(null);

	const onChangeInputFile = async (e) => {
		e.preventDefault();

		const imageFile = e.target.files[0];
		setFoto(imageFile);
	};

	const uploadFoto = (e) => {
		refImgUpload.current.click();
	};

	useEffect(() => {
		if (!foto) return;
		const newReader = new FileReader();

		newReader.addEventListener("load", () => {
			dispatch(
				fotosController({
					action: "add",
					dados: {
						numero_pedido: props.numero_pedido,
						cargas_id: props.cargas_id,
						romaneio: props.romaneio,
						imgUrl: newReader.result,
						fotoFile: foto,
						id: fotos.length,
						status: "Não Enviada",
					},
				})
			);
		});

		newReader.readAsDataURL(foto);
	}, [foto]);

	useEffect(() => {
		//online
		function toggleOnline() {
			setIsOnline(true);
		}
		function toggleOffline() {
			setIsOnline(false);
		}
		window.addEventListener("online", toggleOnline);
		window.addEventListener("offline", toggleOffline);
		return () => {
			window.removeEventListener("online", toggleOnline);
			window.removeEventListener("offline", toggleOffline);
		};
	}, []);
	return (
		<>
			<Modal
				show={props.show}
				onHide={handlerSimpleClose}
				backdrop="static"
				keyboard={false}
				centered
				size="lg"
			>
				<Modal.Header closeButton>
					<Modal.Title>
						Controle de Foto do Pedido N°{" "}
						<b>
							<i style={{ fontSize: "inherit" }}>{props.numero_pedido}</i>
						</b>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body style={{ padding: "70px 30px" }}>
					<Row className="justify-content-center">
						{user.tipo.toLowerCase() == "caminhoneiro" ? (
							<>
								<Col sm="12" md="6" lg="3">
									<Card className="card-manifesto" onClick={uploadFoto}>
										<span style={{ position: "relative" }}>
											<i
												className="bi bi-image"
												style={{
													fontSize: "3rem",
													opacity: "0.4",
													color: "#347d68",
													filter: "blur(1.5px)",
												}}
											></i>
											<i
												className="bi bi-save"
												style={{
													position: "absolute",
													left: "-15px",
													bottom: "30px",
													fontSize: "2rem",
												}}
											></i>
										</span>
										<Card.Title>
											Carregar Foto
											<Form.Control
												type="file"
												name="teste"
												onChange={onChangeInputFile}
												hidden
												ref={refImgUpload}
											/>
										</Card.Title>
									</Card>
								</Col>
								<Col sm="12" md="6" lg="3">
									<Card
										className={`card-manifesto active-animation-card-manifesto 
                                    ${
																			fotos.length <= 0
																				? "active-animation-danger"
																				: ""
																		}
                                    ${
																			fotos.find(
																				(item) => item.status == "Não Enviada"
																			)
																				? "active-animation-warning"
																				: ""
																		}
                                    ${
																			!fotos.find(
																				(item) => item.status == "Não Enviada"
																			)
																				? "active-animation-success"
																				: ""
																		}
                                    `}
										data-before={
											fotos.length
												? fotos.find((item) => item.status == "Não Enviada")
													? `${
															fotos.filter(
																(item) => item.status == "Não Enviada"
															).length
													  } Não Enviadas`
													: "Enviadas"
												: "Sem Fotos"
										}
										onClick={() => handlerEnviarFoto()}
									>
										<span style={{ position: "relative" }}>
											<i
												className="bi bi-image"
												style={{
													fontSize: "3rem",
													opacity: "0.4",
													color: "#347d68",
													filter: "blur(1.5px)",
												}}
											></i>
											<i
												className="bi bi-send-check"
												style={{
													position: "absolute",
													left: "-15px",
													bottom: "30px",
													fontSize: "2rem",
												}}
											></i>
										</span>
										<Card.Title>
											{loadingEnviar.msg}{" "}
											{loadingEnviar.flag ? (
												<Spinner animation="border" size="sm" />
											) : (
												""
											)}
										</Card.Title>
									</Card>
								</Col>

								<Col sm="12" md="6" lg="3">
									<Card
										className="card-manifesto"
										onClick={() => {
											if (!fotos.length)
												return setError({
													show: true,
													list: "Fotos não Lançadas",
												});
											setShowFotos({
												show: true,
												fotoList: fotos,
												pedido: props,
											});
										}}
									>
										<span style={{ position: "relative" }}>
											<i
												className="bi bi-image"
												style={{
													fontSize: "3rem",
													opacity: "0.4",
													color: "#347d68",
													filter: "blur(1.5px)",
												}}
											></i>
											<i
												className="bi"
												style={{
													position: "absolute",
													left: "-15px",
													bottom: "30px",
													fontSize: "2rem",
												}}
											>
												<span style={{ fontSize: "inherit" }}>
													{fotos.length}
												</span>
											</i>
										</span>
										<Card.Title>
											Ver Fotos Salvas
											{loadingStatus.location == "encerrar" &&
											loadingStatus.flag ? (
												<Spinner animation="border" size="sm" />
											) : (
												""
											)}{" "}
											<br />{" "}
										</Card.Title>
									</Card>
								</Col>
							</>
						) : (
							""
						)}
						<Col sm="12" md="6" lg="3">
							<Card
								className="card-manifesto"
								onClick={() => {
									setShowFotosEnviadas({ show: true, pedido: props });
								}}
							>
								<span style={{ position: "relative" }}>
									<i
										className="bi bi-image"
										style={{
											fontSize: "3rem",
											opacity: "0.4",
											color: "#347d68",
											filter: "blur(1.5px)",
										}}
									></i>
									<i
										className="bi bi-file-earmark-image"
										style={{
											position: "absolute",
											left: "-15px",
											bottom: "30px",
											fontSize: "2rem",
										}}
									>
										<span style={{ fontSize: "" }}>{props.foto}</span>
									</i>
								</span>
								<Card.Title>
									Ver Fotos Enviadas
									{loadingStatus.location == "encerrar" &&
									loadingStatus.flag ? (
										<Spinner animation="border" size="sm" />
									) : (
										""
									)}{" "}
									<br />{" "}
								</Card.Title>
							</Card>
						</Col>
					</Row>
				</Modal.Body>
				<Modal.Footer>
					<Col sm="3">
						<Button variant="secondary" onClick={handlerSimpleClose}>
							Fechar
						</Button>
					</Col>
				</Modal.Footer>
			</Modal>
			{error.show ? <ModalErrorPretty setShow={setError} {...error} /> : ""}
			{success.show ? (
				<ModalSuccessPretty setShow={setSuccess} {...success} />
			) : (
				""
			)}

			{verPDF.flag ? (
				<ModalPDF_VerDanfe
					title={verPDF.title}
					pdf={verPDF.PDF}
					setShow={setVerPDF}
					show={verPDF.flag}
				/>
			) : (
				""
			)}

			{showFotos.show ? (
				<ModalFotosEnviadasMotorista setShow={setShowFotos} {...showFotos} />
			) : (
				""
			)}
			{/* {showFotos.show ? (
				<ModalFotosEnviadasMotorista
                    setShow={setShowFotos}
                    {...showFotos}
				/>
			) : (
				""
			)} */}
			{showFotosEnviadas.show ? (
				<ModalListarFotos
					setShow={setShowFotosEnviadas}
					{...showFotosEnviadas}
				/>
			) : (
				""
			)}
		</>
	);
}

function ModalFotosEnviadasMotorista({
	show,
	setShow,
	fotoListExterna = [],
	pedido,
}) {
	const handlerSimpleClose = () =>
		setShow((last) => ({ ...last, show: false }));
	let fotoList = useSelector((state) => state.cargaSlicer.listasFotos);
	fotoList = fotoList.length ? fotoList : fotoListExterna;
	const [index, setIndex] = useState(0);
	const [error, setError] = useState({
		flag: false,
	});

	const dispatch = useDispatch();

	const removeFoto = (foto) => {
		if (foto.status == "Enviada") {
			setError({
				flag: true,
				list: "Função Bloqueada. Foto ja Enviada para o Gestor!",
				title: "Aviso!",
			});
			return;
		}
		dispatch(
			fotosController({
				action: "delete-one",
				id: foto.id,
			})
		);
		setIndex(0);
	};

	useEffect(() => {}, []);

	return (
		<>
			<Modal
				show={show}
				onHide={handlerSimpleClose}
				backdrop="static"
				keyboard={false}
				centered
				size="xl"
			>
				<Modal.Header closeButton>
					<Modal.Title>Fotos Enviadas</Modal.Title>
				</Modal.Header>
				<Modal.Body style={{ overflow: "hidden" }}>
					{fotoList.length ? (
						<div className="d-flex align-items-center justify-content-around">
							<i
								className="bi bi-arrow-left-circle bi-select-img"
								onClick={() => setIndex((last) => (last <= 0 ? 0 : last - 1))}
								style={{
									// position: 'absolute',
									fontSize: "2rem",
								}}
							></i>
							<Row style={{ flexWrap: "nowrap", width: "80%" }}>
								{fotoList
									.filter((item) => item.numero_pedido == pedido.numero_pedido)
									?.map((item, key, self) => (
										<Col
											sm="12"
											style={{ textAlign: "center" }}
											key={key}
											hidden={index == key ? "" : true}
										>
											<p>
												{key + 1}/{self.length}
											</p>
											<img
												src={item.imgUrl}
												style={{
													width: "80%",
													objectFit: "contain",
													border: "solid 0.1px black",
													boxShadow: "0 0 20px 0 #0000007f",
												}}
												/* width={'80%'} height={'100%'} */
											/>
											<div className="d-flex justify-content-center mt-3">
												<Button
													style={{ width: "50%" }}
													variant="info"
													onClick={() => removeFoto(item)}
												>
													Remover Foto
												</Button>
											</div>
										</Col>
									))}
							</Row>
							<i
								className="bi bi-arrow-right-circle "
								onClick={() =>
									setIndex((last) =>
										last >= fotoList.length - 1 ? fotoList.length - 1 : last + 1
									)
								}
								style={{
									fontSize: "2rem",
								}}
							></i>
						</div>
					) : (
						"Sem fotos lançadas"
					)}
				</Modal.Body>
			</Modal>
			{error.flag ? (
				<ModalError
					show={error.flag}
					setShow={setError}
					msg={error.msg}
					title={error.title}
					{...error}
				/>
			) : (
				""
			)}
		</>
	);
}
function ModalListarFotos({ show, setShow, fotoListExterna = [], pedido }) {
	const handlerSimpleClose = () =>
		setShow((last) => ({ ...last, show: false }));
	const [fotoList, setFotosList] = useState([]);
	const [index, setIndex] = useState(0);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState({
		flag: false,
	});

	const dispatch = useDispatch();

	const carregarFotos = async () => {
		try {
			const fotos = await getAllData(
				`entregas/pedido/show-imgs/${pedido.cargas_id}/${pedido.numero_pedido}`
			);
			setFotosList(fotos.data);
		} catch (error) {}
		setLoading(false);
	};

	useEffect(() => {
		carregarFotos();
	}, []);
	return (
		<>
			<Modal
				show={show}
				onHide={handlerSimpleClose}
				backdrop="static"
				keyboard={false}
				centered
				size="xl"
			>
				<Modal.Header closeButton>
					<Modal.Title>
						Fotos Enviadas{" "}
						{loading ? <Spinner animation="border" size="sm" /> : ""}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body style={{ overflow: "hidden" }}>
					{fotoList.length ? (
						<div className="d-flex align-items-center justify-content-around">
							<i
								className="bi bi-arrow-left-circle bi-select-img"
								onClick={() => setIndex((last) => (last <= 0 ? 0 : last - 1))}
								style={{
									// position: 'absolute',
									fontSize: "2rem",
								}}
							></i>
							<Row style={{ flexWrap: "nowrap", width: "80%" }}>
								{fotoList?.map((item, key, self) => (
									<Col
										sm="12"
										style={{ textAlign: "center" }}
										key={key}
										hidden={index == key ? "" : true}
									>
										<p>
											{key + 1}/{self.length}
										</p>
										<img
											src={`${locationDev}/files/users/entregas/carga_${pedido.cargas_id}/${item.foto}`}
											style={{
												width: "80%",
												objectFit: "contain",
												border: "solid 0.1px black",
												boxShadow: "0 0 20px 0 #0000007f",
											}} /* width={'80%'} height={'100%'} */
										/>
									</Col>
								))}
							</Row>
							<i
								className="bi bi-arrow-right-circle "
								onClick={() =>
									setIndex((last) =>
										last >= fotoList.length - 1 ? fotoList.length - 1 : last + 1
									)
								}
								style={{
									fontSize: "2rem",
								}}
							></i>
						</div>
					) : (
						"Sem fotos lançadas"
					)}
				</Modal.Body>
			</Modal>
			{error.flag ? (
				<ModalError
					show={error.flag}
					setShow={setError}
					msg={error.msg}
					title={error.title}
					{...error}
				/>
			) : (
				""
			)}
		</>
	);
}
