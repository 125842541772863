import { createSlice } from '@reduxjs/toolkit'

import logo from '../../assets/logo1.png'
const returnDataWithCorrection = (date) => {
    const horas = new Date(date)
    horas.setHours(horas.getHours() - horas.getTimezoneOffset()/60)
    return horas
}
export const userSlice = createSlice({
    name: 'user',
    initialState: {
        name: ''
        , tipo: ''
        , cpf: ''
        , cnpj: ''
        , cnh: ''
        , fotoPerfil: ''
        , fotoCnh: ''
        , email: ''
        , bio: ''
        , online: false
        , token: ''
        , socket_id: ''
        , id_filial: 0
        , empresas_id: 0
        , authenticated: false
        , cargas_id: 0
        , logoImg: logo
        , cargaSelecionada: []
        , manifesto_id: null
        , integracao: false
        , caminhaoSelecionado: {
            status: false //true or false para disponibilizado ou nao
        }
        , confirmar_planejamento: {
            confirmado: false,
            date: ""
        }
        , dataNascimento: ''
        
        , funcionariosList: {
            data: []
            , lastUpdate: new Date().toLocaleString()
        }
        , caminhoesList: {
            data: []
            , lastUpdate: new Date().toLocaleString()
        }
        , caminhoesCaminhoneirosList: {
            data: []
            , lastUpdate: new Date().toLocaleString()
        },
        rotasList: {
            data: []
            , lastUpdate: new Date().toLocaleString()
        },
        pontosList: {
            data: []
            , lastUpdate: new Date().toLocaleString()
        },
        cargasList: {
            data: []
            , lastUpdate: new Date().toLocaleString()
        },
        manutencoesList: {
            data: []
            , lastUpdate: new Date().toLocaleString()
        },
        romaneiosList: {
            data: []
            , lastUpdate: new Date().toLocaleString()
        },
        produtosList: {
            data: []
            , lastUpdate: new Date().toLocaleString()
        },
        embalagensList: {
            data: []
            , lastUpdate: new Date().toLocaleString()
        },
        notificacoesList: {
            data: []
            , lastUpdate: new Date().toLocaleString()
        },
        abastecimentosList: {
            data: []
            , lastUpdate: new Date().toLocaleString()
        },
        diariosList: {
            data: []
            , lastUpdate: new Date().toLocaleString()
        },
        socket: null,
        processo_entrega: {
            flag: false,
            locations: []
        },
        locationsList: {
            data: []
            , lastUpdate: new Date().toLocaleString()
        },
        filiaisList: {
            data: []
            , lastUpdate: new Date().toLocaleString()
        },
        equipamentosList: {
            data: []
            , lastUpdate: new Date().toLocaleString()
        },
        transferenciasList: {
            data: []
            , lastUpdate: new Date().toLocaleString()
        },
        freteiroList: {
            data: []
            , lastUpdate: new Date().toLocaleString()
        },
        manifestosList: {
            data: []
            , lastUpdate: new Date().toLocaleString()
        },
        produtosEEmbalagens: {
            data: []
            , lastUpdate: new Date().toLocaleString()
        },
    },

    reducers: {
        update: (state, action) => {
            if (action.payload) {
                Object.keys(action.payload).forEach(key => {
                    state[key] = action.payload[key]
                    localStorage.setItem(`${key}`, action.payload[key])
                })
            }
        },
        updateCaminhaoSelecionado: (state, action) => {
            if (action.payload) {
                state.caminhaoSelecionado = action.payload
            }else{}
        },
        updateCarga_Id: (state, action) => {
            if (action.payload) {
                state.cargas_id = action.payload
            } else {
                state.cargas_id = null
            }
        },
        updateFuncionarios: (state, action) => {

            if (action.payload) {
                state.funcionariosList.data = action.payload.map(item => {
                    item.data_valid_cnh = item.data_valid_cnh ? new Date(item.data_valid_cnh).toLocaleDateString('pt-br', { timeZone: 'UTC' }): 'N/A'
                    item.data_nascimento = item.data_nascimento ? new Date(item.data_nascimento).toLocaleDateString('pt-br', { timeZone: 'UTC' }): 'N/A'
                    item.createdAt = returnDataWithCorrection(item.createdAt).toLocaleString('pt-br', { timeZone: 'UTC'})
                    item.updatedAt = returnDataWithCorrection(item.updatedAt).toLocaleString('pt-br', { timeZone: 'UTC'})
                    return item
                })
            }
        },
        updateFuncionario: (state, action) => {
            state.funcionariosList.data.map(item => {
                if (item.id === action.payload.idFuncionario && action.payload.status) {
                    item.caminhao = 'Caminhão Disponibilizado'
                } else if (item.id === action.payload.idFuncionario && !action.payload.status) {
                    item.caminhao = ''

                }
                return item
            })
        },
        updateTransferencias: (state, action) => {

            if (action.payload) {
                state.transferenciasList.data = action.payload.map(item => {
                    item.created_at = returnDataWithCorrection(item.createdAt).toLocaleString('pt-br', { timeZone: 'UTC'})
                    item.updated_at = returnDataWithCorrection(item.updatedAt).toLocaleString('pt-br', { timeZone: 'UTC'})
                    return item
                })
            }
        },
        updateEquipamentos: (state, action) => {

            if (action.payload) {
                state.equipamentosList.data = action.payload.map(item => {
                    item.created_at = returnDataWithCorrection(item.createdAt).toLocaleString('pt-br', { timeZone: 'UTC'})
                    item.updated_at = returnDataWithCorrection(item.updatedAt).toLocaleString('pt-br', { timeZone: 'UTC'})
                    return item
                })
            }
        },
        updateCaminhoes: (state, action) => {

            if (action.payload) {
                state.caminhoesList.data = action.payload.map(item => {
                    item.vencimento_tacografo = item.vencimento_tacografo ? new Date(item.vencimento_tacografo).toLocaleDateString('pt-br', { timeZone: 'UTC' }): 'N/A'
                    item.createdAt = returnDataWithCorrection(item.createdAt).toLocaleString('pt-br', { timeZone: 'UTC'})
                    item.updatedAt = returnDataWithCorrection(item.updatedAt).toLocaleString('pt-br', { timeZone: 'UTC'})
                    return item
                })
            }
        },
        updateCaminhoesCaminhoeiros: (state, action) => {

            if (action.payload) {
                state.caminhoesCaminhoneirosList.data = action.payload.map(item => {
                    item.createdAt = returnDataWithCorrection(item.createdAt).toLocaleString('pt-br', { timeZone: 'UTC'})
                    item.updatedAt = returnDataWithCorrection(item.updatedAt).toLocaleString('pt-br', { timeZone: 'UTC'})
                    return item
                })
            }
        },
        updateRotasList: (state, action) => {

            if (action.payload) {
                state.rotasList.data = action.payload.map(item => {

                    item.createdAt = returnDataWithCorrection(item.createdAt).toLocaleString('pt-br', { timeZone: 'UTC'})
                    item.updatedAt = returnDataWithCorrection(item.updatedAt).toLocaleString('pt-br', { timeZone: 'UTC'})
                    return item
                })
            }
        },
        updatePontosList: (state, action) => {
            
            if (action.payload) {
                state.pontosList.data = action.payload.map(item => {
                    item.createdAt = returnDataWithCorrection(item.createdAt).toLocaleString('pt-br', { timeZone: 'UTC'})
                    item.updatedAt = returnDataWithCorrection(item.updatedAt).toLocaleString('pt-br', { timeZone: 'UTC'})
                    item.ponto = returnDataWithCorrection(item.ponto).toLocaleString('pt-br', { timeZone: 'UTC' })
                    return item
                })
            }
        },
        updateCargasList: (state, action) => {
            
            if (action.payload) {
                state.cargasList.data = action.payload.map(item => {
                    // item.createdAt = returnDataWithCorrection(item.createdAt).toLocaleString('pt-br', { timeZone: 'UTC'})
                    // item.updatedAt = returnDataWithCorrection(item.updatedAt).toLocaleString('pt-br', { timeZone: 'UTC'})
                    item.created_at = returnDataWithCorrection(item.createdAt).toLocaleDateString('pt-br', { timeZone: 'UTC'})
                    item.updated_at = returnDataWithCorrection(item.updatedAt).toLocaleDateString('pt-br', { timeZone: 'UTC'})
                    item.data_saida = item.data_saida ? returnDataWithCorrection(item.data_saida).toLocaleDateString('pt-br', { timeZone: 'UTC'}) : ''
                    item.data_chegada = item.data_chegada ? returnDataWithCorrection(item.data_chegada).toLocaleString('pt-br', { timeZone: 'UTC'}) : ''
                    item.data_inicio_entrega = item.data_inicio_entrega ? returnDataWithCorrection(item.data_inicio_entrega).toLocaleString('pt-br', { timeZone: 'UTC'}) : ''
                    return item
                })
            }
        },
        updateManutencoesList: (state, action) => {
            
            if (action.payload) {
                state.manutencoesList.data = action.payload.map(item => {
                    // item.createdAt = returnDataWithCorrection(item.createdAt).toLocaleString('pt-br', { timeZone: 'UTC'})
                    // item.updatedAt = returnDataWithCorrection(item.updatedAt).toLocaleString('pt-br', { timeZone: 'UTC'})
                    item.emissao = item.emissao ? new Date(item.emissao).toLocaleDateString('pt-br', { timeZone: 'UTC'}) : 'N/A'
                    item.data_previsao = item.data_previsao ? new Date(item.data_previsao).toLocaleDateString('pt-br', { timeZone: 'UTC'}) : 'N/A'
                    item.data_finalizacao = item.data_finalizacao ? new Date(item.data_finalizacao).toLocaleDateString('pt-br', { timeZone: 'UTC'}) : 'N/A'
                    return item
                })
            }
        },
        updateRomaneiosList: (state, action) => {
            
            if (action.payload) {
                state.romaneiosList.data = action.payload.map(item => ({
                    ...item,
                    created_at: returnDataWithCorrection(item.createdAt).toLocaleString('pt-br', { timeZone: 'UTC'}),
                    updated_at: returnDataWithCorrection(item.updatedAt).toLocaleString('pt-br', { timeZone: 'UTC'}),
                }))
            }
        },
        updateNotificacoesList: (state, action) => {
            
            if (action.payload) {
                state.notificacoesList.data = action.payload.map(item => {
                   
                    return {
                        ...item
                        , created_at: returnDataWithCorrection(item.createdAt).toLocaleString('pt-br', { timeZone: 'UTC' })
                        , updated_at: returnDataWithCorrection(item.updatedAt).toLocaleString('pt-br', { timeZone: 'UTC'})
                    }
                })
            }
        },
        updateAbastecimentosList: (state, action) => {
            
            if (action.payload) {
                state.abastecimentosList.data = action.payload.map(item => {
                    item.created_at = returnDataWithCorrection(item.createdAt).toLocaleString('pt-br', { timeZone: 'UTC'})
                    item.updated_at = returnDataWithCorrection(item.updatedAt).toLocaleString('pt-br', { timeZone: 'UTC'})
                    item.data_abastecimento = returnDataWithCorrection(item.data_abastecimento).toLocaleString('pt-br', { timeZone: 'UTC'})
                    return item
                })
            }
        },
        updateFreteirosList: (state, action) => {
            
            if (action.payload) {
                state.freteiroList.data = action.payload.map(item => {
                    return {
                        ...item
                        , created_at: returnDataWithCorrection(item.createdAt).toLocaleString('pt-br', { timeZone: 'UTC'})
                        , updated_at: returnDataWithCorrection(item.updatedAt).toLocaleString('pt-br', { timeZone: 'UTC'})
                        , data_abastecimento: returnDataWithCorrection(item.data_abastecimento).toLocaleString('pt-br', { timeZone: 'UTC'})
                    }
                })
            }
        },
        updateProdutosList: (state, action) => {
            
            if (action.payload) {
                state.produtosList.data = action.payload.map(item => ({
                    ...item,
                    created_at: returnDataWithCorrection(item.createdAt).toLocaleString('pt-br', { timeZone: 'UTC'}),
                    updated_at: returnDataWithCorrection(item.updatedAt).toLocaleString('pt-br', { timeZone: 'UTC'})
                }))
            }
        },
        updateEmbalagensList: (state, action) => {
            
            if (action.payload) {
                state.embalagensList.data = action.payload.map(item => {
                    item.created_at = returnDataWithCorrection(item.createdAt).toLocaleString('pt-br', { timeZone: 'UTC'})
                    item.updated_at = returnDataWithCorrection(item.updatedAt).toLocaleString('pt-br', { timeZone: 'UTC'})
                    return item
                })
            }
        },
        updateProdutosListDimensoes: (state, action) => {
            
            if (action.payload) {
                const index = state.produtosList.data.findIndex(o => o.qr_sku === action.payload.qr_sku)
                state.produtosList.data.at(index)[action.payload.dimension.name] = action.payload.dimension.value
                const comprimento = state.produtosList.data.at(index).comprimento
                const altura = state.produtosList.data.at(index).altura
                const largura = state.produtosList.data.at(index).largura
                if (action.payload.dimension.name == 'volume') {
                    state.produtosList.data.at(index).volume = action.payload.dimension.value
                    
                } else  if (action.payload.dimension.name == 'peso') {
                    state.produtosList.data.at(index).peso = action.payload.dimension.value
                    
                } else {
                    state.produtosList.data.at(index).volume = Number(comprimento * altura * largura).toFixed(2)
                    
                }
            }
        },
        updateEmbalagensListDimensoes: (state, action) => {
            
            if (action.payload) {
                const index = state.embalagensList.data.findIndex(o => o.qr_sku === action.payload.qr_sku)
                state.embalagensList.data.at(index)[action.payload.dimension.name] = action.payload.dimension.value
                const comprimento = state.embalagensList.data.at(index).comprimento
                const altura = state.embalagensList.data.at(index).altura
                const largura = state.embalagensList.data.at(index).largura
                state.embalagensList.data.at(index).volume = Number(comprimento * altura * largura).toFixed(2)
            }
        },
        updateProdutosListChecked: (state, action) => {
            
            if (action.payload) {
                state.produtosList.data.map(item => {
                    if(item.qr_sku === action.payload.qr_sku){
                        item.conferido = action.payload.conferido
                        item.data_conferencia = action.payload.data
                        item.user_conferencia = action.payload.user
                        item.setor_conferencia = action.payload.setor
                        return item
                    }
                    return item
                })
            }
        },
        updateEmbalagensChecked: (state, action) => {
            
            if (action.payload) {
                for (let i = 0; i < state.embalagensList.data.length; i++){
                    const element = state.embalagensList.data[i]
                    const el = state.embalagensList.data.splice(i, 1)
                    if (element.qr_sku == action.payload.qr_sku) {
                        state.embalagensList.data = [
                            ...state.embalagensList.data,
                            {
                                ...element,
                                conferido: action.payload.conferido,
                                data_conferencia: action.payload.data,
                                user_conferencia: action.payload.user,
                                setor_conferencia: action.payload.setor,
                            }
                        ]
                        break
                    }
                }
                // state.embalagensList.data.map(item => {
                //     if(item.qr_sku === action.payload.qr_sku){
                //         item.conferido = action.payload.conferido
                //         item.data_conferencia = action.payload.data
                //         item.user_conferencia = action.payload.user
                //         item.setor_conferencia = action.payload.setor
                //         return item
                //     }
                //     return item
                // })
            }
        },
        updateEmbalagensListCarregados: (state, action) => {
            
            if (action.payload) {
                state.embalagensList.data.map(item => {
                    if(item.qr_sku === action.payload.qr_sku){
                        item.carregado = action.payload.carregado
                        item.data_carregamento = action.payload.data
                        item.user_carregamento = action.payload.user
                        return item
                    }
                    return item
                })
            }
        },
        updateProdutosListCarregados: (state, action) => {
            
            if (action.payload) {
                state.produtosList.data.map(item => {
                    if(item.qr_sku === action.payload.qr_sku){
                        item.carregado = action.payload.carregado
                        item.data_carregamento = action.payload.data
                        item.user_carregamento = action.payload.user
                        return item
                    }
                    return item
                })
            }
        },
        updateProdutosListPlanejados: (state, action) => {
            
            if (action.payload) {
                state.produtosList.data.map(item => {
                    if(item.qr_sku === action.payload.qr_sku){
                        item.planejado = action.payload.planejado
                        item.data_planejamento = action.payload.data
                        item.user_planejamento = action.payload.user
                        return item
                    }
                    return item
                })
            }
        },
        updateEmbalagensListPlanejados: (state, action) => {
            
            if (action.payload) {
                state.embalagensList.data.map(item => {
                    if(item.qr_sku === action.payload.qr_sku){
                        item.planejado = action.payload.planejado
                        item.data_planejamento = action.payload.data
                        item.user_planejamento = action.payload.user
                        item.cor_caixa = action.payload.cor_caixa
                        return item
                    }
                    return item
                })
            }
        },
        updateProdutosListDescarregados: (state, action) => {
            
            if (action.payload) {
                state.produtosList.data.map(item => {
                    if(item.qr_sku === action.payload.qr_sku){
                        item.descarregado = action.payload.descarregado
                        item.data_descarregamento = action.payload.data
                        item.user_descarregamento = action.payload.user
                        return item
                    }
                    return item
                })
            }
        },
        updateEmbalagensListDescarregados: (state, action) => {
            
            if (action.payload) {
                state.embalagensList.data.map(item => {
                    if(item.qr_sku === action.payload.qr_sku){
                        item.descarregado = action.payload.descarregado
                        item.data_descarregamento = action.payload.data
                        item.user_descarregamento = action.payload.user
                        return item
                    }
                    return item
                })
            }
        },
        updateDiarioList: (state, action) => {
            
            if (action.payload) {
                state.diariosList.data = action.payload.map(item => {
                    item.created_at = returnDataWithCorrection(item.createdAt).toLocaleString('pt-br', { timeZone: 'UTC'})
                    item.updated_at = returnDataWithCorrection(item.updatedAt).toLocaleString('pt-br', { timeZone: 'UTC'})
                    item.horario = returnDataWithCorrection(item.data).toLocaleTimeString('pt-BR', { timeZone: 'UTC'})
                    item.data = returnDataWithCorrection(item.data).toLocaleDateString('pt-br', { timeZone: 'UTC'})
                    return item
                })
            }
        },
        updateManifestosList: (state, action) => {
            
            if (action.payload) {
                state.manifestosList.data = action.payload.map(item => {
                    item.created_at = returnDataWithCorrection(item.createdAt).toLocaleString('pt-br', { timeZone: 'UTC'})
                    item.updated_at = returnDataWithCorrection(item.updatedAt).toLocaleString('pt-br', { timeZone: 'UTC'})
                    return item
                })
            }
        },
        updateLocationsList: (state, action) => {
            
            if (action.payload) {
                state.locationsList.data = action.payload
            }
        },
        updateFiliaisList: (state, action) => {
            
            if (action.payload) {
                state.filiaisList.data = action.payload
            }
        },
        updateProdutosEEmbalagens: (state, action) => {
            
            if (action.payload) {
                state.produtosEEmbalagens.data = action.payload
            }
        },
        updateProdutosEEmbalagensBySku: (state, action) => {
            
            if (action.payload) {
                state.produtosEEmbalagens.data.map(item => {
                    if (item.qr_sku === action.payload.qr_sku) {
                        if (action.payload.parameter == 'descarregado') {
                            item.data_descarregamento = action.payload.data
                            item.user_descarregamento = action.payload.user
                            
                        }
                        if (action.payload.parameter == 'carregado') {
                            item.data_carregamento = action.payload.data
                            item.user_carregamento = action.payload.user
                        }
                        const parameter = action.payload.parameter
                        item[parameter] = action.payload[parameter]
                        return item
                    }
                    return item
                })
            }
        },
        updateCargaSelecionada: (state, action) => {
            
            if (action.payload) {
                state.cargaSelecionada = action.payload
            }
        },
    }

})

export const { 
    update
    , updateFuncionarios
    , updateFuncionario
    , updateCaminhoes
    , updateCaminhaoSelecionado
    , updateCaminhoesCaminhoeiros
    , updateRotasList
    , updatePontosList
    , updateCargasList
    , updateRomaneiosList
    , updateProdutosList
    , updateNotificacoesList
    , updateProdutosListChecked
    , updateProdutosListCarregados
    , updateProdutosListDescarregados
    , updateProdutosListDimensoes
    , updateProdutosListPlanejados
    , updateEmbalagensListDimensoes
    , updateEmbalagensList
    , updateEmbalagensChecked
    , updateEmbalagensListPlanejados
    , updateEmbalagensListCarregados
    , updateAbastecimentosList
    , updateEmbalagensListDescarregados
    , updateCarga_Id
    , updateDiarioList
    , updateLocationsList
    , updateFiliaisList
    , updateEquipamentos
    , updateTransferencias
    , updateFreteirosList
    , updateCargaSelecionada
    , updateManifestosList
    , updateManutencoesList
    , updateProdutosEEmbalagens
    , updateProdutosEEmbalagensBySku
 } = userSlice.actions
export default userSlice.reducer