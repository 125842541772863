import { useEffect, useLayoutEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import {
	getAllData,
	getData,
	getTableColumns,
	verificaTipoUsuario,
} from "../../services/Servicos";
import { ListarRomaneios } from "../planoLogistica/romaneios/Romaneios";
import { useDispatch, useSelector } from "react-redux";

import "./Modal.scss";

export default function ModalListarRomaneios({
	msg,
	title,
	show,
	setShow,
	msgButton,
	setCarga,
	carga,
}) {
	const [headList, setHead] = useState(["index", "admin"]);
	const [romaneioFiltered, setRomaneioFiltered] = useState([]);
	const user = useSelector((state) => state.userSlice);

	const updateColumns = async () => {
		const romaneiosGet = await getTableColumns("CargasPedidos");

		let headList = [
			"status",
			"nome_vendedor",
			"tipo",
			...Object.keys(romaneiosGet.data)
				.filter((item) => item !== "status")
				.filter((item) => item !== "data_cancelamento")
				.filter((item) => item !== "nome_vendedor")
				.filter((item) => item !== "created_at")
				.filter((item) => item !== "updated_at")
				.filter((item) => item !== "qr_code_id")
				.filter((key) => key !== "cpf_responsavel")
				.filter((key) => key !== "tipo"),
		];
		setHead((last) => [...last, ...headList]);
		// if (user.email == 'CODJI.SUPORTE@GMAIL.COM') {
		//     setHead(last => ['suporte', ...last, ...headList])
		// } else {

		// }
	};

	const updateArrayRomaneios = async () => {
		let carga_id = carga;
		let id_filial = user.id_filial;

		if (user.id_filial == 2 || user.tipo.toLowerCase() == "empresa") {
			id_filial = null;
		}

		let objCons = { cargas_id: carga_id };

		if (
			user.tipo.toLowerCase() !== "empresa" &&
			user.tipo.toLowerCase() !== "gestor"
		) {
			const romaneiosGet = await getData("cargas/pedidos/separador", carga_id);
			setRomaneioFiltered(romaneiosGet.data);
			return;
		}

		let romaneiosGet = await getAllData(
			verificaTipoUsuario(user) + "cargas/pedidos/empresa",
			objCons
		);
		setRomaneioFiltered(romaneiosGet.data);
	};

	useLayoutEffect(() => {
		updateColumns();
		// updateArrayRomaneios()
	}, []);
	useEffect(() => {}, [romaneioFiltered]);

	const handlerSimpleClose = () => {
		// window.location.reload()
		setShow((last) => ({ error: { flag: false } }));
	};

	return (
		<>
			<Modal
				show={show}
				onHide={handlerSimpleClose}
				backdrop="static"
				keyboard={false}
				centered
				size="xl"
			>
				<Modal.Header closeButton>
					<Modal.Title>
						<span>{title}:</span>
						<span>
							<b>{carga}</b>
						</span>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<ListarRomaneios
						headList={headList}
						height={400}
						carga_id={carga}
						romaneiosList={romaneioFiltered}
					/>
				</Modal.Body>
				<Modal.Footer className="modal-footer-no-border">
					<Button variant="secondary" onClick={handlerSimpleClose}>
						Sair
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}
