import {
	Fragment,
	createRef,
	useEffect,
	useLayoutEffect,
	useRef,
	useState,
} from "react";
import { Form, Spinner } from "react-bootstrap";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import "./HeadRow.scss";
import {
	getFilialUsuario,
	getStatusBoolean,
	getStatusCarga,
	getStatusPedidos,
} from "../../../services/Servicos";
import { useSelector } from "react-redux";

const dateSaida = createRef();
export default function HeadRowNew({
	headerList,
	setArrayFilters = () => {},
	arrayFilters = [],
	setFiltersOld = () => {},
	filtersOld = [],
	arrayValues = [],
	arrayPedidos = [],
	setPedidos,
	rota = "",
	setDataParam = () => {},
}) {
	// const dateSaida = useRef()
	const [show, setShow] = useState({});
	const [loading, setLoading] = useState({});

	const user = useSelector((state) => state.userSlice);
	const filiaisList = user.filiaisList.data;

	const onClickFilter = (e, filter, value = "") => {
		setLoading((last) => ({ ...last, [filter]: true }));
		setTimeout(() => {
			console.log(filter, value)
			if (!arrayFilters[filter].length) {
				// se ele clicar no filtro e nada estiver cadastrado, coloque as opções
				const teste = new Promise(function (resolve, reject) {
					let elements = [];
					if (["idFilial","id_filial",'id_filial_destino'].includes(filter)) {
						elements = arrayValues
							.filter(
								(i, n, s) => s.findIndex((o) => o[filter] === i[filter]) === n
							)
							.map((item) => ({
								show: false,
								value: `${item[filter]} - ${
									filiaisList.filter(
										(itemB) => itemB.idFilial == item[filter]
									)[0]?.identificacaoInterna
								}`, //item[filter]
							}));
						//
					} else {
						elements = arrayValues
							.filter(
								(i, n, s) => s.findIndex((o) => o[filter] === i[filter]) === n
							)
							.map((item) => ({ show: false, value: item[filter] }));
					}
					resolve(elements);
				});

				teste.then((response) => {
					setArrayFilters((last) => ({
						...last,
						[filter]: [...last[filter], ...response],
					}));
				});
			}

			Object.keys(show).map((key) => {
				const prom = new Promise((resolve, reject) => {
					if (key == filter) {
						// setShow(last => ({ ...last, [key]: !last[key] }))
						resolve(!show[key]);
					} else {
						// setShow(last => ({ ...last, [key]: true }))
						resolve(true);
					}
				});
				prom.then((response) => {
					setShow((last) => ({ ...last, [key]: response }));
					setLoading((last) => ({ ...last, [filter]: false }));
				});

				// prom.then(response => {

				// })
				// setShow(last => ({ ...last, [key]: true }))

				if (!show[filter]) {
					// se ja estiver aberto, retorne
					setShow((last) => ({ ...last, [filter]: true }));
					return;
				}
			});

			if (String(value).length) {
				if (filtersOld.filter((item) => item[filter] == value).length) {
					alert("item ja filtrado");
					return;
				}
				setFiltersOld((last) => [...last, { [filter]: String(value) }]);
			}
		}, 100);
	};

	const onChangeValueFilter = (e, key) => {
		const value = e.target.value.toUpperCase();

		if (["idFilial","id_filial",'id_filial_destino'].includes(key)) {
			setArrayFilters((last) => ({
				...last,
				[key]: last[key].map((item) => {
					if (String(item.value).toUpperCase().includes(value)) {
						item.show = false;
					} else if (
						(item.show !== true) &
						!String(item.value).toUpperCase().includes(value)
					) {
						item.show = true;
					}
					return item;
				}),
			}));
			return;
		}

		setArrayFilters((last) => ({
			...last,
			[key]: last[key].map((item) => {
				// .includes(value))
				if (String(item.value).toUpperCase().includes(value)) {
					item.show = false;
				} else if (
					(item.show !== true) &
					!String(item.value).toUpperCase().includes(value)
				) {
					item.show = true;
				}
				return item;
			}),
		}));
	};

	const [sorted, setSorted] = useState({ option: "", order: true });
	useEffect(() => {
		if (!sorted.option) return;
		let pedidosSorted = [...arrayPedidos];
		const pedidosS = pedidosSorted.sort((a, b) => {
			let valueA = a[sorted.option];
			let valueB = b[sorted.option];

			if (sorted.option.includes("dias")) {
				if (Number(valueA) > Number(valueB)) return 1;
				if (Number(valueA) < Number(valueB)) return -1;
				return 0;
			}
			if (valueA > valueB) return 1;
			if (valueA < valueB) return -1;
			return 0;
		});
		setPedidos(sorted.order ? pedidosS : pedidosS.reverse());
	}, [sorted]);

	useEffect(() => {
		headerList
			.filter((item) => item !== "admin")
			.filter((item) => item !== "index")
			.forEach((element) => {
				setArrayFilters((last) => ({ ...last, [element]: [] }));
				setShow((last) => ({ ...last, [element]: true }));
				setLoading((last) => ({ ...last, [element]: false }));
			});
	}, [headerList]);

	return (
		<Fragment>
			<tr style={{ verticalAlign: "middle" }}>
				{headerList.map((item, index) => {
					if (item === "index") {
						return <th key={index}>#</th>;
					}
					if (item === "admin") {
						return <th key={index}>ADMIN</th>;
					}
					if (item === "codigoPedido") {
						return (
							<th key={index}>
								<div
									className="d-flex align-items-center"
									style={{ cursor: "pointer" }}
									onClick={() =>
										setSorted((last) => ({ option: item, order: !last.order }))
									}
								>
									{sorted.order == 1 && sorted.option == item ? (
										<i className="bi bi-sort-alpha-down"></i>
									) : (
										<i className="bi bi-sort-alpha-up-alt"></i>
									)}
									<div
										style={{
											fontWeight: sorted.option == item ? "800" : "300",
											marginLeft: "5px",
										}}
									>
										{item.toUpperCase()}
									</div>
								</div>
							</th>
						);
					}
					if (item === "liberacao_financeiro_file") {
						return <th key={index}>LIBERACAÇÃO FINANCEIRO</th>;
					}
					if (item === "fechamento_financeiro_file") {
						return <th key={index}>FECHAMENTO FINANCEIRO</th>;
					}
					if (item === "previsaoEntrega") {
						return (
							<th key={index}>
								<div
									key={index}
									style={{
										display: "flex",
										alignItems: "center",
										zIndex: "1",
										cursor: "pointer",
									}}
									onClick={() =>
										setSorted((last) => ({ option: item, order: !last.order }))
									}
								>
									{sorted.order == 1 && sorted.option == item ? (
										<i className="bi bi-sort-alpha-down"></i>
									) : (
										<i className="bi bi-sort-alpha-up-alt"></i>
									)}
									<div
										style={{ display: "flex", justifyContent: "space-between" }}
									>
										<div style={{ marginRight: "10px" }}>
											{item.toUpperCase().replaceAll("_", " ")}
										</div>
										<div className="filter-icon">
											<i
												className="bi bi-calendar-date"
												style={{ fontSize: "0.8rem" }}
												title="Editar"
												onClick={(e) =>
													setDataParam((last) => ({
														...last,
														show: true,
														filtro: item,
													}))
												}
											></i>
										</div>
									</div>
								</div>
							</th>
						);
					}
					if (
						item === "data_saida" ||
						item === "dtemissao" ||
						item === "data" ||
						item === "data_nascimento"
					) {
						return (
							<th key={index}>
								<div
									key={index}
									style={{
										display: "flex",
										alignItems: "baseline",
										zIndex: "1",
										cursor: "pointer",
									}}
									onClick={() =>
										setSorted((last) => ({ option: item, order: !last.order }))
									}
								>
									{sorted.order == 1 && sorted.option == item ? (
										<i
											className="bi bi-sort-alpha-down"
											style={{ marginRight: "10px" }}
										></i>
									) : (
										<i
											className="bi bi-sort-alpha-up-alt"
											style={{ marginRight: "10px" }}
										></i>
									)}
									<div
										style={{ display: "flex", justifyContent: "space-between" }}
									>
										<div style={{ marginRight: "10px" }}>
											{item.toUpperCase().replaceAll("_", " ")}
										</div>
										<div className="filter-icon">
											<i
												className="bi bi-calendar-date"
												style={{ fontSize: "0.8rem" }}
												title="Editar"
												onClick={(e) =>
													setDataParam((last) => ({
														...last,
														show: true,
														filtro: item,
													}))
												}
											></i>
										</div>
									</div>
								</div>
							</th>
						);
					}
					if (item === "upload_file") {
						return (
							<th key={index} style={{ width: "15%" }}>
								{item.toUpperCase().replaceAll("_", " ")}
							</th>
						);
					}
					if (
						[
							// /cargas
							"peso_total",
							"volume_total",
							"metros_quadrados",
							"qtde_produtos",
							"qtde_pedidos",
							"created_at",
							"updated_at",
							// /plano-logistica/vendas
							"ADMIN",
							"idEmpresa",
							"observacao11",
							"observacao2",
							"observacao3",
							"observacao4",
							"vlrVenda",
							"descbairro",
							"endereco",
							"foneresidencial",
							"fonecelular",
							"foneopcional",
							"nomeRazao",
							"stLogistica",
							"nfeMod",
							"cep",
							"",
							// romaneios
							"empresas_id",
							"qr_code_id",
							"emissao",
							"valor_total",
							// diarios
							"horario",
							"mensagem",
							// rotas
							"createdAt",
							"updatedAt",
							// frota
							"Atribuir",
							"ADMIN",
						].indexOf(item) >= 0
					) {
						return (
							<th key={index}>
								<div
									className="d-flex align-items-center"
									style={{ cursor: "pointer" }}
									onClick={() =>
										setSorted((last) => ({ option: item, order: !last.order }))
									}
								>
									{sorted.order == 1 && sorted.option == item ? (
										<i className="bi bi-sort-alpha-down"></i>
									) : (
										<i className="bi bi-sort-alpha-up-alt"></i>
									)}
									<div
										style={{
											fontWeight: sorted.option == item ? "800" : "300",
											marginLeft: "5px",
										}}
									>
										{" "}
										{item.toUpperCase().replaceAll("_", " ")}
									</div>
								</div>
							</th>
						);
					}
					return (
						<th key={index}>
							<div
								key={index}
								style={{
									display: "flex",
									alignItems: "center",
									zIndex: "1",
									cursor: "pointer",
									width: "auto",
								}}
								onClick={() =>
									setSorted((last) => ({ option: item, order: !last.order }))
								}
							>
								{sorted.order == 1 && sorted.option == item ? (
									<i
										className="bi bi-sort-alpha-down"
										style={{ marginRight: "10px" }}
									></i>
								) : (
									<i
										className="bi bi-sort-alpha-up-alt"
										style={{ marginRight: "10px" }}
									></i>
								)}

								<div
									style={{
										display: "flex",
										justifyContent: "space-between",
										zIndex: "1",
									}}
								>
									<div style={{ marginRight: "10px" }}>
										{item.toUpperCase().replaceAll("_", " ")}
									</div>
									<div className="filter-icon">
										{loading[item] ? (
											<Spinner size="sm" color="black" animation="border" />
										) : (
											<i
												className="bi bi-funnel-fill"
												style={{ fontSize: "0.8rem" }}
												title="Editar"
												onClick={(e) => onClickFilter(e, item)}
											></i>
										)}
										<ul
											className=" card card-filters-options"
											hidden={show[item]}
										>
											<Form.Control
												style={{ padding: "5px", margin: "2px" }}
												onChange={(e) => onChangeValueFilter(e, item)}
											/>
											{arrayFilters[item] &&
												arrayFilters[item]
													// .filter((i, n, s) => s.findIndex(o => o.value === i.value) === n)
													.filter((i) => i.value !== null && i.value !== "")
													.sort((a, b) => {
														if (a.value > b.value) return 1;
														if (a.value < b.value) return -1;
														return 0;
													})
													.filter((item) => !item.show)
													.map((chave, index_) => {
														if (['id_filial','idFilial','id_filial_destino'].includes(item)) {
															return (
																<span
																	key={index_}
																	value={`${chave.value} - ${
																		filiaisList.filter(
																			(item) => item.idFilial == chave.value
																		)[0]?.identificacaoInterna
																	}`}
																	onClick={(e) =>
																		onClickFilter(e, item, chave.value)
																	}
																	hidden={chave.show}
																>
																	{chave.value}
																</span>
															);
														}

														if (index_ < 10) {
															if (
																item === "status" &&
																(rota === "frota" || rota === "funcionarios")
															) {
																return (
																	<span
																		key={index_}
																		value={chave.value}
																		onClick={(e) =>
																			onClickFilter(e, item, chave.value)
																		}
																		hidden={chave.show}
																	>
																		{getStatusBoolean(chave.value)}
																	</span>
																);
															}

															if (item === "status") {
																return (
																	<span
																		key={index_}
																		value={chave.value}
																		onClick={(e) =>
																			onClickFilter(e, item, chave.value)
																		}
																		hidden={chave.show}
																	>
																		{getStatusCarga(chave.value)}
																	</span>
																);
															}
															if (item === "stLogistica") {
																return (
																	<span
																		key={index_}
																		value={chave.value}
																		onClick={(e) =>
																			onClickFilter(e, item, chave.value)
																		}
																		hidden={chave.show}
																	>
																		{getStatusPedidos(chave.value)}
																	</span>
																);
															}
															return (
																<span
																	key={index_}
																	value={chave.value}
																	onClick={(e) =>
																		onClickFilter(e, item, chave.value)
																	}
																	hidden={chave.show}
																>
																	{chave.value}
																</span>
															);
														}
													})}
										</ul>
									</div>
								</div>
							</div>
						</th>
					);
				})}
			</tr>
		</Fragment>
	);
}
