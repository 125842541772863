import { QRCodeSVG } from "qrcode.react"
import { Fragment, useEffect, useRef, useState } from "react"
import ModalTableProdutos from "../../modal/ModalTableProdutos"

import './BodyRow.scss'
import { Form, Spinner } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { updateEmbalagensChecked, updateProdutosListChecked } from "../../../redux/slicers/user"
import ModalAtualizarDimensoes from "../../modal/ModalAtualizarDimensoes"
import ModalAtualizarDimensoesEmbalagens from "../../modal/ModalAtualizarDimensoesEmbalagens"
import ModalListarProdutosEmbalados from "../../modal/ModalListarProdutosEmbalados"
import { correctDataUTC, getAllData, getData, verificaTipoUsuario } from "../../../services/Servicos"
import ModalImprimirQRCode from "../../modal/ModalImprimirQRCode"
import ModalControleManifesto from "../../modal/ModalControleManifesto"

export default function BodyRowManifestos({ headList, bodyList, index, caminhao, setLoadingFinalizarParcial = () => {}, setError = () => {}, setSeparador = () => {}, separador, tableLength }) {
    const user = useSelector(state => state.userSlice)
    const filiaisList = user.filiaisList.data 
    const refRow = useRef(null)
    const refEditar = useRef(null)

    const [imprimir, setImprimir] = useState({
        flag: false,
        title: '',
        qr_sku:"",
        msg_obj: {}
    })  

    const onClickConfig = (e) => {
        setControleManifesto({ flag: true, body: { ...bodyList, id: bodyList.cargas_id } })
    }
   
    const [controleManifesto, setControleManifesto] = useState({
        flag: false,
        body: {}
    })

    const onClickTableRow = (e) => {
        e.preventDefault()
        refRow.current.classList = 'bg-selected'
        let refRowNumber = index

        let i = refRowNumber
        let actualSibling = refRow.current.nextSibling

        while(i < tableLength){
            if(actualSibling === null){
                break
            }
            actualSibling.classList = ''
            
            actualSibling = actualSibling.nextSibling
            i++
        }

        i = index
        actualSibling = refRow.current.previousSibling
        while(0 < i ){
            if(actualSibling === null){
                break
            }
            actualSibling.classList = ''
            actualSibling = actualSibling.previousSibling
            i--
        }     
        
    }

    return (
        <Fragment>
            {
                <tr ref={refRow} onClick={onClickTableRow}>
                    {
                        headList.map((item, _index) => {
                     
                            if (item === 'index') {
                                return <td key={_index}> {index + 1}</td>

                            } 
                            else if (item.toLowerCase() === 'admin') {

                                return <td key={_index}>
                                    <div className="admin" style={{padding: 0, justifyContent:'center'}}>
                                        <span className="imprimir"><i className="bi bi-gear" title="Imprimir Romaneio" onClick={onClickConfig} ref={refEditar}></i></span>
                                    </div>
                                </td>
                            }
                            else if (item === 'emissao') {

                                return <td key={_index}>{ correctDataUTC(bodyList.emissao) }</td>
                            }
                            else if (['encerrado','cancelado'].includes(item)) {
                                return <td key={_index} className='rowDataWidth'>{
                                    bodyList[item] ? <span  style={{
                                        fontSize: '0.8rem', padding: '7px 15px', borderRadius: '10px', backgroundColor:'#cd0c0cc9', color:'white'}}>SIM</span> : 'NÃO'}</td>
                                
                            }    
                            else if (item === 'filial') {
                                return <td key={_index}>{filiaisList.filter(item => item.idFilial == bodyList.filial)[0]?.identificacaoInterna ?? 'NÃO INFORMADO'}</td>
                            } 
                            return <td key={_index} className={item} style={{minWidth:'100px'}}> {bodyList[item]}</td>
                        })
                    }
                </tr>
            }
            {
                controleManifesto.flag ? <ModalControleManifesto show={controleManifesto.flag} setShow={setControleManifesto} title={`Controle de Manifesto`} body={controleManifesto.body} controleByManifestosList={ true } /> : ""
            }
        </Fragment>
    )
}