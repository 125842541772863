import { Fragment, useEffect, useRef, useState } from "react";
import ModalTableProdutos from "../../modal/ModalTableProdutos";

import "./BodyRow.scss";
import {
	compareDates,
	getAllData,
	getDanfeVST,
	getStatusPedidos,
	valorMoeda,
} from "../../../services/Servicos";
import { useSelector } from "react-redux";
import ModalTableProdutosInformacoes from "../../modal/ModalTableProdutosInformacoes";
import { Spinner } from "react-bootstrap";
import ModalVisualizarDanfe from "../../modal/ModalVisualizarDanfe";
import ModalError from "../../modal/ModalError";
import ModalFinalizarPedidos from "../../modal/ModalFinalizarPedidos";

export default function BodyRowPedidosFromPedidos({
	headList,
	bodyList,
	index,
	tableLength,
	cargas_id,
	setPedidos = () => {},
}) {
	const refRow = useRef(null);
	const refEditar = useRef(null);
	const user = useSelector((state) => state.userSlice);
	const filiaisList = user.filiaisList.data;

	const [loading, setLoading] = useState(false);
	const [loadingFunc, setLoadingFunc] = useState(true);
	const [produtos, setProdutos] = useState({
		flag: "",
		ID_EMPRESA: "",
		ID_FILIAL: "",
		ID_PEDIDO: "",
	});
	const [informacoesProdutos, setInformacoesProdutos] = useState({
		flag: "",
		ID_EMPRESA: "",
		ID_FILIAL: "",
		ID_PEDIDO: "",
	});

	const verInformacoesProdutos = (e, bodyList) => {
		e.preventDefault();
		const date = new Date(bodyList.dtemissao).toLocaleDateString("pt-BR", {
			timeZone: "UTC",
		});

		setInformacoesProdutos((last) => ({
			...last,
			flag: true,
			ID_EMPRESA: bodyList.idEmpresa,
			ID_FILIAL: bodyList.idFilial,
			ID_PEDIDO: bodyList.codigoPedido,
		}));
	};

	const onClickVerMais = (e, bodyList) => {
		e.preventDefault();
		const date = new Date(bodyList.dtemissao).toLocaleDateString("pt-BR", {
			timeZone: "UTC",
		});

		setProdutos((last) => ({
			...last,
			flag: true,
			ID_EMPRESA: bodyList.idEmpresa,
			ID_FILIAL: bodyList.idFilial,
			ID_PEDIDO: bodyList.codigoPedido,
			pedido: {
				id_filial: bodyList.idFilial,
				numero_pedido: bodyList.codigoPedido,
				emissao: date,
				nome_razao: bodyList.nomeRazao,
				nome_fantasia: bodyList.apelidoFantasia,
				cpf_cnpj: bodyList.cpfCnpj,
				endereco: bodyList.endereco,
				cep: bodyList.cep,
				bairro: bodyList.descbairro,
				cidade: bodyList.desccidade,
				numero: bodyList.numero,
				tel_1: bodyList.fonecelular,
				tel_2: bodyList.foneresidencial,
				valor_total: bodyList.vlrVenda,
				chave_nfe: bodyList.nfeChavedeacesso,
				nome_vendedor: bodyList.nomefunc,
				cargas_id,
				peso_nf: bodyList.pesoBruto,
				responsavel: String(user.name).toUpperCase(),
				cpf_responsavel: String(user.cpf).toUpperCase(),
			},
		}));
	};

	const onClickTableRow = (e) => {
		e.preventDefault();
		refRow.current.classList += " bg-selected";
		let refRowNumber = refRow.current.children[0].innerText;

		let i = refRowNumber;
		let actualSibling = refRow.current.nextSibling;

		while (i < tableLength) {
			if (actualSibling === null) {
				break;
			}
			let classList = "";
			actualSibling.classList.forEach((e) => {
				classList += `${e} `;
			});
			actualSibling.classList = classList.replace("bg-selected", "");

			actualSibling = actualSibling.nextSibling;
			i++;
		}

		i = refRow.current.children[0].innerText;
		actualSibling = refRow.current.previousSibling;
		while (0 < i) {
			if (actualSibling === null) {
				break;
			}

			let classList = "";
			actualSibling.classList.forEach((e) => {
				classList += `${e} `;
			});
			actualSibling.classList = classList.replace("bg-selected", "");

			// actualSibling.classList = ''
			actualSibling = actualSibling.previousSibling;
			i--;
		}
	};

	const [verPDF, setVerPDF] = useState({
		show: false,
		PDF: "",
		title: "",
	});

	const [error, setError] = useState({
		flag: false,
	});

	const findDanfe = async (e, bodyList_) => {
		setLoading(true);

		const { codigoPedido, idFilial, apelidoFantasia } = bodyList_;

		setVerPDF({
			show: true,
			PDF: await getDanfeVST(idFilial, codigoPedido),
			title: "Danfe",
		});

		setError((last) => ({
			...last,
			numero_pedido: "",
		}));
		setLoading(false);
	};

	const [finalizarPedidos, setFinalizarPedidos] = useState({
		flag: false,
	});

	const onClickAtualizarPedido = (e) => {
		const numero_pedido = bodyList.codigoPedido;
		const filial = bodyList.idFilial;
		setPedidos((last) => [
			...last.filter(
				(item) => item.codigoPedido != numero_pedido && item.idFilial != filial
			),
			...last
				.filter(
					(item) =>
						item.codigoPedido == numero_pedido && item.idFilial == filial
				)
				.map((item) => ({ ...item, stLogistica: 20 })),
		]);
	};

	const verificarPedido = async () => {
		if (bodyList.data_logistica) return;
		getAllData(
			`pedidos/monitoramento/${bodyList.idFilial}/${bodyList.codigoPedido}`
		)
			.then((resp) => {
				let dias_logistica = compareDates(bodyList.dtfaturamento, new Date());
				let data_logistica = "N/A";

				if (typeof resp.data == "object") {
					const data_formatada = new Date(resp.data.createdAt);
					data_formatada.setHours(-4, 0, 0);

					data_logistica = data_formatada.toLocaleDateString("pt-br", {
						timeZone: "UTC",
					});
					dias_logistica = compareDates(
						bodyList.dtfaturamento,
						data_formatada.toISOString()
					);
				}
				setPedidos((last) => [
					...last.filter((item) => item.codigoPedido != bodyList.codigoPedido),
					...last
						.filter((item) => item.codigoPedido === bodyList.codigoPedido)
						.map((item, index) => {
							return {
								...item,
								data_logistica,
								dias_logistica,
							};
						}),
				]);
			})
			.finally(() => setLoadingFunc(false));
	};
	useEffect(() => {
		if (window.location.pathname !== "/monitoramento-pedidos") return;
		verificarPedido();
	}, []);

	return (
		<Fragment>
			{
				<tr
					ref={refRow}
					onClick={onClickTableRow}
					className={
						!bodyList.nfeNumero || bodyList.nfeMod != "55" ? "rowDanger" : ""
					}
				>
					{headList.map((item, _index) => {
						if (item === "index") {
							return <td key={_index}> {index + 1}</td>;
						} else if (item.toLowerCase() === "admin") {
							return (
								<td key={_index}>
									<div className="admin" style={{ alignItems: "baseline" }}>
										{/* {} */}
										{bodyList.stLogistica == -1 ||
										bodyList.stLogistica == 24 ? (
											bodyList.nfeNumero && bodyList.nfeMod == "55" ? (
												<span className="editar">
													<i
														className="bi bi-plus-circle"
														title="Ver Produtos"
														onClick={(e) => onClickVerMais(e, bodyList)}
														ref={refEditar}
													></i>
												</span>
											) : (
												<span className="editar">
													<i
														className="bi bi-exclamation-triangle"
														title="Ver Produtos"
													></i>
												</span>
											)
										) : (
											<span
												title="Pedido atrelado a uma carga"
												onClick={(e) => verInformacoesProdutos(e, bodyList)}
											>
												<i
													className="bi bi-question-circle"
													style={{ cursor: "pointer" }}
												></i>
											</span>
										)}
										{loading ? (
											<Spinner animation="border" size="sm" />
										) : (
											<i
												className="bi bi-file-earmark-pdf"
												onClick={(e) => {
													findDanfe(e, bodyList);
												}}
												style={{ cursor: "pointer" }}
											></i>
										)}
									</div>
								</td>
							);
						} else if (item.toLowerCase() === "encerrar_pedido") {
							return (
								<td key={_index}>
									<div className="admin" style={{ alignItems: "baseline" }}>
										<span
											title="Encerrar Pedido sem Carga"
											onClick={(e) =>
												setFinalizarPedidos({
													flag: true,
													pedidos: [bodyList],
												})
											}
										>
											<i
												className="bi bi-exclamation-diamond"
												style={{ cursor: "pointer" }}
											></i>
										</span>
									</div>
								</td>
							);
						} else if (item === "status_pedido") {
							return (
								<td key={_index}>
									<span
										style={{
											fontSize: "0.8rem",
											padding: "7px 15px",
											borderRadius: "10px",
											backgroundColor:
												bodyList.stLogistica < 0
													? "#ff5757"
													: bodyList.stLogistica <= 2
													? "#00b4e1"
													: bodyList.stLogistica <= 5
													? "#c3e100"
													: "#00c66a",
										}}
									>
										{bodyList[item]}
									</span>
								</td>
							);
						} else if (item === "pesoBruto") {
							return (
								<td key={_index}>
									{Number(bodyList[item]).toLocaleString("pt-BR")}
								</td>
							);
						} else if (item === "idFilial") {
							return (
								<td key={_index}>
									{filiaisList.filter(
										(item) => item.idFilial == bodyList.idFilial
									)[0]?.identificacaoInterna ?? "NÃO INFORMADO"}
								</td>
							);
						} else if (item === "vlrVenda") {
							return (
								<td key={_index} className="rowDataWidth">
									{valorMoeda(bodyList.vlrVenda)}
								</td>
							);
						} else if (item === "stLogistica") {
							return (
								<td key={_index} className="rowDataWidth">
									{getStatusPedidos(bodyList.stLogistica)}
								</td>
							);
						}
						// else if (item === 'dias_producao') {

						//     return <td key={_index}>{compareDates(bodyList.dtcadastro, bodyList.dtfaturamento)}</td>
						// }
						// else if (item === 'dias_logistica') {
						//     return <td key={_index}>{loadingFunc ? <Spinner size='sm' animation="border" /> : bodyList.dias_logistica}</td>
						// }
						else if (
							[
								"dtemissao",
								"dtfaturamento",
								"dtcadastro",
								"data_cadastro",
								"data_faturamento",
							].includes(item)
						) {
							return bodyList[item] ? (
								<td key={_index}>
									{" "}
									{new Date(bodyList[item]).toLocaleDateString("pt-BR", {
										timeZone: "UTC",
									})}
								</td>
							) : (
								<td key={_index}></td>
							);
						}
						return (
							<td key={_index} className={item} style={{ minWidth: "100px" }}>
								{" "}
								{bodyList[item]}
							</td>
						);
					})}
				</tr>
			}
			{produtos.flag ? (
				<ModalTableProdutos
					show={produtos.flag}
					setShow={setProdutos}
					ID_EMPRESA={produtos.ID_EMPRESA}
					ID_FILIAL={produtos.ID_FILIAL}
					ID_PEDIDO={produtos.ID_PEDIDO}
					pedido={produtos.pedido}
					stLogistica={bodyList.stLogistica}
					setPedidos={setPedidos}
				/>
			) : (
				""
			)}
			{informacoesProdutos.flag ? (
				<ModalTableProdutosInformacoes
					show={informacoesProdutos.flag}
					setShow={setInformacoesProdutos}
					ID_EMPRESA={informacoesProdutos.ID_EMPRESA}
					ID_FILIAL={informacoesProdutos.ID_FILIAL}
					ID_PEDIDO={informacoesProdutos.ID_PEDIDO}
					pedido={informacoesProdutos.pedido}
					setPedidos={setPedidos}
				/>
			) : (
				""
			)}
			{verPDF.show ? (
				<ModalVisualizarDanfe
					title={verPDF.title}
					pdf={verPDF.PDF}
					setShow={setVerPDF}
					show={verPDF.show}
				/>
			) : (
				""
			)}
			{error.flag ? (
				<ModalError
					title={error.title}
					msg={error.msg}
					setShow={setError}
					show={error.flag}
				/>
			) : (
				""
			)}

			{finalizarPedidos.flag ? (
				<ModalFinalizarPedidos
					show={finalizarPedidos.flag}
					setShow={setFinalizarPedidos}
					{...finalizarPedidos}
					callback={onClickAtualizarPedido}
				/>
			) : (
				""
			)}
		</Fragment>
	);
}
