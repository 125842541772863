import { useEffect, useState } from "react";
import { Button, Card, Col, Modal, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
	cancelarManifestoFunc,
	encerrarManifestoByProtocolo,
	encerrarManifestoFunc,
	getAllData,
	getManifestoId,
	getManifestoPDF,
	getManifestoStatus,
	updateTable,
} from "../../services/Servicos";

import "./Modal.scss";
import ModalMontaManifesto from "./ModalMontaManifesto";
import ModalError from "./ModalError";
import ModalVisualizarPDFComputer from "./ModalVisualizarPDFComputer";
import ModalVisualizarDanfe from "./ModalVisualizarDanfe";
import axios from "axios";
import ModalManifesto from "./ModalManifesto";
import ModalVisualizarPDF from "./ModalVisualizarPDF";
import ModalVisualizarManifesto from "./ModalVisualizarManifesto";
import { update, updateManifestosList } from "../../redux/slicers/user";

export default function ModalControleManifesto(props) {
	const user = useSelector((state) => state.userSlice);

	const [statusMDFE, setStatusMDFE] = useState(null);
	const [codigoMDFE, setCodigoMDFE] = useState(null);
	const [manifestoId, setManifestoID] = useState(null);
	const [loadingStatus, setLoadingStatus] = useState({
		location: "pdf",
		flag: true,
	});
	const [montaManifesto, setMontaManifesto] = useState({
		flag: false,
	});
	const [error, setError] = useState({
		flag: false,
	});
	const [verPDF, setVerPDF] = useState({
		show: false,
		PDF: "",
		title: "",
	});
	const dispatch = useDispatch();

	const handlerSimpleClose = () => props.setShow({ flag: false });

	const updateData = async () => {
		// dispatch(updateManifestosList([]))
		if (props.body.id_manifesto) {
			if (user.tipo === "empresa") {
				await getAllData("manifesto/empresa")
					.then((response) => {
						//
						dispatch(updateManifestosList(response.data));
					})
					.catch((error) => {
						setError({
							list: error.message,
							flag: true,
						});
					});
			} else {
				await getAllData("manifestos/gestao")
					.then((response) => {
						//
						dispatch(updateManifestosList(response.data));
					})
					.catch((error) => {
						setError({
							list: error.message,
							flag: true,
						});
					});
			}
		}

		handlerSimpleClose();
	};

	const cancelarManifesto = (e) => {
		if (user.tipo.toLowerCase() !== "empresa")
			return setError({
				flag: true,
				list: `Função bloqueada`,
				title: "Aviso!",
			});
		if (loadingStatus.flag)
			return setError({
				flag: true,
				list: `Aguarde a finalização da solicitação feita: "${loadingStatus.location.toUpperCase()} MANIFESTO"`,
				title: "Aviso!",
			});
		if (!codigoMDFE?.id_manifesto)
			return setError({
				flag: true,
				list: `Manifesto não Gerado`,
				title: "Aviso!",
			});

		setLoadingStatus({ location: "cancelar", flag: true });
		//
		// return

		cancelarManifestoFunc(codigoMDFE?.id_manifesto)
			.then((resp) => {
				setError({
					flag: true,
					title: "Atenção",
					list: resp,
				});
			})
			.catch((err) => {
				setError({ flag: true, list: err, title: "Aviso!" });
			})
			.finally(() => {
				setLoadingStatus({ location: "cancelar", flag: false });
			});
	};

	const excluirManifesto = (e) => {
		if (user.tipo.toLowerCase() !== "empresa")
			return setError({
				flag: true,
				list: `Função bloqueada`,
				title: "Aviso!",
			});
		if (loadingStatus.flag)
			return setError({
				flag: true,
				list: `Aguarde a finalização da solicitação feita: "${loadingStatus.location.toUpperCase()} MANIFESTO"`,
				title: "Aviso!",
			});
		if (!codigoMDFE?.id_manifesto)
			return setError({
				flag: true,
				list: `Manifesto não Gerado`,
				title: "Aviso!",
			});

		setLoadingStatus({ location: "excluir", flag: true });
		updateTable("manifesto/empresa/update", codigoMDFE.id_manifesto, {
			data_cancelamento: new Date().toISOString(),
			user_cancelamento: user.name,
		})
			.then((resp) => {
				setError({
					flag: true,
					title: "Atenção",
					list: "Requisição Excluida com Sucesso",
				});

				updateData();
			})
			.catch((err) => {
				setError({ flag: true, list: err, title: "Aviso!" });
			})
			.finally(() => {
				setLoadingStatus({ location: "cancelar", flag: false });
			});
	};

	const encerrarManifesto = (e) => {
		if (user.tipo.toLowerCase() !== "empresa")
			return setError({
				flag: true,
				list: `Função bloqueada`,
				title: "Aviso!",
			});
		if (loadingStatus.flag)
			return setError({
				flag: true,
				list: `Aguarde a finalização da solicitação feita: "${loadingStatus.location.toUpperCase()} MANIFESTO"`,
				title: "Aviso!",
			});
		// if(!codigoMDFE?.id_manifesto) return setError({flag: true, list: `Manifesto não Gerado`, title:'Aviso!'})

		setLoadingStatus({ location: "encerrar", flag: true });

		if (!props.body.id_manifesto) {
			encerrarManifestoByProtocolo({
				protocolo: props.body.protocolo,
				chave: props.body.chave_mdfe,
			})
				.then((resp) => {
					setError({
						flag: true,
						title: "Atenção",
						list: resp.data,
					});
				})
				.catch((err) => {
					setError({ flag: true, list: err.message, title: "Aviso!" });
				})
				.finally(() => {
					setLoadingStatus({ location: "encerrar", flag: false });
				});
			return;
		}

		encerrarManifestoFunc(codigoMDFE?.id_manifesto)
			.then((resp) => {
				setError({
					flag: true,
					title: "Atenção",
					list: resp,
				});
			})
			.catch((err) => {
				setError({ flag: true, list: err.message, title: "Aviso!" });
			})
			.finally(() => {
				setLoadingStatus({ location: "encerrar", flag: false });
			});
	};

	const [manifestoDeCargaFile, setManifestoDeCargaFile] = useState({
		show: false,
		PDF: "",
	});

	const verPDfHandler = async (e) => {
		if (loadingStatus.flag)
			return setError({
				flag: true,
				list: `Aguarde a finalização da solicitação feita: "${loadingStatus.location.toUpperCase()} MANIFESTO"`,
				title: "Aviso!",
			});
		if (!codigoMDFE?.id_manifesto)
			return setError({
				flag: true,
				list: `Manifesto não Gerado`,
				title: "Aviso!",
			});

		setLoadingStatus({ location: "pdf", flag: true });
		if (statusMDFE == "ERRO") {
			setError((last) => ({ ...last, flag: true }));
			return setLoadingStatus({ location: "pdf", flag: false });
		}

		let artosAuth = user.authArtos;
		if (!user.authArtos) {
			artosAuth = await getAllData("manifesto/auth");
			artosAuth = artosAuth.data.token;
			dispatch(update({ authArtos: artosAuth }));
		}

		//não deve ser bloqueado porque ha ainda a necessidade de forçar a confecção do manifesto

		await axios({
			url: `https://api-integracao.artossoftware.com.br/v1/mdfe/${codigoMDFE?.id_manifesto}/chave`,
			method: "get",
			headers: {
				Authorization: `Bearer ${artosAuth}`,
				Accept: "application/json",
			},
		});

		//não deve ser bloqueado porque ha ainda a necessidade de forçar a confecção do manifesto

		axios({
			url: `https://api-integracao.artossoftware.com.br/v1/mdfe/${codigoMDFE?.id_manifesto}/pdf`,
			method: "get",
			responseType: "arraybuffer",
			headers: {
				Authorization: `Bearer ${artosAuth}`,
				Accept: "application/pdf, application/json",
			},
		})
			.then((resp) => {
				setManifestoDeCargaFile({
					show: true,
					PDF: resp.data,
				});
			})
			.catch((err) => {
				const decoded = JSON.parse(new TextDecoder().decode(err.response.data));
				setError({ flag: true, list: decoded, title: "Aviso!" });
			})
			.finally(() => {
				setLoadingStatus({ location: "pdf", flag: false });
			});
	};

	useEffect(() => {
		if (props.body.id_manifesto) {
			setStatusMDFE("SOLICITADO");
			setCodigoMDFE({ id_manifesto: props.body.id_manifesto });
			return;
		}
		getManifestoId(props.body.id, user.tipo)
			.then((response) => {
				if (response.status == 204) {
					setStatusMDFE("NÃO ENVIADO");
					setLoadingStatus({ location: "pdf", flag: false });
				} else {
					setStatusMDFE("SOLICITADO");
					setCodigoMDFE(response.data);
				}
			})
			.catch((error) => {
				if (error.response.status == 412) {
					setLoadingStatus({ location: "pdf", flag: false });
				}
			});
	}, []);

	useEffect(() => {
		if (!codigoMDFE?.id_manifesto) return;
		getManifestoStatus(codigoMDFE?.id_manifesto)
			.then((response) => {
				setStatusMDFE("APROVADO");
			})
			.catch((err) => {
				setStatusMDFE("ERRO");

				setError({ flag: true, list: err, title: "Aviso!" });
			})
			.finally(() => {
				setLoadingStatus({ location: "pdf", flag: false });
			});
	}, [codigoMDFE]);
	return (
		<>
			<Modal
				show={props.show}
				onHide={handlerSimpleClose}
				backdrop="static"
				keyboard={false}
				centered
				size="lg"
			>
				<Modal.Header closeButton>
					<Modal.Title>
						Controle de Manifesto de Carga: N°{" "}
						<b>
							<i style={{ fontSize: "inherit" }}>{props.body.id}</i>
						</b>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body style={{ padding: "70px 30px" }}>
					{props.controleByManifestosList ? (
						<h3 className="text-center mb-4"> ID: {props.body.id_manifesto}</h3>
					) : (
						""
					)}
					<Row
						className={
							props.controleByManifestosList ? "justify-content-center" : ""
						}
					>
						{props.controleByManifestosList ? (
							""
						) : (
							<Col sm="12" md="6" lg="3">
								<Card
									className="card-manifesto"
									onClick={(e) => {
										if (!props.body.caminhao) {
											setError({
												flag: true,
												title: "Erro",
												list: {
													erro: "Validar Manifesto",
													motivo: "Caminhão não especificado para a carga",
													correcao: "Disponibilize um caminhão a carga",
												},
											});
											return;
										}
										if (!props.body.caminhoneiro) {
											setError({
												flag: true,
												title: "Erro",
												list: {
													erro: "Validar Manifesto",
													motivo:
														"Caminhão sem CONDUTOR especificado para a carga",
													correcao: "Disponibilize o caminhão a um CONDUTOR",
												},
											});
											return;
										}
										if (codigoMDFE?.id_manifesto && !codigoMDFE?.cancelado) {
											return setError({
												flag: true,
												title: "Erro",
												list: {
													erro: "Gerar Manifesto",
													motivo: "Manifesto gerado anteriormente!",
													correcao: "Cancele o manifesto e repita o processo",
												},
											});
										}
										setMontaManifesto({ flag: true });
									}}
								>
									<span style={{ position: "relative" }}>
										<i
											className="bi bi-truck"
											style={{
												fontSize: "3rem",
												opacity: "0.4",
												color: "#347d68",
												filter: "blur(1.5px)",
											}}
										></i>
										<i
											className="bi bi-card-heading"
											style={{
												position: "absolute",
												left: "-15px",
												bottom: "30px",
												fontSize: "2rem",
											}}
										></i>
									</span>
									<Card.Title>Gerar Manifesto</Card.Title>
								</Card>
							</Col>
						)}
						<Col sm="12" md="6" lg="3">
							<Card className="card-manifesto" onClick={encerrarManifesto}>
								<span style={{ position: "relative" }}>
									<i
										className="bi bi-truck"
										style={{
											fontSize: "3rem",
											opacity: "0.4",
											color: "#347d68",
											filter: "blur(1.5px)",
										}}
									></i>
									<i
										className="bi bi-check-circle"
										style={{
											position: "absolute",
											left: "-15px",
											bottom: "30px",
											fontSize: "2rem",
										}}
									></i>
								</span>
								<Card.Title>
									Encerrar Manifesto{" "}
									{loadingStatus.location == "encerrar" &&
									loadingStatus.flag ? (
										<Spinner animation="border" size="sm" />
									) : (
										""
									)}
								</Card.Title>
							</Card>
						</Col>
						<Col sm="12" md="6" lg="3">
							<Card
								className={`card-manifesto active-animation-card-manifesto 
                            ${
															codigoMDFE?.cancelado
																? "active-animation-danger"
																: ""
														}
                            ${
															statusMDFE?.toUpperCase() === "APROVADO"
																? "active-animation-success"
																: ""
														}
                            ${
															statusMDFE?.toUpperCase() === "ERRO"
																? "active-animation-danger"
																: ""
														}
                            ${
															statusMDFE?.toUpperCase() === "REPROVADO"
																? "active-animation-danger"
																: ""
														}
                            ${
															statusMDFE?.toUpperCase() === "SOLICITADO"
																? "active-animation-warning"
																: ""
														}
                            ${
															statusMDFE?.toUpperCase() === "NÃO ENVIADO"
																? "active-animation-dark"
																: ""
														}
                            ${!statusMDFE ? "active-animation-dark" : ""}
                            `}
								data-before={
									!statusMDFE
										? "VERIFICANDO"
										: codigoMDFE?.cancelado
										? "CANCELADO"
										: statusMDFE
								}
								onClick={verPDfHandler}
							>
								<span style={{ position: "relative" }}>
									<i
										className="bi bi-truck"
										style={{
											fontSize: "3rem",
											opacity: "0.4",
											color: "#347d68",
											filter: "blur(1.5px)",
										}}
									></i>
									<i
										className="bi bi-file-earmark-pdf"
										style={{
											position: "absolute",
											left: "-15px",
											bottom: "30px",
											fontSize: "2rem",
										}}
									></i>
								</span>
								<Card.Title>
									Ver Manifesto{" "}
									{loadingStatus.location == "pdf" && loadingStatus.flag ? (
										<Spinner animation="border" size="sm" />
									) : (
										""
									)}
								</Card.Title>
							</Card>
						</Col>
						<Col sm="12" md="6" lg="3">
							<Card className="card-manifesto" onClick={cancelarManifesto}>
								<span style={{ position: "relative" }}>
									<i
										className="bi bi-truck"
										style={{
											fontSize: "3rem",
											opacity: "0.4",
											color: "#347d68",
											filter: "blur(1.5px)",
										}}
									></i>
									<i
										className="bi bi-x-circle"
										style={{
											position: "absolute",
											left: "-15px",
											bottom: "30px",
											fontSize: "2rem",
										}}
									></i>
								</span>
								<Card.Title>
									Cancelar Manifesto{" "}
									{loadingStatus.location == "cancelar" &&
									loadingStatus.flag ? (
										<Spinner animation="border" size="sm" />
									) : (
										""
									)}
								</Card.Title>
							</Card>
						</Col>
						<Col sm="12" md="6" lg="3">
							<Card className="card-manifesto" onClick={excluirManifesto}>
								<span style={{ position: "relative" }}>
									<i
										className="bi bi-truck"
										style={{
											fontSize: "3rem",
											opacity: "0.4",
											color: "#347d68",
											filter: "blur(1.5px)",
										}}
									></i>
									<i
										className="bi bi-trash"
										style={{
											position: "absolute",
											left: "-15px",
											bottom: "30px",
											fontSize: "2rem",
										}}
									></i>
								</span>
								<Card.Title>
									Excluir Requisição{" "}
									{loadingStatus.location == "excluir" && loadingStatus.flag ? (
										<Spinner animation="border" size="sm" />
									) : (
										""
									)}
								</Card.Title>
							</Card>
						</Col>
					</Row>
				</Modal.Body>
				<Modal.Footer>
					<Col sm="3">
						<Button variant="secondary" onClick={handlerSimpleClose}>
							Fechar
						</Button>
					</Col>
				</Modal.Footer>
			</Modal>
			{montaManifesto.flag ? (
				<ModalMontaManifesto
					show={montaManifesto.flag}
					setShow={setMontaManifesto}
					body={props.body}
					setCodigoMDFE={setCodigoMDFE}
				/>
			) : (
				""
			)}
			{error.flag ? (
				<ModalError show={error.flag} setShow={setError} {...error} />
			) : (
				""
			)}
			{manifestoDeCargaFile.show ? (
				<ModalVisualizarManifesto
					title={"Manifesto de Carga"}
					pdf={manifestoDeCargaFile.PDF}
					setShow={setManifestoDeCargaFile}
					show={manifestoDeCargaFile.show}
				/>
			) : (
				""
			)}
		</>
	);
}
