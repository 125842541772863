import { createRef, useEffect, useLayoutEffect, useRef, useState } from "react";
import { Button, Col, Row, Spinner, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import PlanoLositicaLayout from "../PlanoLositicaLayout";
import Loading from "../../loading/Loading";
import {
	updateEmbalagensList,
	updateManifestosList,
} from "../../../redux/slicers/user";
import {
	getAllData,
	getDateInitMonth,
	getTableColumns,
} from "../../../services/Servicos";
import HeadTable from "../../table/head/HeadRow";
import {
	filtroDinamicoArrays,
	removerDadosDuplicados,
} from "../../../services/helpers";
import BodyRowManifestos from "../../table/body/BodyRowManifestos";
import ScrollbarLeft from "../../scrollbars/ScrollbarLeft";

const tableRef = createRef();
const tbodyRef = createRef();

const buttomStyle = {
	textAlign: "center",
	padding: "15px",
	boxShadow: "0 5px 10px #00000021",
	borderRadius: "5px",
	marginBottom: "0.8rem",
};

export default function Manifestos() {
	const user = useSelector((state) => state.userSlice);
	const manifestos = user.manifestosList.data;

	const dispatch = useDispatch();

	const [error, setError] = useState({
		flag: false,
		msg: "",
	});

	const [indexList, setIndexList] = useState(15);
	const [loading, setLoading] = useState(true);

	const [selected, setSelected] = useState("Geral");
	const [headList, setHead] = useState([]);
	const [dataFiltro, setDataFiltro] = useState("");

	const updateColums = async () => {
		const cargasGet = await getTableColumns("Manifestos");
		const except = [
			"user_cancelamento",
			"data_cancelamento",
			"created_at",
			"updated_at",
			"emissao",
			"filial",
		];
		setHead((last) => [
			"index",
			"admin",
			"emissao",
			"filial",
			...Object.keys(cargasGet.data).filter((item) => !except.includes(item)),
		]);
	};
	const updateData = async (data_inicial, data_final) => {
		let filial = user.id_filial;

		if (user.id_filial == 2 || user.tipo.toLowerCase() == "empresa") {
			filial = null;
		}

		const [anoI, mesI, diaI] = data_inicial.split("-");
		const dataI = new Date(anoI, Number(mesI) - 1, diaI);
		const [anoF, mesF, diaF] = data_final.split("-");
		const dataF = new Date(anoF, Number(mesF) - 1, diaF);
		let objCons = { filial, data_inicial, data_final };

		setDataFiltro(
			`de ${dataI.toLocaleDateString()} até ${dataF.toLocaleDateString()}`
		);
		try {
			const cargas = await getAllData(
				user.tipo === "empresa" ? "manifesto/empresa" : "manifestos/gestao",
				objCons
			);
			dispatch(updateManifestosList(cargas.data));
		} catch (error) {
			setError({
				list:
					"Error na obtenção dos Manifestos existentes." +
					JSON.stringify(error),
				flag: true,
			});
		} finally {
			setLoading(false);
		}
	};
	const listNaoEncerrados = async (data_inicial, data_final) => {
		try {
			const data = await getAllData("manifesto/carga/nao-encerrados");

			setHead([
				"admin",
				"id_manifesto",
				...Object.keys(data.data[0]).filter((item) => !item.includes("uuid")),
			]);
			const dados = data.data.map((item) => ({
				chave_mdfe: item.chave_mdfe,
				protocolo: item.protocolo,
				id_manifesto: item.uuid,
			}));

			dispatch(updateManifestosList(dados));
		} catch (error) {
			setError({
				list:
					"Error na obtenção dos Manifestos existentes." +
					JSON.stringify(error),
				flag: true,
			});
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		setLoading(true);
		setHead([]);
		dispatch(updateManifestosList([]));
		if (selected != "Geral") {
			listNaoEncerrados();
			return;
		}
		updateColums();
		const date = new Date();
		const dateCorrect = getDateInitMonth(date);
		updateData(dateCorrect.data_inicial, dateCorrect.data_final);
	}, [selected]);

	const [filtersNew, setFiltersNew] = useState({});
	const [filters, setFilters] = useState([]);
	const [filteredTable, setFilteredTable] = useState(false);

	const onClickRemoveFilter = (e) => {
		e.preventDefault();
		const value = e.target.parentNode.firstElementChild.innerText;
		const [key, keyValue] = value.split(":");
		setFilters((last) => [
			...last.filter(
				(item) =>
					String(item[key]).replaceAll(" ", "") !== keyValue.replaceAll(" ", "")
			),
		]);
	};

	useEffect(() => {
		if (!filters.length) {
			setIndexList(15);
			setFilteredTable(false);
			return;
		}

		let testeFilters = {};

		filters.forEach((ele) => {
			// testeFilters[arguments[ele]]
			const param = Object.getOwnPropertyNames(ele)[0];
			testeFilters[param] = [];
		});

		filters.forEach((ele) => {
			// testeFilters[arguments[ele]]
			const param = Object.getOwnPropertyNames(ele)[0];

			testeFilters[param].push(String(ele[param]).toLowerCase());

			//linha adicionada para incluir filtros com valor nulo
			if (String(ele[param]).toLowerCase() == "false") {
				testeFilters[param].push("null");
			}
		});

		let arrayRetorno = [manifestos];

		Object.keys(testeFilters).forEach((key, index) => {
			arrayRetorno.push(
				filtroDinamicoArrays(
					testeFilters,
					key,
					arrayRetorno[arrayRetorno.length - 1]
				)
			);
		});

		const arrayF = removerDadosDuplicados(
			arrayRetorno[arrayRetorno.length - 1],
			"id"
		);
		setFilteredTable(arrayF.reverse());
	}, [filters]);

	useEffect(() => {
		const refTable = tableRef.current;
		const scrollHandler = (e) => {
			const tbodyHeight = tbodyRef.current.clientHeight;
			const divTableHeight = e.target.clientHeight;
			const scrollPosition = e.target.scrollTop;
			if (scrollPosition >= tbodyHeight - divTableHeight) {
				setTimeout(() => {
					setIndexList(indexList + 10);
				}, 1000);
			}
		};

		refTable.addEventListener("scroll", scrollHandler, false);

		return () => refTable.removeEventListener("scroll", scrollHandler, false);
	});

	const onChangePage = (page) => {
		setSelected(page);
	};
	return (
		<PlanoLositicaLayout>
			<div className="card-header d-flex align-items-center">
				<h3 style={{ marginBottom: 0, marginRight: "5px" }}>
					Tabela de Manifestos -{" "}
					<i style={{ fontSize: "inherit" }}>{dataFiltro}</i>{" "}
				</h3>
				{loading ? <Spinner size="sm" animation="border" /> : ""}
			</div>
			<div>
				<Row className="mt-2 row-filters">
					<Col>
						<div>
							{filters.map((item, key) =>
								Object.keys(item).map((key, index) => {
									return (
										<span className="filters-card" key={index}>
											<span className="filters">
												<span className="title">
													{key}: {item[key]}
												</span>
												<i
													className="bi bi-x-lg"
													onClick={onClickRemoveFilter}
												></i>
											</span>
										</span>
									);
								})
							)}
						</div>
					</Col>
				</Row>
			</div>

			<Row>
				<Col sm="12" md="4">
					<Button
						onClick={() => onChangePage("Geral")}
						style={buttomStyle}
						variant="info"
						className={selected == "Geral" ? "bg-dark text-light" : ""}
					>
						{" "}
						<i className="bi bi-list"></i> Lista de Manifestos
					</Button>
					<Button
						onClick={() => onChangePage("Não Encerrado")}
						style={buttomStyle}
						variant="info"
						className={selected == "Não Encerrado" ? "bg-dark text-light" : ""}
					>
						{" "}
						<i className="bi bi-x-lg"></i> Não Encerrados
					</Button>
				</Col>
				<Col sm="12" md="8">
					<ScrollbarLeft
						tableRef={tableRef}
						coluna2={4}
						maxima_distancia={150}
					/>
					<div
						className=" responsive-table"
						ref={tableRef}
						style={{ position: "relative", maxHeight: "600px" }}
					>
						<Table size="sm" bordered>
							<thead style={{ zIndex: "49" }}>
								<HeadTable
									arrayValues={[...manifestos].reverse()}
									headerList={headList}
									setArrayFilters={setFiltersNew}
									arrayFilters={filtersNew}
									setFiltersOld={setFilters}
									filtersOld={filters}
									fn={updateData}
								/>
							</thead>
							<tbody ref={tbodyRef}>
								{filteredTable
									? [...filteredTable]
											.sort((a, b) => {
												if (a.id > b.id) return -1;
												if (a.id < b.id) return 1;
												return 0;
											})
											.map((item, index, self) => {
												if (index < indexList) {
													return (
														<BodyRowManifestos
															key={index}
															bodyList={item}
															headList={headList}
															index={index}
															tableLength={self.length}
															setError={setError}
														/>
													);
												}
											})
									: manifestos &&
									  [...manifestos].reverse().map((item, index, self) => {
											if (index < indexList) {
												return (
													<BodyRowManifestos
														key={index}
														bodyList={item}
														headList={headList}
														index={index}
														tableLength={self.length}
														setError={setError}
													/>
												);
											}
									  })}
							</tbody>
						</Table>
						{/* CASO NÃO HAJA MAIS VENDAS, REMOVER LOADING  */}
						{filteredTable ? (
							indexList < filteredTable.length ? (
								<Loading color={"black"} msg="Loading" />
							) : (
								""
							)
						) : indexList < manifestos.length ? (
							<Loading color={"black"} msg="Loading" />
						) : (
							""
						)}
					</div>
				</Col>
			</Row>
		</PlanoLositicaLayout>
	);
}
