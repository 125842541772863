import { createRef, useEffect, useState } from "react";
import { Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Html5QrcodeScanner } from "html5-qrcode";
import { Html5Qrcode } from "html5-qrcode";
import "./Modal.scss";
import { numerosEditadoBR } from "../../services/Servicos";
import ModalError from "./ModalError";

const qrCodeRef = createRef();

export default function ModalLerQRCode({
	msg,
	title,
	show,
	setShow,
	setProdutoSelecionado = () => {},
	produtoSelecionado,
	entregar = true,
	entregarFn = () => {},
	carregarFn = () => {},
}) {
	const handlerSimpleClose = () => {
		scanner.stop();
		setTimeout(() => {
			setShow(false);
		});
	};

	const user = useSelector((state) => state.userSlice);
	const produtos = user.produtosList.data;
	const embalagens = user.embalagensList.data;
	const produtosEEmbalagensList = user.produtosEEmbalagens.data;
	const romaneios = user.romaneiosList.data;
	const [scanner, setScanner] = useState(null);
	const [error, setError] = useState({
		flag: false,
	});
	const [restart, setRestart] = useState(0);

	const startScanning = async () => {
		setScanner(await QRCodeScannerAuto());
	};

	useEffect(() => {
		// getCameras()
	}, []);
	useEffect(() => {
		startScanning();
		// if (user.email.toLowerCase() == 'codji.suporte@gmail.com') {

		// } else {
		//     QRCodeScanner()

		// }
	}, [restart]);

	const onInputQrCodeChange = (e) => {
		e.preventDefault();

		const qr_sku_selecionado = qrCodeRef.current.value;

		if (qr_sku_selecionado !== produtoSelecionado.qr_sku) {
			setError({
				flag: true,
				list: ["msg: O produto verificado não é o mesmo do selecionado"],
				title: "Atenção",
			});
			setRestart((last) => last + 1);
			// setTimeout(() => {
			//     setRestart(last => last + 1)

			// },2000)
			return;
		}
		const produtoEmbalagemSelecionada = produtosEEmbalagensList.find(
			(item) => item.qr_sku == qr_sku_selecionado
		);
		if (!produtoEmbalagemSelecionada) {
			setError({
				flag: true,
				list: ["msg: Produto Bipado não esta na disponivel nessa carga"],
				title: "Atenção",
			});
			setRestart((last) => last + 1);
			return;
		}
		if (produtoEmbalagemSelecionada.tipo === "PRODUTO") {
			setProdutoSelecionado({
				...produtoEmbalagemSelecionada,
				tipo: "PRODUTO",
			});
		} else {
			setProdutoSelecionado({
				...produtoEmbalagemSelecionada,
				tipo: "EMBALAGEM",
			});
		}
		qrCodeRef.current.value = "";
		if (entregar) {
			entregarFn();
		} else {
			carregarFn();
		}
		// // return
		handlerSimpleClose();
	};

	const [nomeCliente, setNomeCliente] = useState(null);

	useEffect(() => {
		let pedido;

		if (produtoSelecionado?.numero_embalagem) {
			setNomeCliente(
				romaneios.find(
					(item) => item.numero_pedido == produtoSelecionado.numero_pedido
				)?.nome_razao
			);
		} else {
			setNomeCliente(
				romaneios.find(
					(item) => item.numero_pedido == produtoSelecionado.numero_pedido
				)?.nome_razao
			);
		}
	}, []);

	return (
		<>
			<Modal
				show={show}
				onHide={handlerSimpleClose}
				backdrop="static"
				keyboard={false}
				centered
				size="lg"
			>
				<Modal.Header closeButton>
					<Modal.Title>{title}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div style={{ display: "flex" }}>
						<div style={{ fontSize: "0.8rem", padding: "5px", width: "50%" }}>
							<div
								className="card"
								style={{
									padding: "5px 10px",
									backgroundColor: "#F9F9F9",
									borderRadius: "5px",
									border: "0.5px solid #F9F9F9",
								}}
							>
								<h4 style={{ fontFamily: "Kanit", color: "#333333" }}>
									{produtoSelecionado?.descricao || "Sem Descrição"}
								</h4>
								<br />
								<h6
									style={{ fontFamily: "Kanit", color: "#333333", margin: 0 }}
								>
									Cliente:{" "}
									<span>
										{nomeCliente ? (
											nomeCliente
										) : (
											<Spinner animation="border" size="sm" />
										)}
									</span>
								</h6>
								<h6
									style={{ fontFamily: "Kanit", color: "#333333", margin: 0 }}
								>
									Tipo:{" "}
									<span>
										{produtoSelecionado?.numero_embalagem
											? "EMBALAGEM"
											: "PRODUTO"}
									</span>
								</h6>
								<h6
									style={{ fontFamily: "Kanit", color: "#333333", margin: 0 }}
								>
									{produtoSelecionado?.numero_embalagem ? "N° " : ""}{" "}
									<span>
										{produtoSelecionado?.numero_embalagem
											? String(produtoSelecionado?.numero_embalagem).padStart(
													7,
													0
											  )
											: ""}
									</span>
								</h6>
								<br />
								<h6
									style={{ fontFamily: "Kanit", color: "#333333", margin: 0 }}
								>
									Carga: <span>{produtoSelecionado.cargas_id} </span>
								</h6>
								<h6
									style={{ fontFamily: "Kanit", color: "#333333", margin: 0 }}
								>
									Pedido: <span>{produtoSelecionado?.numero_pedido}</span>
								</h6>
								<h6
									style={{ fontFamily: "Kanit", color: "#333333", margin: 0 }}
								>
									Código Item: <span>{produtoSelecionado?.id}</span>
								</h6>
								<h6
									style={{ fontFamily: "Kanit", color: "#333333", margin: 0 }}
								>
									Código Produto:{" "}
									<span>{produtoSelecionado?.codigo ?? "N/A"}</span>
								</h6>
								<br />
								<h6
									style={{ fontFamily: "Kanit", color: "#333333", margin: 0 }}
								>
									Quantidade:{" "}
									<span>
										{numerosEditadoBR(
											produtoSelecionado?.qtde ||
												produtoSelecionado?.qtde_produtos
										)}
									</span>
								</h6>
								<h6
									style={{ fontFamily: "Kanit", color: "#333333", margin: 0 }}
								>
									Comprimento{" "}
									<span>
										{numerosEditadoBR(produtoSelecionado?.comprimento)} [m]
									</span>
								</h6>
								<h6
									style={{ fontFamily: "Kanit", color: "#333333", margin: 0 }}
								>
									Peso:{" "}
									<span>{numerosEditadoBR(produtoSelecionado?.peso)} [kg]</span>
								</h6>
								<h6
									style={{ fontFamily: "Kanit", color: "#333333", margin: 0 }}
								>
									Unidade:{" "}
									<span>{produtoSelecionado?.abreviatura ?? "N/A"}</span>
								</h6>
								<h6
									style={{ fontFamily: "Kanit", color: "#333333", margin: 0 }}
								>
									ID Volume:{" "}
									<span>
										{numerosEditadoBR(produtoSelecionado?.id_volume ?? 0)}{" "}
									</span>
								</h6>

								{new Array(2).fill(1).map((item, index) => (
									<br key={index} />
								))}

								<Row className="justify-content-between">
									<Col>
										<span style={{ fontSize: "0.8rem" }}>@B-LOG</span>
									</Col>
									<Col className="text-end">
										<span style={{ fontSize: "0.8rem" }}>
											{new Date().toLocaleString("pt-BR")}
										</span>
									</Col>
								</Row>
							</div>
						</div>

						<div style={{ position: "relative", width: "50%" }}>
							<div
								style={{ marginLeft: "0.8rem", width: "100%" }}
								id="reader"
								className="mb-2"
							></div>
							{scanner ? <div className="lineScanner"></div> : ""}
						</div>
					</div>
					<Form.Control
						name="filterKeyword"
						className="form-control-input mb-2"
						placeholder="sku"
						ref={qrCodeRef}
						onInput={onInputQrCodeChange}
						disabled
					/>
				</Modal.Body>
			</Modal>

			{error.flag ? (
				<ModalError
					title={error.title}
					show={error}
					setShow={setError}
					{...error}
				/>
			) : (
				""
			)}
		</>
	);
}

async function QRCodeScanner() {
	let devices = await Html5Qrcode.getCameras();
	let cameraId = devices[0].id;
	let scanner = new Html5QrcodeScanner(
		"reader",
		{
			fps: 10,
			qrbox: { width: 250, height: 250 },
			videoConstraints: { facingMode: "environment" },
		},
		/* verbose= */ false
	);
	return scanner.render(onScanSuccess, onScanFailure);

	function onScanSuccess(decodedText, decodedResult) {
		// handle the scanned code as you like, for example:
		qrCodeRef.current.value = String(decodedText)
			.replace("{", "")
			.replace("}", "");
		/* dispachando um evento para o input perceber a mudança */
		const ev = new Event("input", { bubbles: true });
		qrCodeRef.current.dispatchEvent(ev);
		scanner.clear();
	}

	function onScanFailure(error) {
		// handle scan failure, usually better to ignore and keep scanning.
		// for example:
		console.warn(`Code scan error = ${error}`);
	}
}

// async function QRCodeScanner() {
//     let devices = await Html5Qrcode.getCameras();
//     let cameraId = devices[0].id;
//     let scanner = new Html5QrcodeScanner(
//         "reader",
//         { fps: 10, qrbox: {width: 250, height: 250},  },
//         /* verbose= */ false);

//     return scanner.render(onScanSuccess, onScanFailure)

//     function onScanSuccess(decodedText, decodedResult) {
//         // handle the scanned code as you like, for example:
//         qrCodeRef.current.value = String(decodedText).replace('{','').replace('}','')
//         /* dispachando um evento para o input perceber a mudança */
//         const ev = new Event('input', {bubbles: true})
//         qrCodeRef.current.dispatchEvent(ev)
//         scanner.clear()
//     }

//     async function onScanFailure(error) {
//     // handle scan failure, usually better to ignore and keep scanning.
//         // for example:
//         console.warn(`Code scan error = ${error}`);
//     }
// }
async function QRCodeScannerAuto() {
	// let devices = await Html5Qrcode.getCameras();
	// let cameraId = devices[0].id;
	const scanner = new Html5Qrcode("reader");
	const config = { fps: 10, qrbox: { width: 500, height: 500 } };

	const qrCodeSuccessCallback = (decodedText, decodedResult) => {
		//  handle the scanned code as you like, for example:
		qrCodeRef.current.value = String(decodedText)
			.replace("{", "")
			.replace("}", "");
		/* dispachando um evento para o input perceber a mudança */
		const ev = new Event("input", { bubbles: true });
		qrCodeRef.current.dispatchEvent(ev);
		scanner.stop();
	};

	scanner.start({ facingMode: "environment" }, config, qrCodeSuccessCallback);
	return scanner;
}
