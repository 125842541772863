import { Fragment, useEffect, useState } from "react";
import { Card, Col, Row, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import Chart from "react-apexcharts";
import ModalAtualizarDataEntradaVST from "../modal/ModalAtualizarDataEntradaVST";
import {
	getAllData,
	getDateInit,
	numerosEditadoBR,
	valorMoeda,
} from "../../services/Servicos";
import ModalFiltros from "../modal/ModalFiltros";

export default function DashboardAbastecimentos() {
	const user = useSelector((state) => state.userSlice);
	const [loading, setLoading] = useState(true);
	const [dataParamMotoristas, setDataParamMotoristas] = useState({
		show: false,
		data_inicial: null,
		data_final: null,
		filtro: "Indicador dos Motoristas",
		title: "",
	});
	const [resultado, setResultado] = useState({ loading: true, dados: [] });

	const atualizarDataParametro = (data_inicial, data_final) => {
		setDataParamMotoristas({ show: false, data_inicial, data_final });
	};

	const getApiData = async (data_inicial, data_final) => {
		const [anoI, mesI, diaI] = data_inicial.split("-");
		const dataI = new Date(anoI, Number(mesI) - 1, diaI);
		const [anoF, mesF, diaF] = data_final.split("-");
		const dataF = new Date(anoF, Number(mesF) - 1, diaF);
		setDataParamMotoristas((last) => ({
			...last,
			title: `De ${dataI.toLocaleDateString()} Até ${dataF.toLocaleDateString()}`,
		}));

		let url = "dashboard/empresa/get-total-combustivel-maquinas";
		let stringfilters = `AND CONVERT(CREATED_AT, DATE) >= '${data_inicial}' AND CONVERT(CREATED_AT, DATE) <= '${data_final}'`;

		if (user.id_filial != "2" && user.tipo.toLowerCase() != "empresa") {
			stringfilters = `AND CONVERT(CREATED_AT, DATE) >= '${data_inicial}' AND CONVERT(CREATED_AT, DATE) <= '${data_final}' and ID_FILIAL = '${user.id_filial}'`;
		}

		getAllData(url, { stringfilters })
			.then((resp) => {
				setResultado({ loading: false, dados: resp.data });
				setDataMotoristas(resp.data);
				setArrayDataMotoristas(resp.data);
			})
			.catch((error) => {});
	};

	useEffect(() => {
		if (!user.filiaisList.data) return;
		const datas = getDateInit(new Date());
		getApiData(datas.data_inicial, datas.data_final);
	}, [user.filiaisList.data]);

	useEffect(() => {
		if (!dataParamMotoristas.data_final && !dataParamMotoristas.data_inicial)
			return;
		setResultado({ loading: true, dados: [] });
		setLoading(true);

		getApiData(
			dataParamMotoristas?.data_inicial,
			dataParamMotoristas?.data_final
		).then(() => {
			setDataParamMotoristas((last) => ({
				...last,
				show: false,
				data_inicial: null,
				data_final: null,
			}));
		});
	}, [dataParamMotoristas]);

	const [showFilters, setShowFilters] = useState({ show: false });
	const [dataMotoristas, setDataMotoristas] = useState(null);
	const [arrayDataMotoristas, setArrayDataMotoristas] = useState([]);
	const [dataMotoristasFiltered, setDataMotoristasFiltered] = useState([]);
	const [arrayFilters, setArrayFiltes] = useState({
		combustivel: [],
	});

	useEffect(() => {
		if (!dataMotoristasFiltered.length) return;
		setResultado({ loading: true, dados: [] });
		setTimeout(() => {
			setResultado({ loading: false, dados: dataMotoristasFiltered });
		}, 750);
	}, [dataMotoristasFiltered]);
	return (
		<Fragment>
			<Row>
				<Col sm="12">
					<div className="card-header d-flex pb-2 justify-content-end">
						<h3 className="">Dashboard</h3>
						<span
							className="filter-data"
							onClick={() =>
								setDataParamMotoristas((last) => ({ ...last, show: true }))
							}
						>
							Filtrar Data
							<span className="filho">{dataParamMotoristas.title}</span>
							<i
								className="bi bi-calendar-date"
								style={{ marginLeft: "5px" }}
							></i>
						</span>
						<span
							className="dashboard-filters"
							onClick={() => {
								setShowFilters((last) => ({
									...last,
									show: true,
									arrayData: arrayDataMotoristas,
									variable: "combustivel",
									filterOptions: ["id_filial"],
								}));
							}}
						>
							Filtros
							<i className="bi bi-gear" style={{ marginLeft: "5px" }}></i>
						</span>
					</div>
				</Col>

				<Col sm="12">
					<div className="card-header d-flex mt-4 pb-2">
						<h4 className="">Lançamentos Padrão</h4>
					</div>
				</Col>
				<Col sm="12">
					<Indicadores
						title="Total Abastecido por Máquina"
						unidadeMedida="L"
						colors="#0d6efdff"
						resultado={resultado}
						arrayData={arrayDataMotoristas}
						arrayDataFiltered={dataMotoristasFiltered}
					/>
				</Col>

				<Col sm="12">
					<div className="card-header d-flex mt-4 pb-2">
						<h4 className="">Lançamentos Adicionais</h4>
					</div>
				</Col>
				<Col sm="12">
					<Indicadores
						unidadeMedida="L"
						colors="#0d6efdff"
						resultado={resultado}
						lancamentoAdicional={true}
						arrayData={arrayDataMotoristas}
						arrayDataFiltered={dataMotoristasFiltered}
					/>
				</Col>

				<Col sm="12" md="8">
					<Grafico1
						title="Total Abastecido por Máquina"
						unidadeMedida="L"
						colors="#0d6efdff"
						resultado={resultado}
						arrayData={arrayDataMotoristas}
						arrayDataFiltered={dataMotoristasFiltered}
					/>
				</Col>
				<Col sm="12" md="4">
					<Grafico4
						title="Valor Abastecido por Máquina"
						unidadeMedida="R$"
						posisaoUnidadeMedida={"frente"}
						colors="#0d6efd6f"
						resultado={resultado}
						arrayData={arrayDataMotoristas}
						arrayDataFiltered={dataMotoristasFiltered}
					/>
				</Col>
				<Col sm="12" md="6" lg="6">
					<Grafico2
						title="Total Abastecido por Filial"
						unidadeMedida="L"
						colors="#04c704c7"
						resultado={resultado}
						arrayData={arrayDataMotoristas}
						arrayDataFiltered={dataMotoristasFiltered}
					/>
				</Col>
				<Col sm="12" md="6" lg="6">
					<Grafico3
						title="Valor Abastecido por Filial"
						unidadeMedida="R$"
						posisaoUnidadeMedida={"frente"}
						colors="#04c704c7"
						resultado={resultado}
						arrayData={arrayDataMotoristas}
						arrayDataFiltered={dataMotoristasFiltered}
					/>
				</Col>
			</Row>

			<Row></Row>

			{dataParamMotoristas.show ? (
				<ModalAtualizarDataEntradaVST
					title={"Perido do Gráfico dos Motoristas"}
					show={dataParamMotoristas.show}
					setShow={setDataParamMotoristas}
					{...dataParamMotoristas}
					setDataParam={setDataParamMotoristas}
					fn={atualizarDataParametro}
				/>
			) : (
				""
			)}
			{showFilters.show ? (
				<ModalFiltros
					{...showFilters}
					setShow={setShowFilters}
					setFilteredTable={setDataMotoristasFiltered}
					arrayFilters={arrayFilters}
					setArrayFiltes={setArrayFiltes}
				/>
			) : (
				""
			)}
		</Fragment>
	);
}

function Indicadores({ url, resultado, lancamentoAdicional = false }) {
	const [infos, setInfos] = useState({
		litros: 0,
		valor_total: 0,
		maquinas: 0,
		abastecimentos: 0,
		adicional: 0,
	});

	const [loading, setLoading] = useState(resultado.loading);

	useEffect(() => {
		setLoading(resultado.loading);
		if (!resultado.dados.length) {
			setInfos((last) => ({
				...last,
				maquinas: numerosEditadoBR(0),
				litros: numerosEditadoBR(0),
				valor_total: valorMoeda(0),
				abastecimentos: resultado.dados.length,
			}));
			return;
		}
		setInfos((last) => ({
			...last,
			maquinas: numerosEditadoBR(
				resultado.dados
					.filter((item) =>
						lancamentoAdicional
							? item.tipo_lancamento == "ADICIONAL DE COMBUSTIVEL"
							: item.tipo_lancamento != "ADICIONAL DE COMBUSTIVEL"
					)
					.filter(
						(i, n, s) => s.findIndex((o) => o.caminhao === i.caminhao) === n
					).length
			),
			litros: numerosEditadoBR(
				resultado.dados
					.filter((item) =>
						lancamentoAdicional
							? item.tipo_lancamento == "ADICIONAL DE COMBUSTIVEL"
							: item.tipo_lancamento != "ADICIONAL DE COMBUSTIVEL"
					)
					.reduce((p, c) => p + c.litros_abastecidos, 0)
			),
			valor_total: valorMoeda(
				resultado.dados
					.filter((item) =>
						lancamentoAdicional
							? item.tipo_lancamento == "ADICIONAL DE COMBUSTIVEL"
							: item.tipo_lancamento != "ADICIONAL DE COMBUSTIVEL"
					)
					.reduce((p, c) => p + c.litros_abastecidos * c.valor_combustivel, 0)
			),
			abastecimentos: resultado.dados.filter((item) =>
				lancamentoAdicional
					? item.tipo_lancamento == "ADICIONAL DE COMBUSTIVEL"
					: item.tipo_lancamento != "ADICIONAL DE COMBUSTIVEL"
			).length,
		}));
		setLoading(false);
	}, [resultado]);
	return (
		<Row>
			<Col sm="6" lg="3">
				<Card style={{ width: "auto" }} className="card-dashboard card-color-1">
					<h3>
						{loading ? <Spinner animation="border" size="sm" /> : infos.litros}
					</h3>
					<p>Litros</p>
					<i className="bi bi-fuel-pump bi-dashboard"></i>
				</Card>
			</Col>
			<Col sm="6" lg="3">
				<Card style={{ width: "auto" }} className="card-dashboard card-color-2">
					<h3>
						{loading ? (
							<Spinner animation="border" size="sm" />
						) : (
							infos.valor_total
						)}
					</h3>
					<p>Valor Total</p>
					<i className="bi bi-currency-dollar bi-dashboard"></i>
				</Card>
			</Col>

			<Col sm="6" lg="3">
				<Card style={{ width: "auto" }} className="card-dashboard card-color-3">
					<h3>
						{loading ? (
							<Spinner animation="border" size="sm" />
						) : (
							infos.maquinas
						)}
					</h3>
					<p>Máquinas</p>
					<i className="bi bi-truck bi-dashboard"></i>
				</Card>
			</Col>
			<Col sm="6" lg="3">
				<Card style={{ width: "auto" }} className="card-dashboard card-color-4">
					<h3>
						{loading ? (
							<Spinner animation="border" size="sm" />
						) : (
							infos.abastecimentos
						)}
					</h3>
					<p>Abastecimentos</p>
					<i className="bi bi-123 bi-dashboard"></i>
				</Card>
			</Col>
		</Row>
	);
}
function Grafico1({
	title,
	unidadeMedida = "",
	posisaoUnidadeMedida,
	colors = "#f60809",
	resultado,
}) {
	const user = useSelector((state) => state.userSlice);
	const [options, setOptions] = useState({
		options: {
			chart: {
				id: "chart-pedido-sem-logisica",
			},
			grid: {
				show: false,
			},
			plotOptions: {
				bar: {
					horizontal: false,
					dataLabels: {
						position: "top",
					},
				},
			},
			dataLabels: {
				enabled: true,
				formatter: function (value, index) {
					if (posisaoUnidadeMedida == "frente") {
						return `${unidadeMedida} ${numerosEditadoBR(value)}`;
					}
					return `${numerosEditadoBR(value)} ${unidadeMedida} `;
				},
				offsetY: -20,
				style: {
					fontSize: "10px",
					colors: ["#304758"],
				},
				background: {
					enabled: true,
					foreColor: "#fff",
					padding: 4,
					borderRadius: 2,
					borderWidth: 1,
					borderColor: "#fff",
					opacity: 0.9,
					dropShadow: {
						enabled: false,
						top: 1,
						left: 1,
						blur: 1,
						color: "#000",
						opacity: 0.45,
					},
				},
			},
			xaxis: {
				labels: {
					style: {
						fontSize: "12px",
					},
				},
				categories: [],
			},
			yaxis: {
				show: false,
				labels: {
					style: {
						fontSize: "8px",
					},
					formatter: (value, index) => {
						if (typeof value === "number") {
							if (posisaoUnidadeMedida == "frente") {
								return `${unidadeMedida} ${numerosEditadoBR(value)}`;
							}
							return `${numerosEditadoBR(value)} ${unidadeMedida} `;
						}
						return value;
					},
				},
			},
		},
		series: [],
	});
	const [loading, setLoading] = useState(resultado.loading);

	useEffect(() => {
		setLoading(resultado.loading);
		if (!resultado?.dados.length) {
			setOptions((last) => ({
				...last,
				series: [],
			}));
			return;
		}
		// const resultado = resp.data

		const caminhoes = resultado.dados.filter(
			(i, n, s) => s.findIndex((o) => o.caminhao === i.caminhao) === n
		);

		const resultatoGeral = caminhoes
			.map((item) => {
				return {
					eixoX: item.caminhao, //.length > 10 ? item.caminhao.slice(0, 10) + '...' : item.caminhao,
					eixoY: resultado.dados
						.filter((item2) => item2.caminhao == item.caminhao)
						.reduce((p, c) => p + c.litros_abastecidos, 0),
					eixoY_add: resultado.dados
						.filter(
							(item) => item.tipo_lancamento == "ADICIONAL DE COMBUSTIVEL"
						)
						.filter((item2) => item2.caminhao == item.caminhao)
						.reduce((p, c) => p + c.litros_abastecidos, 0),
				};
			})
			.sort((a, b) => {
				if (a.eixoY > b.eixoY) return 1;
				if (a.eixoY < b.eixoY) return -1;
				return 0;
			})
			.filter((item) => item.eixoY);

		setOptions((last) => ({
			...last,
			options: {
				...last.options,
				colors: [colors, "#04c70453"],
				xaxis: {
					...last.options.xaxis,
					show: false,
					categories: resultatoGeral.map((item) =>
						item.eixoX.length > 10
							? item.eixoX.slice(0, 10) + "..."
							: item.eixoX
					),
				},
			},
			series: [
				{
					name: "Total de Lançamentos",
					data: resultatoGeral.map((item) => Number(item.eixoY).toFixed(3)),
				},
				{
					name: "Adicional",
					data: resultatoGeral.map((item) => Number(item.eixoY_add).toFixed(3)),
				},
			],
		}));
		setLoading(false);
	}, [resultado]);
	return (
		<Card className="p-3">
			<Card.Title className="card-header d-flex align-items-center pb-2">
				<span style={{ marginRight: "10px", font: "inherit" }}>{title}</span>
				{loading ? <Spinner animation="border" size="sm" /> : ""}
				{/* <span className="filter-data" onClick={() => setDataParam(last => ({...last, show: true}))}>
                    Filtrar Data 
                <span className="filho"style={{backgroundColor:colors}}>{periodoInicial}</span>
                    <i className="bi bi-calendar-date" style={{marginLeft: '5px'}}></i>
                </span> */}
			</Card.Title>
			<Card.Body className="p-0">
				{loading ? (
					""
				) : !options.series.find((item) => item.data.length) ? (
					"Sem dados para o periodo informado"
				) : (
					<div id={`chart-pedido-sem-logistica`}>
						<Chart
							options={options.options}
							series={options.series}
							type="bar"
							height={400}
						/>
					</div>
				)}
			</Card.Body>
		</Card>
	);
}

function Grafico2({
	title,
	unidadeMedida = "",
	posisaoUnidadeMedida,
	colors = "#f60809",
	resultado,
}) {
	const user = useSelector((state) => state.userSlice);
	const [options, setOptions] = useState({
		options: {
			chart: {
				id: "chart-pedido-sem-logisica",
			},
			grid: {
				show: false,
			},
			plotOptions: {
				bar: {
					horizontal: true,
					// columnWidth: '70%',
					barHeight: "35%",
					dataLabels: {
						position: "top",
					},
				},
			},
			dataLabels: {
				enabled: true,
				formatter: function (value, index) {
					if (posisaoUnidadeMedida == "frente") {
						return `${unidadeMedida} ${numerosEditadoBR(value)}`;
					}
					return `${numerosEditadoBR(value)} ${unidadeMedida} `;
				},
				offsetX: 20,
				style: {
					fontSize: "12px",
					colors: ["#000000"],
				},

				background: {
					enabled: true,
					foreColor: "#fff",
					padding: 4,
					borderRadius: 2,
					borderWidth: 1,
					borderColor: "#fff",
					opacity: 0.9,
					dropShadow: {
						enabled: false,
						top: 1,
						left: 1,
						blur: 1,
						color: "#000",
						opacity: 0.45,
					},
				},
			},
			xaxis: {
				labels: {
					show: false,
					style: {
						fontSize: "12px",
					},
				},
				categories: [],
			},
			yaxis: {
				show: true,
				labels: {
					style: {
						fontSize: "8px",
					},
					formatter: (value, index) => {
						if (typeof value === "number") {
							if (posisaoUnidadeMedida == "frente") {
								return `${unidadeMedida} ${numerosEditadoBR(value)}`;
							}
							return `${numerosEditadoBR(value)} ${unidadeMedida} `;
						}
						return value;
					},
				},
			},
		},
		series: [],
	});

	const [loading, setLoading] = useState(resultado.loading);

	useEffect(() => {
		setLoading(resultado.loading);
		if (!resultado?.dados.length) {
			setOptions((last) => ({
				...last,
				series: [],
			}));
			return;
		}
		// const resultado = resp.data

		const resultado_ = resultado.dados;

		const filiais = [...user.filiaisList.data];

		const resultatoGeral = filiais
			.map((item) => {
				const identificacao = item.identificacaoInterna.replace(
					"FERMAT - ",
					""
				);
				return {
					eixoX:
						identificacao.length > 10
							? identificacao.slice(0, 10) + "..."
							: identificacao,
					eixoY: resultado_
						.filter((item2) => item2.id_filial == item.idFilial)
						.reduce((p, c) => p + c.litros_abastecidos, 0),
					eixoY_add: resultado_
						.filter(
							(item) => item.tipo_lancamento == "ADICIONAL DE COMBUSTIVEL"
						)
						.filter((item2) => item2.id_filial == item.idFilial)
						.reduce((p, c) => p + c.litros_abastecidos, 0),
				};
			})
			.sort((a, b) => {
				if (a.eixoY > b.eixoY) return 1;
				if (a.eixoY < b.eixoY) return -1;
				return 0;
			})
			.filter((item) => item.eixoY)
			.reverse();

		setOptions((last) => ({
			...last,
			options: {
				...last.options,
				colors: [colors, "#5D9CFF7e"],
				xaxis: {
					...last.options.xaxis,
					show: false,
					categories: resultatoGeral.map((item) => item.eixoX),
				},
			},
			series: [
				{
					name: "Total Lançamentos",
					data: resultatoGeral.map((item) => Number(item.eixoY).toFixed(3)),
				},
				{
					name: "Adicional",
					data: resultatoGeral.map((item) => Number(item.eixoY_add).toFixed(3)),
				},
			],
		}));

		setLoading(false);
	}, [resultado]);
	return (
		<Card className="p-3">
			<Card.Title className="card-header d-flex align-items-center pb-2">
				<span style={{ marginRight: "10px", font: "inherit" }}>{title}</span>
				{loading ? <Spinner animation="border" size="sm" /> : ""}
				{/* <span className="filter-data" onClick={() => setDataParam(last => ({...last, show: true}))}>
                    Filtrar Data 
                <span className="filho"style={{backgroundColor:colors}}>{periodoInicial}</span>
                    <i className="bi bi-calendar-date" style={{marginLeft: '5px'}}></i>
                </span> */}
			</Card.Title>
			<Card.Body className="p-0">
				{loading ? (
					""
				) : !options.series.find((item) => item.data.length) ? (
					"Sem dados para o periodo informado"
				) : (
					<div id={`chart-pedido-sem-logistica`}>
						<Chart
							options={options.options}
							series={options.series}
							type="bar"
							height={400}
						/>
					</div>
				)}
			</Card.Body>
		</Card>
	);
}

function Grafico3({
	title,
	unidadeMedida = "",
	posisaoUnidadeMedida,
	colors = "#f60809",
	resultado,
}) {
	const user = useSelector((state) => state.userSlice);
	const [options, setOptions] = useState({
		options: {
			chart: {
				id: "chart-pedido-sem-logisica",
			},
			grid: {
				show: false,
			},
			plotOptions: {
				bar: {
					horizontal: true,
					// columnWidth: '70%',
					barHeight: "35%",
					dataLabels: {
						position: "top",
					},
				},
			},
			dataLabels: {
				enabled: true,
				formatter: function (value, index) {
					if (posisaoUnidadeMedida == "frente") {
						return `${unidadeMedida} ${numerosEditadoBR(value)}`;
					}
					return `${numerosEditadoBR(value)} ${unidadeMedida} `;
				},
				offsetX: 50,
				style: {
					fontSize: "12px",
					colors: ["#000000"],
				},

				background: {
					enabled: true,
					foreColor: "#fff",
					padding: 4,
					borderRadius: 2,
					borderWidth: 1,
					borderColor: "#fff",
					opacity: 0.9,
					dropShadow: {
						enabled: false,
						top: 1,
						left: 1,
						blur: 1,
						color: "#000",
						opacity: 0.45,
					},
				},
			},
			xaxis: {
				labels: {
					show: false,
					style: {
						fontSize: "12px",
					},
				},
				categories: [],
			},
			yaxis: {
				show: true,
				labels: {
					style: {
						fontSize: "8px",
					},
					formatter: (value, index) => {
						if (typeof value === "number") {
							if (posisaoUnidadeMedida == "frente") {
								return `${unidadeMedida} ${numerosEditadoBR(value)}`;
							}
							return `${numerosEditadoBR(value)} ${unidadeMedida} `;
						}
						return value;
					},
				},
			},
		},
		series: [],
	});

	const [loading, setLoading] = useState(resultado.loading);

	useEffect(() => {
		setLoading(resultado.loading);
		if (!resultado?.dados.length) {
			setOptions((last) => ({
				...last,
				series: [],
			}));
			return;
		}
		// const resultado = resp.data

		const resultado_ = resultado.dados;

		const filiais = [...user.filiaisList.data];

		const resultatoGeral = filiais
			.map((item) => {
				const identificacao = item.identificacaoInterna.replace(
					"FERMAT - ",
					""
				);
				return {
					eixoX:
						identificacao.length > 10
							? identificacao.slice(0, 10) + "..."
							: identificacao,
					eixoY: resultado_
						.filter((item2) => item2.id_filial == item.idFilial)
						.reduce(
							(p, c) => p + c.litros_abastecidos * c.valor_combustivel,
							0
						),
					eixoY_add: resultado_
						.filter(
							(item) => item.tipo_lancamento == "ADICIONAL DE COMBUSTIVEL"
						)
						.filter((item2) => item2.id_filial == item.idFilial)
						.reduce(
							(p, c) => p + c.litros_abastecidos * c.valor_combustivel,
							0
						),
				};
			})
			.sort((a, b) => {
				if (a.eixoY > b.eixoY) return 1;
				if (a.eixoY < b.eixoY) return -1;
				return 0;
			})
			.filter((item) => item.eixoY)
			.reverse();

		setOptions((last) => ({
			...last,
			options: {
				...last.options,
				colors: [colors, "#5D9CFF7e"],
				xaxis: {
					...last.options.xaxis,
					show: false,
					categories: resultatoGeral.map((item) => item.eixoX),
				},
			},
			series: [
				{
					name: "Total Lançamentos",
					data: resultatoGeral.map((item) => Number(item.eixoY).toFixed(3)),
				},
				{
					name: "Adicional",
					data: resultatoGeral.map((item) => Number(item.eixoY_add).toFixed(3)),
				},
			],
		}));

		setLoading(false);
	}, [resultado]);
	return (
		<Card className="p-3">
			<Card.Title className="card-header d-flex align-items-center pb-2">
				<span style={{ marginRight: "10px", font: "inherit" }}>{title}</span>
				{loading ? <Spinner animation="border" size="sm" /> : ""}
				{/* <span className="filter-data" onClick={() => setDataParam(last => ({...last, show: true}))}>
                    Filtrar Data 
                <span className="filho"style={{backgroundColor:colors}}>{periodoInicial}</span>
                    <i className="bi bi-calendar-date" style={{marginLeft: '5px'}}></i>
                </span> */}
			</Card.Title>
			<Card.Body className="p-0">
				{loading ? (
					""
				) : !options.series.find((item) => item.data.length) ? (
					"Sem dados para o periodo informado"
				) : (
					<div id={`chart-pedido-sem-logistica`}>
						<Chart
							options={options.options}
							series={options.series}
							type="bar"
							height={400}
						/>
					</div>
				)}
			</Card.Body>
		</Card>
	);
}

function Grafico4({
	title,
	unidadeMedida = "",
	posisaoUnidadeMedida,
	colors = "#f60809",
	resultado,
}) {
	const user = useSelector((state) => state.userSlice);
	const [options, setOptions] = useState({
		options: {
			chart: {
				id: "chart-pedido-sem-logisica",
			},
			grid: {
				show: false,
			},
			plotOptions: {
				bar: {
					horizontal: true,
					// columnWidth: '70%',
					barHeight: "35%",
					dataLabels: {
						position: "top",
					},
				},
			},
			dataLabels: {
				enabled: true,
				formatter: function (value, index) {
					if (posisaoUnidadeMedida == "frente") {
						return `${unidadeMedida} ${numerosEditadoBR(value.toFixed(2))}`;
					}
					return `${numerosEditadoBR(value.toFixed(2))} ${unidadeMedida} `;
				},
				offsetX: 50,
				style: {
					fontSize: "12px",
					colors: ["#000000"],
				},

				background: {
					enabled: true,
					foreColor: "#fff",
					padding: 4,
					borderRadius: 2,
					borderWidth: 1,
					borderColor: "#fff",
					opacity: 0.9,
					dropShadow: {
						enabled: false,
						top: 1,
						left: 1,
						blur: 1,
						color: "#000",
						opacity: 0.45,
					},
				},
			},
			xaxis: {
				labels: {
					show: false,
					style: {
						fontSize: "12px",
					},
				},
				categories: [],
			},
			yaxis: {
				show: true,
				labels: {
					style: {
						fontSize: "8px",
					},
					formatter: (value, index) => {
						if (typeof value === "number") {
							if (posisaoUnidadeMedida == "frente") {
								return `${unidadeMedida} ${numerosEditadoBR(value.toFixed(2))}`;
							}
							return `${numerosEditadoBR(value.toFixed(2))} ${unidadeMedida} `;
						}
						return value;
					},
				},
			},
		},
		series: [],
	});

	const [loading, setLoading] = useState(resultado.loading);

	useEffect(() => {
		setLoading(resultado.loading);
		if (!resultado?.dados.length) {
			setOptions((last) => ({
				...last,
				series: [],
			}));
			return;
		}
		const resultado_ = resultado.dados;

		const caminhoes = resultado_.filter(
			(i, n, s) => s.findIndex((o) => o.caminhao === i.caminhao) === n
		);

		const resultatoGeral = caminhoes
			.map((item) => {
				return {
					eixoX: item.caminhao, //.length > 10 ? item.caminhao.slice(0, 10) + '...' : item.caminhao,
					eixoY: resultado_
						.filter((item2) => item2.caminhao == item.caminhao)
						.reduce(
							(p, c) => p + c.litros_abastecidos * c.valor_combustivel,
							0
						),
					eixoY_add: resultado_
						.filter(
							(item) => item.tipo_lancamento == "ADICIONAL DE COMBUSTIVEL"
						)
						.filter((item2) => item2.caminhao == item.caminhao)
						.reduce(
							(p, c) => p + c.litros_abastecidos * c.valor_combustivel,
							0
						),
				};
			})
			.sort((a, b) => {
				if (a.eixoY > b.eixoY) return 1;
				if (a.eixoY < b.eixoY) return -1;
				return 0;
			})
			.filter((item) => item.eixoY)
			.reverse();

		setOptions((last) => ({
			...last,
			options: {
				...last.options,
				colors: [colors, "#04c704c7"],
				xaxis: {
					...last.options.xaxis,
					show: false,
					categories: resultatoGeral.map((item) =>
						item.eixoX.length > 10
							? item.eixoX.slice(0, 10) + "..."
							: item.eixoX
					),
				},
			},
			series: [
				{
					name: "Total Lançamentos",
					data: resultatoGeral.map((item) => Number(item.eixoY).toFixed(3)),
				},
			],
		}));

		setLoading(false);
	}, [resultado]);

	return (
		<Card className="p-3">
			<Card.Title className="card-header d-flex align-items-center pb-2">
				<span style={{ marginRight: "10px", font: "inherit" }}>{title}</span>
				{loading ? <Spinner animation="border" size="sm" /> : ""}
				{/* <span className="filter-data" onClick={() => setDataParam(last => ({...last, show: true}))}>
                    Filtrar Data 
                <span className="filho"style={{backgroundColor:colors}}>{periodoInicial}</span>
                    <i className="bi bi-calendar-date" style={{marginLeft: '5px'}}></i>
                </span> */}
			</Card.Title>
			<Card.Body className="p-0">
				{loading ? (
					""
				) : !options.series.find((item) => item.data.length) ? (
					"Sem dados para o periodo informado"
				) : (
					<div id={`chart-pedido-sem-logistica`}>
						<Chart
							options={options.options}
							series={options.series}
							type="bar"
							height={400}
						/>
					</div>
				)}
			</Card.Body>
		</Card>
	);
}
