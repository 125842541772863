import { createRef, Fragment, useEffect, useRef, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { update, updateFuncionarios } from "../../redux/slicers/user";
import { clearPersistData } from "../../redux/storeConfig/store";
import {
	alterFuncionarios_Caminhoes,
	apenasNumeros,
	getCaminhoes,
	getFuncionarios,
	pis_mask,
	updateTableNew,
} from "../../services/Servicos";
import Loading from "../loading/Loading";

import "./Modal.scss";
import { telefoneMask } from "../../services/helpers";

const nomeD = createRef(null);
const emailD = createRef(null);
const telefoneD = createRef(null);
const cnhD = createRef(null);
const dataNascimentoD = createRef(null);
const dataValidCnhD = createRef(null);
const userStatusD = createRef(null);
const tipoD = createRef(null);
const senhaD = createRef(null);
const localSeparacaoD = createRef(null);
const propriedadeD = createRef(null);
const DescLocalSeparacaoD = createRef(null);

export default function ModalAtualizarUsuarios({
	msg,
	title,
	show,
	setShow,
	msgButton,
}) {
	const dispatch = useDispatch();
	const filiaisList = useSelector((state) => state.userSlice.filiaisList.data);

	const [user, setUser] = useState({ status: msg.body.status });

	const [loading, setLoading] = useState({
		isLoading: false,
		msg: msgButton,
		style: "primary",
	});
	const [error, setError] = useState(false);

	const updateFuncionariosList = () => {
		getFuncionarios().then((response) => {
			dispatch(updateFuncionarios(response));
			handlerSimpleClose();
			// window.location.reload()
		});
	};

	const submitDataExclude = () => {
		setLoading({
			isLoading: true,
			msg: "Excluindo Usuário",
			style: "danger",
		});
		updateTableNew(`funcionarios?id=${msg.body.id}`, {
			data_cancelamento: new Date().toISOString(),
		})
			.then((resp) => {
				setLoading({
					isLoading: false,
					msg: "Excluido com Sucesso",
					style: "success",
				});

				setTimeout(() => {
					updateFuncionariosList();
				}, 1000);
			})
			.catch((error) => {
				if (error.msg === "Unauthorized") {
					// alert('Session has Expired')
					dispatch(update({ authenticated: false, token: "" }));
					window.location.replace("/login");
				}
			});
	};

	const submitDataEdit = async () => {
		//     if (title.includes('Usuário')) {
		setLoading({
			isLoading: true,
			msg: "Atualizando Usuário",
			style: "warning",
		});
		let userToUpdate = {};
		Object.keys(user).forEach((key) => {
			if (String(user[key]).length) {
				userToUpdate[key] = user[key];
			}
		});

		if (
			msg.body.email.toLowerCase() == "codji.suporte@gmail.com" &&
			msg?.body?.password
		) {
			delete user?.password;
			alert("Voce nao pode alterar a senha do Suporte");
			handlerSimpleClose();
			return;
		}

		try {
			await updateTableNew(`funcionarios?id=${msg.body.id}`, userToUpdate);
			setLoading({
				isLoading: false,
				msg: "Atualizado com Sucesso",
				style: "success",
			});

			setTimeout(() => {
				updateFuncionariosList();
			}, 500);
		} catch (error) {
			setError(true);
		}
		setLoading({
			isLoading: false,
			msg: msgButton,
			style: "primary",
		});
	};

	const onlyNumbersHandler = (e) => {
		// e.preventDefault()
		const buttonsAllowed = [
			"backspace",
			"alt",
			"arrowleft",
			"arrowright",
			"delete",
			"tab",
		].indexOf(e.key.toLowerCase());
		if (buttonsAllowed >= 0) {
		} else if (buttonsAllowed >= 0 || /[0-9]/g.test(e.key)) {
		} else {
			e.preventDefault();
			return false;
		}
	};

	const handlerSimpleClose = () =>
		setShow((last) => ({ error: { flag: false } }));

	const onChangeInputPadrao = (e) => {
		e.preventDefault();
		if (e.target.name == "pis") {
			setUser((last) => ({
				...last,
				pis: pis_mask(e.target.value),
			}));
			return;
		}
		if (e.target.name == "telefone") {
			setUser((last) => ({
				...last,
				telefone: telefoneMask(e.target.value),
			}));
			return;
		}
		if (e.target.name == "cnh") {
			setUser((last) => ({
				...last,
				cnh: apenasNumeros(e.target.value),
			}));
			return;
		}
		setUser((last) => ({
			...last,
			[e.target.name]: e.target.value,
		}));
	};

	useEffect(() => {
		let data_nascimento;
		let data_valid_cnh;
		if (msg.body.data_nascimento != "N/A") {
			const [dia, mes, ano] = String(msg.body.data_nascimento).split("/");
			data_nascimento = `${ano}-${mes}-${dia}`;
		}
		if (msg.body.data_valid_cnh != "N/A") {
			const [dia, mes, ano] = String(msg.body.data_valid_cnh).split("/");
			data_valid_cnh = `${ano}-${mes}-${dia}`;
		}

		setUser((last) => ({
			...last,
			data_nascimento,
			data_valid_cnh,
		}));
	}, []);

	return (
		<>
			<Modal
				show={show}
				onHide={handlerSimpleClose}
				backdrop="static"
				keyboard={false}
				centered
				size="md"
			>
				<Modal.Header closeButton>
					<Modal.Title>
						{title}: {msg.msg.pk}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{title.includes("Excluir") ? <ExcluirDados msg={msg} /> : ""}
					{title.includes("Editar") ? (
						<Form>
							<Row className="mb-2">
								<Col sm="4">
									<Form.Label>Propriedade:</Form.Label>
								</Col>
								<Col className="mb-3">
									<Form.Select
										name="id_filial"
										onChange={onChangeInputPadrao}
										defaultValue={msg.body.id_filial}
									>
										<option value={0}></option>
										{filiaisList.map((item, index) => (
											<option key={index} value={item.idFilial}>
												{item.identificacaoInterna}
											</option>
										))}
									</Form.Select>
								</Col>
							</Row>
							<Row className="mb-2">
								<Col sm="4">
									<Form.Label>Nome Completo:</Form.Label>
								</Col>
								<Col>
									<Form.Control
										type="text"
										placeholder={msg.body.name}
										required
										name="name"
										onChange={onChangeInputPadrao}
									/>
								</Col>
							</Row>
							{msg.body.email.toLowerCase() == "codji.suporte@gmail.com" ? (
								""
							) : (
								<Row className="mb-2">
									<Col sm="4">
										<Form.Label>Email:</Form.Label>
									</Col>
									<Col>
										<Form.Control
											type="email"
											placeholder={msg.body.email}
											required
											name="email"
											onChange={onChangeInputPadrao}
										/>
									</Col>
								</Row>
							)}

							<Row className="mb-2">
								<Col sm="4">
									<Form.Label>Telefone:</Form.Label>
								</Col>
								<Col>
									<Form.Control
										type="text"
										placeholder={msg.body.telefone}
										maxLength={15}
										onKeyDown={onlyNumbersHandler}
										name="telefone"
										onChange={onChangeInputPadrao}
										value={user?.telefone}
									/>
								</Col>
							</Row>
							<Row className="mb-2">
								<Col sm="4">
									<Form.Label>CNH:</Form.Label>
								</Col>
								<Col>
									<Form.Control
										type="text"
										placeholder={msg.body.cnh}
										onKeyDown={onlyNumbersHandler}
										maxLength={11}
										name="cnh"
										onChange={onChangeInputPadrao}
										value={user?.cnh}
									/>
								</Col>
							</Row>
							<Row className="mb-2">
								<Col sm="4">
									<Form.Label>Data de Nascimento:</Form.Label>
								</Col>
								<Col>
									<Form.Control
										type="date"
										defaultValue={user?.data_nascimento}
										name="data_nascimento"
										onChange={onChangeInputPadrao}
									/>
								</Col>
							</Row>
							<Row className="mb-2">
								<Col sm="4">
									<Form.Label>Validade da CNH:</Form.Label>
								</Col>
								<Col>
									<Form.Control
										type="date"
										defaultValue={user?.data_valid_cnh}
										name="data_valid_cnh"
										onChange={onChangeInputPadrao}
									/>
								</Col>
							</Row>
							<Row className="mb-2">
								<Col sm="4">
									<Form.Label>PIS:</Form.Label>
								</Col>
								<Col>
									<Form.Control
										type="text"
										defaultValue={msg.body.pis}
										maxLength={14}
										name="pis"
										value={user?.pis}
										onChange={onChangeInputPadrao}
									/>
								</Col>
							</Row>
							<Row className="mb-2">
								<Col sm="4">
									<Form.Label>Status:</Form.Label>
								</Col>
								<Col>
									<Form.Check
										type="switch"
										defaultChecked={msg.body.status}
										onChange={() => {
											if (!user?.status)
												return setUser((last) => ({
													...last,
													status: !msg.body.status,
												}));
											setUser((last) => ({ ...last, status: !last.status }));
										}}
										label={user?.status ? "Usuário Ativo" : "Usuário Inativo"}
									/>
								</Col>
							</Row>
							<Row className="mb-2">
								<Col sm="4">
									<Form.Label>Tipo:</Form.Label>
								</Col>
								<Col>
									<Form.Select
										ref={tipoD}
										name="tipo"
										onChange={onChangeInputPadrao}
										// onChange={() => {
										// 	if (tipoD.current.value !== "Separador") {
										// 		setUser((last) => ({ ...last, local_separacao: "" }));
										// 		localSeparacaoD.current.value = "";
										// 	}
										// 	setUser((last) => ({
										// 		...last,
										// 		tipo: tipoD.current.value,
										// 	}));
										// }}
										required
										defaultValue={msg.body.tipo}
									>
										<option value=""></option>
										<option value="Caminhoneiro">Caminhoneiro</option>
										<option value="Carregador">Carregador</option>
										<option value="Gestor">Gestor</option>
										<option value="Separador">Separador</option>
									</Form.Select>
								</Col>
							</Row>
							<Row className="mb-2">
								{/* <Col sm='4'>
                                <Form.Label>Local da Separação:</Form.Label>
                            </Col>
                            <Col>
                                <Form.Select 
                                    ref={localSeparacaoD}
                                    onChange={onChangeInput}
                                    disabled={tipoD.current?.value === 'Separador'? false : true}
                                    defaultValue={msg.msg[10].innerText}
                                >
                                    <option value=''></option>
                                    <option value='CD'>Centro de Distribuição</option>
                                    <option value='INDUSTRIA'>Industria</option>
                                </Form.Select>
                            </Col> */}
								<Col md="4" className="mb-3">
									<Form.Label>Setor da Separação:</Form.Label>
								</Col>
								<Col>
									<Form.Select
										name="local_separacao"
										onChange={onChangeInputPadrao}
										defaultValue={msg.body.local_separacao}
										disabled={
											tipoD?.current?.value === "Separador" ? false : true
										}
									>
										<option value=""></option>
										<option value="SETOR_1">SETOR_1</option>
										<option value="SETOR_2">SETOR_2</option>
										<option value="SETOR_3">SETOR_3</option>
										<option value="SETOR_4">SETOR_4</option>
										<option value="SETOR_5">SETOR_5</option>
										<option value="SETOR_6">SETOR_6</option>
									</Form.Select>
								</Col>
							</Row>
							<Row>
								<Col md="4" className="mb-3">
									<Form.Label>Descricao do Setor de Separação:</Form.Label>
								</Col>
								<Col>
									<Form.Control
										name="desc_setor_sep"
										onChange={onChangeInputPadrao}
										defaultValue={msg.body.desc_setor_sep}
										disabled={
											tipoD?.current?.value === "Separador" ? false : true
										}
									/>
								</Col>
							</Row>

							{msg.body.email.toLowerCase() == "codji.suporte@gmail.com" ? (
								""
							) : (
								<Row className="mb-2">
									<Col sm="4">
										<Form.Label>Senha:</Form.Label>
									</Col>
									<Col>
										<Form.Control
											type="text"
											placeholder="xxxxxxx"
											name="password"
											onChange={onChangeInputPadrao}
										/>
									</Col>
								</Row>
							)}
							{error ? (
								<Form.Text className="text-muted error-form">
									Email, CPF ou CNH ja cadastrada em outro usuário.
								</Form.Text>
							) : (
								""
							)}
						</Form>
					) : (
						""
					)}
				</Modal.Body>
				<Modal.Footer>
					<Row style={{ width: "100%" }}>
						<Col>
							<Button
								variant={loading.style}
								onClick={
									title.includes("Editar") ? submitDataEdit : submitDataExclude
								}
							>
								{loading.isLoading ? (
									<Loading msg={loading.msg} />
								) : (
									loading.msg
								)}
							</Button>
						</Col>
						<Col>
							<Button variant="secondary" onClick={handlerSimpleClose}>
								Cancelar
							</Button>
						</Col>
					</Row>
				</Modal.Footer>
			</Modal>
		</>
	);
}

function ExcluirDados({ msg }) {
	return (
		<Fragment>
			<div>{msg.title}</div>
			<div>
				{Object.keys(msg.body).map((key, index) => (
					<div key={index}>
						{key}:{" "}
						<b>
							<i>{msg.body[key]}</i>
						</b>
					</div>
				))}
			</div>
		</Fragment>
	);
}
