import { useSelector } from "react-redux"
import NavbarPonto from "./controle-ponto/NavbarPonto"
import Dashboard from "./dashboard/Dashboard"
import NavbarFrota from "./frota/NavbarFrota"
import NavbarPlanoLogistica from "./planoLogistica/NavbarPlanoLogistica"
import { NavbarSeparacao } from "./separacao-carga/Separacao"
import Template from "./template/Template"
import NavbarUser from "./user/NavbarUser"
import Entregas from "./entregas/Entregas"
import Descarregamento from "./entregas/Descarregamento"
import Financeiro from "./financeiro/Financeiro"
import GestaoCarregamento from "./gestaoCarregamento/GestaoCarregamento"
import CarregarCaminhaoNew from "./carregamento/CarregarCaminhaoNew"
import Abastecimento from "./abastecimento/Abastecimento"
import NavbarDiario from "./diarioDeBordo/NavbarDiario"
import Monitoramento from "./frota/monitoramentoFrota/Monitoramento"
import NavbarEquipamentos from "./equipamentos/NavbarEquipamentos"
import NavbarTransferencias from "./transferencias/NavbarTransferencias"
import MonitoramentoPedidos from "./planoLogistica/monitoramentoPedidos/MonitoramentoPedidos"
import ControleDeMensagens from "./controleDeMensagens/ControleDeMensagens"
import MotoristasRoute from "./motoristas/MotoristasRoute"


export function PageDashboard({ user }) {
    const userTipo = useSelector(state => state.userSlice)
    return (
        <Template>
            <h1>Dashboard - <span>{userTipo.tipo.toUpperCase()}</span></h1>
            <hr></hr>
            <Dashboard />
        </Template>
    )
}
export function PageMonitPedidos({ user }) {
    const userTipo = useSelector(state => state.userSlice)
    const filiaisList = userTipo.filiaisList.data
    return (
        <Template>
            <h1>Monitoramento de Pedidos - <span>{filiaisList.find(item => item.idFilial == userTipo.id_filial)?.identificacaoInterna || userTipo.tipo.toUpperCase()}</span></h1>
            <hr></hr>
            <MonitoramentoPedidos />
        </Template>
    )
}
export function PageMotoristas({ user }) {
    
    return (
        <Template>
            <h1>Relatório de Entregas</h1>
            <hr></hr>
            <MotoristasRoute/>
        </Template>
    )
}

export function PageInbox() {
    return (
        <Template>
            <h1>INBOX ROUTE</h1>
            <hr></hr>
        </Template>
    )
}

export function PageChat() {
    return (
        <Template>
            <h1>CHAT ROUTE</h1>
            <hr></hr>
        </Template>
    )
}

export function PageMap() {
    return (
        <Template>
            <div className="header">
                 
            </div>
            <h1>Area de Entregas - Mapa</h1>
            <hr></hr>
            <Entregas />

        </Template>
    )
}
export function PageDescarregamento() {
    return (
        <Template>
            <div className="header">
                 
            </div>
            <h1>Area de Entregas - Descarregamento</h1>
            <hr></hr>
            <Descarregamento />

        </Template>
    )
}

export function PageDiario() {
    return (
        <Template>
            <h1>Diário de Bordo</h1>
            <hr></hr>
            <NavbarDiario />

        </Template>
    )
}
export function PageManutencao() {
    return (
        <Template>
            <h1>USER PROFILE ROUTE</h1>
            <hr></hr>

        </Template>
    )
}
export function PagePlanoLogistica() {
    return (
        <Template>
            <h1>Controle de Planos de Logística</h1>
            <hr></hr>
            <NavbarPlanoLogistica />
        </Template>
    )
}

export function PageFrota() {
    return (
        <Template>
            <h1>Controle de Frota</h1>
            <hr></hr>

            <NavbarFrota />
        </Template>
    )
}
export function PageGestaoCarregamento() {
    return (
        <Template>
            <>
                <h1>Planejamento de Produtos nos Caminhões</h1>
                <hr></hr>
                <GestaoCarregamento />
                {/* <Carregamento /> */}
            </>

        </Template>
    )
}
export function PageCarregamento() {
    return (
        <Template>
            <>
                <h1>Carregamento de Carga</h1>
                <hr></hr>
                <CarregarCaminhaoNew />
                {/* <Carregamento /> */}
            </>

        </Template>
    )
}

export function PageFinanceiro(children) {
    return (
        <Template>
            <h1>Financeiro</h1>
            <hr></hr>
            <Financeiro/>

        </Template>
    )
}

export function PageAbastecimento() {
    return (
        <Template>
            <h1>Abastecimento</h1>
            <hr></hr>
            <Abastecimento/>

        </Template>
    )
}

export function PageMonitoramentoFrota() {
    return (
        <Template>
            <h1>Motiramento da Frota em Rota de Entrega</h1>
            <hr></hr>
            <Monitoramento/>
        </Template>
    )
}
export function PageSeguranca() {
    return (
        <Template>
            <h1>Segurança ROUTE</h1>
            <hr></hr>

        </Template>
    )
}

export function PageControleDePonto() {
    return (
        <Template>
            <h1>Controle de Pontos</h1>
            <hr></hr>
            <NavbarPonto />
            {/* <ControlePonto/> */}
        </Template>
    )
}


export function PageSeparacaoCarga() {
    return (
        <Template>
            <h1>Separação</h1>
            <hr></hr>
            <NavbarSeparacao />
        </Template>
    )
}

export function UserRoute() {
    return (
        <Template>
            <h1>Usuário</h1>
            <hr></hr>
            <NavbarUser />
        </Template>
    )
}


export function PageEquipamentos() {
    return (
        <Template>
            <h1>Controle de Equipamentos</h1>
            <hr></hr>
            <NavbarEquipamentos />
        </Template>
    )
}

export function PageTransferencias() {
    return (
        <Template>
            <h1>Controle de Tranferencias</h1>
            <hr></hr>
            <NavbarTransferencias />
        </Template>
    )
}


export function PageMensagens() {
    return (
        <Template>
            <h1>Controle de Mensagens</h1>
            <hr></hr>
            <ControleDeMensagens/>
        </Template>
    )
}