import { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import {
	updateAbastecimentosList,
	updateCargasList,
} from "../../redux/slicers/user";
import {
	apenasNumeros,
	createAbastecimento,
	getAllData,
	getStatusCarga,
	locationDev,
	numeroDecimais,
	numerosDecimal,
	updateTable,
	verificaTipoUsuario,
} from "../../services/Servicos";
import Loading from "../loading/Loading";

import "./Modal.scss";
import ModalError from "./ModalError";

export default function ModalLancarAbastecimento(props) {
	const dispatch = useDispatch();
	const caminhoesList = useSelector(
		(state) => state.userSlice.caminhoesList.data
	).filter((item) => item.status);
	const cargas = useSelector((state) => state.userSlice.cargasList.data);
	const user = useSelector((state) => state.userSlice);
	const isSuporte = user.email.toLowerCase() == "codji.suporte@gmail.com";

	const [loading, setLoading] = useState({
		isLoading: false,
		msg: "Salvar",
		style: "primary",
	});

	const [body, setBody] = useState({
		abastecedor: user.name,
		tipo_lancamento: "LANÇAMENTO PADRÃO",
	});
	const [error, setError] = useState({
		flag: false,
	});

	const handlerSimpleClose = () =>
		props.setShow((last) => ({ ...last, flag: false }));

	const submitDataEdit = () => {
		if (!body.caminhao) {
			setError({
				flag: true,
				title: "Erro",
				msg: {
					erro: "Lançamento de Abastecimento",
					motivo: "Placa/Caminhao não especificado.",
				},
			});
			return;
		}
		if (!body?.tipo_combustivel) {
			setError({
				flag: true,
				title: "Erro",
				msg: {
					erro: "Lançamento de Abastecimento",
					motivo: "Tipo de Combustivel não especificado",
				},
			});
			return;
		}
		if (!body.litros_abastecidos) {
			setError({
				flag: true,
				title: "Erro",
				msg: {
					erro: "Lançamento de Abastecimento",
					motivo: "Litros não especificado",
				},
			});
			return;
		}
		if (!body.ultimo_km) {
			setError({
				flag: true,
				title: "Erro",
				msg: {
					erro: "Lançamento de Abastecimento",
					motivo: "Ultimo km não especificado",
				},
			});
			return;
		}
		if (!body.valor_combustivel) {
			setError({
				flag: true,
				title: "Erro",
				msg: {
					erro: "Lançamento de Abastecimento",
					motivo: "Valor do combustivel não especificado",
				},
			});
			return;
		}
		if (!body.horimetro) {
			setError({
				flag: true,
				title: "Erro",
				msg: {
					erro: "Lançamento de Abastecimento",
					motivo: "Foto do horimetro não informada",
				},
			});
			return;
		}

		setLoading({
			isLoading: true,
			msg: "Salvando",
			style: "warning",
		});

		let bodySend = {
			data_abastecimento: new Date().toISOString(),
			emissao: new Date().toISOString(),
		};

		Object.keys(body).map((item) => {
			if (item == "horimetro") {
				bodySend[item] = body[item];
				return;
			}

			if (item == "cargas_id" && body.chegada_saida == "chegada") {
				bodySend.cargas_id_chegada = body.cargas_id;
				return;
			}

			bodySend[item] = body[item]
				.toUpperCase()
				.replace(".", "")
				.replace(",", ".");
		});

		createAbastecimento(bodySend, user?.tipo)
			.then((response) => {
				setLoading({
					isLoading: false,
					msg: "Enviado",
					style: "success",
				});
				setTimeout(() => {
					updateAbastecimentosArray();
				}, 700);
			})
			.catch((error) => {
				setLoading({
					isLoading: false,
					msg: "Error",
					style: "danger",
				});

				setError({
					flag: true,
					title: "Atenção",
					msg: error,
				});

				setTimeout(() => {
					setLoading({
						isLoading: false,
						msg: "Enviar",
						style: "primary",
					});
				}, 1000);
			});
	};

	const onChangeInput = (e) => {
		if (
			e.target.name === "chegada_saida" &&
			e.target.value === "chegada" &&
			props.editar
		) {
			setBody((last) => ({
				abastecedor: last.abastecedor,
				cargas_id_chegada: last.cargas_id_chegada,
				[e.target.name]: e.target.value,
			}));
			return;
		}
		if (
			e.target.name === "chegada_saida" &&
			e.target.value === "saida" &&
			props.editar
		) {
			setBody((last) => ({
				abastecedor: last.abastecedor,
				cargas_id: last.cargas_id,
				[e.target.name]: e.target.value,
			}));
			return;
		}
		if (e.target.name === "caminhao") {
			setBody((last) => ({
				...last,
				[e.target.name]: e.target.value,
				id_filial:
					"" +
					caminhoesList.find((item) => item.placa == e.target.value)?.id_filial,
			}));
			return;
		}
		setBody((last) => ({
			...last,
			[e.target.name]: e.target.value,
		}));
	};

	const editValue = (e) => {
		setBody((last) => ({
			...last,
			[e.target.name]: numeroDecimais(apenasNumeros(e.target.value)),
		}));
	};

	const carregarCargas = async () => {
		const cargas = await getAllData(verificaTipoUsuario(user) + "cargas");
		dispatch(updateCargasList(cargas.data));
	};

	useEffect(() => {
		//atualizar lista de cargas
		carregarCargas();
	}, []);
	// useEffect(() => {
	//     //atualizar lista de cargas
	//
	// }, [body])

	const onChangeInputFile = async (e) => {
		e.preventDefault();

		const imageFile = e.target.files[0];

		setBody((last) => ({
			...last,
			horimetro: imageFile,
		}));
	};

	const updateAbastecimentosArray = async () => {
		await props.setAtualizar();
		handlerSimpleClose();
	};

	const submitDataUpdate = () => {
		if (!body.cargas_id && !body.cargas_id_chegada && !isSuporte)
			return setError({
				flag: true,
				msg: {
					title: "Erro na atualização do Abastecimento",
					motivo: "Numero da Carga não informado",
				},
				title: "Aviso",
			});
		if (!body.chegada_saida && !isSuporte)
			return setError({
				flag: true,
				msg: {
					title: "Erro na atualização do Abastecimento",
					motivo: "Referência da Atualização não informado",
				},
				title: "Aviso",
			});
		setLoading({
			isLoading: true,
			msg: "Enviando",
			style: "warning",
		});
		let bodyS = { ...body };
		if (bodyS.cargas_id == "-1" || bodyS.cargas_id_chegada == "-1") {
			bodyS = {
				...bodyS,
				cancelar: true,
				cancelar_carga: {
					id:
						bodyS.chegada_saida == "chegada"
							? props.body.cargas_id_chegada
							: props.body.cargas_id,
				},
			};
		}

		updateTable(verificaTipoUsuario(user) + "abastecimentos", props.body.id, {
			...bodyS,
		})
			.then((res) => {
				setLoading({
					isLoading: false,
					msg: "Enviado",
					style: "success",
				});
				updateAbastecimentosArray();
			})
			.catch((err) => {
				setError({
					flag: true,
					msg: { title: "Erro na atualização do Abastecimento", ...err },
					title: "Aviso",
				});
			});
	};

	return (
		<>
			<Modal
				show={props.show}
				onHide={handlerSimpleClose}
				backdrop="static"
				keyboard={false}
				centered
				size="md"
			>
				<Modal.Header closeButton>
					<Modal.Title>{props.title}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{props.editar ? (
						// para a edição do abastecimento daqueles abastecimentos sem carga que precisam de alteração no futuro
						<Form>
							<Row style={{ fontSize: "1.2rem", padding: "10px" }}>
								<div
									className="card-header pb-2"
									style={{ fontSize: "1.2rem" }}
								>
									Horimetro
								</div>
								<Card.Img
									variant="top"
									src={`${locationDev}/files/users/abastecimentos/${props.body.caminhao}/${props.body.horimetro}`}
									style={{
										cursor: "pointer",
										border: "0.1px solid #00b8ff",
										boxShadow: "0 0 10px #00b8ff73",
										marginBottom: "30px",
									}}
									// onClick={e => setAdminFunction({flag: true, imgSrc: `${locationDev}/files/users/abastecimentos/${bodyList.caminhao}/${bodyList[item]}`})}
								/>
							</Row>
							<Row className="mb-2">
								<Col sm="4">
									<Form.Label>Tipo de Máquina a:</Form.Label>
								</Col>
								<Col>
									<Form.Select
										type="text"
										name="tipo_maquina"
										onChange={onChangeInput}
										defaultValue={props.body?.tipo_maquina.toLowerCase()}
										disabled
									>
										<option value=""></option>
										<option value="caminhao">Caminhão</option>
										<option value="outro">Outro</option>
									</Form.Select>
								</Col>
							</Row>
							{body?.tipo_maquina !== "outro" ? (
								""
							) : (
								<Row className="mb-2">
									<Col sm="4">
										<Form.Label>Placa da Máquina:</Form.Label>
									</Col>
									<Col>
										<Form.Control
											type="text"
											name="caminhao"
											onChange={onChangeInput}
											placeholder="Placa, Descricao"
										/>
									</Col>
								</Row>
							)}
							<Row className="mb-2">
								<Col sm="4">
									<Form.Label>Caminhão:</Form.Label>
								</Col>
								<Col>
									<Form.Select
										type="text"
										name="caminhao"
										disabled={true}
										defaultValue={props.body.caminhao}
									>
										<option value=""></option>
										{caminhoesList.map((i, k) => (
											<option value={i.placa} key={k}>
												{i?.tipo} - {i.placa}
											</option>
										))}
									</Form.Select>
								</Col>
							</Row>
							<Row className="mb-2">
								<Col sm="4">
									<Form.Label>Tipo de Combustível:</Form.Label>
								</Col>
								<Col>
									<Form.Control
										type="text"
										name="tipo_combustivel"
										defaultValue={props.body?.tipo_combustivel?.toUpperCase()}
										disabled
									/>
								</Col>
							</Row>
							<Row className="mb-2">
								<Col sm="4">
									<Form.Label>Referência:</Form.Label>
								</Col>
								<Col>
									<Form.Select
										type="text"
										name="chegada_saida"
										onChange={onChangeInput}
										required
									>
										<option value=""></option>
										{!props.body.cargas_id_chegada ? (
											<option value="chegada">Chegada do Caminhão</option>
										) : (
											""
										)}
										{!props.body.cargas_id ? (
											<option value="saida">Saida do Caminhão</option>
										) : (
											""
										)}
										{user.email == "CODJI.SUPORTE@GMAIL.COM" ? (
											<>
												<option value=""></option>
												<option value="">SUPORTE</option>
												<option value="saida">Saida do Caminhão</option>
												<option value="chegada">Chegada do Caminhão</option>
											</>
										) : (
											""
										)}
									</Form.Select>
								</Col>
							</Row>
							<Row className="mb-2">
								<Col sm="4">
									<Form.Label>Carga:</Form.Label>
								</Col>
								<Col>
									<Form.Select
										type="text"
										name={
											body.chegada_saida == "chegada"
												? "cargas_id_chegada"
												: "cargas_id"
										}
										onChange={onChangeInput}
										disabled={body.chegada_saida ? false : true}
									>
										{console.log(
											body,
											props.body,
											cargas
												.filter((item) => item.status <= 13)
												.filter(
													(item) =>
														item.caminhao == body.caminhao ||
														item.caminhao == props.body.caminhao
												)
										)}
										<option value=""></option>
										{props.body.caminhao &&
										caminhoesList
											.find((item) => item.placa == props.body.caminhao)
											?.tipo.includes("Cavalo")
											? cargas
													.filter((item) => item.status <= 13)
													.map((item, key) => {
														const trator = caminhoesList.find(
															(item_) =>
																item_.trator == props.body.caminhao &&
																item_.placa == item.caminhao
														);
														if (trator) {
															return (
																<option value={item.id} key={key}>
																	N°{item.id} - {item.nome} -{" "}
																	{getStatusCarga(item.status)}
																</option>
															);
														}
													})
											: cargas
													.filter((item) => item.status <= 13)
													.filter(
														(item) => item.caminhao == props.body.caminhao
													)
													.map((item, key) => (
														<option value={item.id} key={key}>
															N°{item.id} - {item.nome} -{" "}
															{getStatusCarga(item.status)}
														</option>
													))}
										{user.email == "CODJI.SUPORTE@GMAIL.COM" ? (
											<>
												<option value="-1">Resetar Lançamento</option>
											</>
										) : (
											""
										)}
									</Form.Select>
								</Col>
							</Row>
							<Row className="mb-2">
								<Col sm="4">
									<Form.Label>Litros:</Form.Label>
								</Col>
								<Col>
									<Form.Control
										type="text"
										value={Number(
											props.body?.litros_abastecidos
										).toLocaleString("pt-BR")}
										name="litros_abastecidos"
										disabled
									/>
								</Col>
							</Row>
							<Row className="mb-2">
								<Col sm="4">
									<Form.Label>Último Km:</Form.Label>
								</Col>
								<Col>
									<Form.Control
										type="text"
										defaultValue={Number(props.body.ultimo_km).toLocaleString(
											"pt-BR"
										)}
										name="litros_abastecidos"
										disabled
									/>
								</Col>
							</Row>
							<Row className="mb-2">
								<Col sm="4">
									<Form.Label>Valor Combustivel (L):</Form.Label>
								</Col>
								<Col>
									<Form.Control
										type="text"
										defaultValue={Number(
											props.body.valor_combustivel
										).toLocaleString("pt-BR")}
										name="litros_abastecidos"
										disabled
									/>
								</Col>
							</Row>
							<Row className="mb-2">
								<Col sm="4">
									<Form.Label>Abastecedor:</Form.Label>
								</Col>
								<Col>
									<Form.Control
										type="text"
										disabled
										value={props.body?.abastecedor}
										name="valor_combustivel"
									/>
								</Col>
							</Row>
						</Form>
					) : (
						// para o lancamento de abastecimento
						<Form>
							<Row className="mb-2">
								<Col sm="4">
									<Form.Label>Tipo de Lançamento:</Form.Label>
								</Col>
								<Col>
									<Form.Select
										type="text"
										name="tipo_lancamento"
										onChange={onChangeInput}
									>
										<option value="LANÇAMENTO PADRÃO">Lançamento Padrão</option>
										<option value="ADICIONAL DE COMBUSTIVEL">
											Adicional de Combustível
										</option>
									</Form.Select>
								</Col>
							</Row>
							<Row className="mb-2">
								<Col sm="4">
									<Form.Label>Tipo de Máquina:</Form.Label>
								</Col>
								<Col>
									<Form.Select
										type="text"
										name="tipo_maquina"
										onChange={onChangeInput}
									>
										<option value=""></option>
										<option value="caminhao">Caminhão</option>
										<option value="outro">Outro</option>
									</Form.Select>
								</Col>
							</Row>
							{body?.tipo_maquina !== "outro" ? (
								""
							) : (
								<Row className="mb-2">
									<Col sm="4">
										<Form.Label>Placa da Máquina:</Form.Label>
									</Col>
									<Col>
										<Form.Control
											type="text"
											name="caminhao"
											onChange={onChangeInput}
											placeholder="Placa, Descricao"
										/>
									</Col>
								</Row>
							)}
							<Row className="mb-2">
								<Col sm="4">
									<Form.Label>Caminhão:</Form.Label>
								</Col>
								<Col>
									<Form.Select
										type="text"
										name="caminhao"
										onChange={onChangeInput}
										disabled={body?.tipo_maquina === "caminhao" ? false : true}
									>
										<option value=""></option>
										{caminhoesList
											.filter((item) => item?.tipo !== "Carreta")
											.map((i, k) => (
												<option value={i.placa} key={k}>
													{i?.tipo} - {i.placa}
												</option>
											))}
									</Form.Select>
								</Col>
							</Row>
							<Row className="mb-2">
								<Col sm="4">
									<Form.Label>Tipo de Combustível:</Form.Label>
								</Col>
								<Col>
									<Form.Select
										type="text"
										name="tipo_combustivel"
										onChange={onChangeInput}
									>
										<option value=""></option>
										<option value="diesel">Diesel</option>
										<option value="gasolina">Gasolina</option>
										<option value="outros">Outros</option>
									</Form.Select>
								</Col>
							</Row>

							<Row className="mb-2">
								<Col sm="4">
									<Form.Label>Referência:</Form.Label>
								</Col>
								<Col>
									<Form.Select
										type="text"
										name="chegada_saida"
										onChange={onChangeInput}
										disabled={
											body?.tipo_maquina == "caminhao" &&
											body?.tipo_lancamento == "LANÇAMENTO PADRÃO"
												? false
												: true
										}
									>
										<option value=""></option>
										<option value="chegada">Chegada do Caminhão</option>
										<option value="saida">Saida do Caminhão</option>
									</Form.Select>
								</Col>
							</Row>
							<Row className="mb-2">
								<Col sm="4">
									<Form.Label>Carga:</Form.Label>
								</Col>
								<Col>
									<Form.Select
										type="text"
										name={"cargas_id"}
										onChange={onChangeInput}
										disabled={
											body.chegada_saida ||
											body?.tipo_lancamento != "LANÇAMENTO PADRÃO"
												? false
												: true
										}
									>
										<option value=""></option>
										{body.caminhao &&
										caminhoesList
											.find((item) => item.placa == body.caminhao)
											?.tipo.includes("Cavalo")
											? cargas
													.filter((item) => item.status <= 13)
													.map((item, key) => {
														const trator = caminhoesList.find(
															(item_) =>
																item_.trator == body.caminhao &&
																item_.placa == item.caminhao
														);
														if (trator) {
															return (
																<option value={item.id} key={key}>
																	N°{item.id} - {item.nome} -{" "}
																	{getStatusCarga(item.status)}
																</option>
															);
														}
													})
											: cargas
													.filter((item) => item.status <= 13)
													.filter((item) => item.caminhao == body.caminhao)
													.map((item, key) => (
														<option value={item.id} key={key}>
															N°{item.id} - {item.nome} -{" "}
															{getStatusCarga(item.status)}
														</option>
													))}
									</Form.Select>
								</Col>
							</Row>

							<Row className="mb-2">
								<Col sm="4">
									<Form.Label>Litros:</Form.Label>
								</Col>
								<Col>
									<Form.Control
										type="text"
										onChange={editValue}
										value={body?.litros_abastecidos}
										name="litros_abastecidos"
									/>
								</Col>
							</Row>
							<Row className="mb-2">
								<Col sm="4">
									<Form.Label>Último Km:</Form.Label>
								</Col>
								<Col>
									<Form.Control
										type="text"
										onChange={editValue}
										value={body?.ultimo_km}
										name="ultimo_km"
									/>
								</Col>
							</Row>
							<Row className="mb-2">
								<Col sm="4">
									<Form.Label>Valor Combustivel (L):</Form.Label>
								</Col>
								<Col>
									<Form.Control
										type="text"
										onChange={editValue}
										value={body?.valor_combustivel}
										name="valor_combustivel"
									/>
								</Col>
							</Row>
							<Row className="mb-2">
								<Col sm="4">
									<Form.Label>Abastecedor:</Form.Label>
								</Col>
								<Col>
									<Form.Control
										type="text"
										onChange={onChangeInput}
										value={body.abastecedor}
										name="abastecedor"
									/>
								</Col>
							</Row>

							<Row>
								<Col sm="4">
									<Form.Label>Horimetro:</Form.Label>
								</Col>
								<Col>
									<Form.Control
										type="file"
										name="teste"
										onChange={onChangeInputFile}
										// capture='environment'
									/>
								</Col>
							</Row>
						</Form>
					)}
				</Modal.Body>
				<Modal.Footer>
					<Row style={{ width: "100%" }}>
						{props.editar ? (
							<Col>
								<Button
									variant={loading.style}
									onClick={submitDataUpdate}
									disabled={loading.style == "primary" ? false : true}
								>
									{loading.isLoading ? (
										<Loading msg={loading.msg} />
									) : (
										loading.msg
									)}
									{loading.style == "success" ? (
										<i className="bi bi-check"></i>
									) : (
										""
									)}
								</Button>
							</Col>
						) : (
							<Col>
								<Button
									variant={loading.style}
									onClick={submitDataEdit}
									disabled={loading.style == "primary" ? false : true}
								>
									{loading.isLoading ? (
										<Loading msg={loading.msg} />
									) : (
										loading.msg
									)}
									{loading.style == "success" ? (
										<i className="bi bi-check"></i>
									) : (
										""
									)}
								</Button>
							</Col>
						)}
						<Col>
							<Button variant="secondary" onClick={handlerSimpleClose}>
								Cancelar
							</Button>
						</Col>
					</Row>
				</Modal.Footer>
				{error ? (
					<ModalError
						show={error.flag}
						setShow={setError}
						title={error.title}
						msg={error.msg}
					/>
				) : (
					""
				)}
			</Modal>
		</>
	);
}
