import { createRef, useEffect, useLayoutEffect, useState } from "react";
import { Col, Row, Spinner, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import PlanoLositicaLayout from "../PlanoLositicaLayout";
import Loading from "../../loading/Loading";
import {
	updateProdutosList,
	updateRomaneiosList,
} from "../../../redux/slicers/user";
import {
	getAllData,
	getData,
	getDateInit,
	getDateInitMonth,
	getTableColumns,
	verificaTipoUsuario,
} from "../../../services/Servicos";
import BodyRowRomaneios from "../../table/body/BodyRowRomaneios";
import BodyRowProduto from "../../table/body/BodyRowProdutos";
import HeadTable from "../../table/head/HeadRow";
import ScrollbarLeft from "../../scrollbars/ScrollbarLeft";
import {
	filtroDinamicoArrays,
	removerDadosDuplicados,
} from "../../../services/helpers";
import axios from "axios";
import BodyRowDefault from "../../table/body/BodyRowDefault";

const tableRef = createRef();
const tableRefP = createRef();
const tbodyRef = createRef();
const tbodyRefP = createRef();

export default function Romaneios() {
	const [headList, setHead] = useState(["index", "admin", "emissao"]);
	const user = useSelector((state) => state.userSlice);

	const updateArrayRomaneios = async () => {
		const romaneiosGet = await getTableColumns("CargasPedidos");

		let headList = [
			"id_filial",
			"status",
			"nome_vendedor",
			"tipo",
			"romaneio",
			"numero_pedido",
			"cargas_id",
			...Object.keys(romaneiosGet.data).filter(
				(item) =>
					![
						"status",
						"data_cancelamento",
						"nome_vendedor",
						"created_at",
						"updated_at",
						"qr_code_id",
						"cpf_responsavel",
						"emissao",
						"status",
						"nome_vendedor",
						"tipo",
						"romaneio",
						"numero_pedido",
						"cargas_id",
					].includes(item)
			),
		];
		setHead((last) => [...last, ...headList]);
	};

	useLayoutEffect(() => {
		updateArrayRomaneios();
	}, []);

	return (
		<PlanoLositicaLayout>
			<ListarRomaneios headList={headList} />
		</PlanoLositicaLayout>
	);
}

export function ListarRomaneios({
	headList,
	height = 600,
	setNomeromaneioSelecionada = () => {},
	carga_id,
}) {
	const romaneiosF = useSelector((state) => state.userSlice.romaneiosList.data);
	// const [romaneiosF, setRomaneiosF] = useState(romaneios)
	const user = useSelector((state) => state.userSlice);

	const dispatch = useDispatch();

	const [loading, setLoading] = useState(true);
	const [indexList, setIndexList] = useState(10);
	const [filteredTable, setFilteredTable] = useState(false);
	const [filters, setFilters] = useState([]);
	const [filtersNew, setFiltersNew] = useState({});
	const [dataFiltro, setDataFiltro] = useState("");

	useEffect(() => {
		const refTable = tableRef.current;
		const scrollHandler = (e) => {
			const tbodyHeight = tbodyRef.current.clientHeight;
			const divTableHeight = e.target.clientHeight;
			const scrollPosition = e.target.scrollTop;
			if (scrollPosition >= tbodyHeight - divTableHeight) {
				setTimeout(() => {
					setIndexList(indexList + 10);
				}, 1000);
			}
		};

		refTable.addEventListener("scroll", scrollHandler, false);

		return () => refTable.removeEventListener("scroll", scrollHandler, false);
	});

	const onClickRemoveFilter = (e) => {
		e.preventDefault();
		const value = e.target.parentNode.firstElementChild.innerText;
		const [key, keyValue] = value.split(":");
		//
		setFilters((last) => [
			...last.filter(
				(item) =>
					String(item[key]).replaceAll(" ", "") !== keyValue.replaceAll(" ", "")
			),
		]);
	};

	const updateArrayRomaneios = async (data_inicial, data_final) => {
		setLoading(true);
		let id_filial = user.id_filial;

		if (user.id_filial == 2 || user.tipo.toLowerCase() == "empresa") {
			id_filial = null;
		}

		const [anoI, mesI, diaI] = data_inicial.split("-");
		const dataI = new Date(anoI, Number(mesI) - 1, diaI);
		const [anoF, mesF, diaF] = data_final.split("-");
		const dataF = new Date(anoF, Number(mesF) - 1, diaF);

		let objCons = {};
		if (!carga_id) {
			setDataFiltro(
				`de ${dataI.toLocaleDateString()} até ${dataF.toLocaleDateString()}`
			);
		}
		if (
			user.tipo.toLowerCase() !== "empresa" &&
			user.tipo.toLowerCase() !== "gestor"
		) {
			headList.shift("admin");
			const romaneiosGet = await getData("cargas/pedidos/separador", carga_id);
			setLoading(true);
			setTimeout(() => {
				let romaneiosList = romaneiosGet.data;
				if (carga_id) {
					dispatch(updateRomaneiosList(romaneiosGet.data));
					// setRomaneiosF(romaneiosGet.data)
					setLoading(false);
					return;
				}
				dispatch(updateRomaneiosList(romaneiosList));
				setLoading(false);
			}, 500);
			return;
		}

		if (carga_id) {
			objCons = { cargas_id: carga_id };
		} else {
			objCons = { id_filial, data_inicial, data_final };
		}
		let romaneiosGet = await getAllData(
			verificaTipoUsuario(user) + "cargas/pedidos/empresa",
			objCons
		);
		if (carga_id) {
			dispatch(updateRomaneiosList(romaneiosGet.data));
			setLoading(false);
			return;
		}
		dispatch(updateRomaneiosList(romaneiosGet.data));
		setLoading(false);

		//atualizar os status dos romaneios
		// const romaneiosUpdate = romaneiosGet.data
		//     let qtde = 0
		//
		// for await (let romaneio of romaneiosUpdate) {
		//
		//     await axios
		//         .get(`${process.env.REACT_APP_API_VST}st/1/id_filial/${romaneio.id_filial}/id_saida/${romaneio.numero_pedido}?status=${romaneio.status}`)
		//         .then(res => {
		//
		//             })
		//             .catch(err => {
		//
		//             })
		//     qtde++

		//     }
	};

	const [dataParam, setDataParam] = useState({
		show: false,
		data_inicial: null,
		data_final: null,
		filtro: "",
	});

	useEffect(() => {
		const date = new Date();
		const dateCorrect = getDateInitMonth(date);
		updateArrayRomaneios(dateCorrect.data_inicial, dateCorrect.data_final);
		// updateArrayRomaneios('2023-12-01', dateCorrect.data_final)
	}, []);

	useEffect(() => {
		if (!dataParam.data_final && !dataParam.data_inicial) return;
		setLoading(true);

		updateArrayRomaneios(dataParam?.data_inicial, dataParam?.data_final).then(
			() => {
				setDataParam({
					show: false,
					data_inicial: null,
					data_final: null,
				});
			}
		);
	}, [dataParam]);

	useEffect(() => {
		if (!filters.length) {
			setIndexList(10);
			setFilteredTable(false);
			return;
		}

		let testeFilters = {};

		filters.forEach((ele) => {
			// testeFilters[arguments[ele]]
			const param = Object.getOwnPropertyNames(ele)[0];
			testeFilters[param] = [];
		});

		filters.forEach((ele) => {
			// testeFilters[arguments[ele]]
			const param = Object.getOwnPropertyNames(ele)[0];
			// testeFilters[param].push(ele[param])
			testeFilters[param].push(String(ele[param]).toLowerCase());

			if (String(ele[param]).toLowerCase() == "false") {
				testeFilters[param].push("null");
			}
		});

		let arrayRetorno = [romaneiosF];

		Object.keys(testeFilters).forEach((key, index) => {
			arrayRetorno.push(
				filtroDinamicoArrays(
					testeFilters,
					key,
					arrayRetorno[arrayRetorno.length - 1]
				)
			);
		});

		const arrayF = removerDadosDuplicados(
			arrayRetorno[arrayRetorno.length - 1],
			"romaneio"
		);
		setFilteredTable(arrayF.reverse());
	}, [filters]);

	return (
		<>
			{loading ? <h1>Carregando...</h1> : ""}
			<div className="section-filters-table">
				<div className="card-header">
					<h3>
						Filtros Selecionados -{" "}
						<i style={{ fontSize: "inherit" }}>{dataFiltro}</i>
					</h3>
				</div>
				<Row className="mt-2 row-filters">
					<Col>
						<div>
							{filters.map((item, key) =>
								Object.keys(item).map((key, index) => {
									return (
										<span className="filters-card" key={index}>
											<span className="filters">
												<span className="title">
													{key}: {item[key]}
												</span>
												<i
													className="bi bi-x-lg"
													onClick={onClickRemoveFilter}
												></i>
											</span>
										</span>
									);
								})
							)}
						</div>
					</Col>
				</Row>
			</div>
			<div className="section-filters-table">
				<div className="card-header">
					<h3 style={{ display: "flex", alignItems: "center" }}>
						Tabela de Romaneios{" "}
						{loading ? (
							<Spinner
								style={{ marginLeft: "10px", fontSize: "1rem" }}
								animation="border"
								size="sm"
							/>
						) : (
							""
						)}
					</h3>
				</div>
				<div
					className=" responsive-table"
					ref={tableRef}
					style={{ position: "relative", height, maxHeight: "unset" }}
				>
					<ScrollbarLeft
						tableRef={tableRef}
						coluna2={4}
						coluna3={8}
						maxima_distancia={250}
					/>

					{loading ? (
						""
					) : (
						<Table hover size="sm">
							<thead style={{ zIndex: 49 }}>
								<HeadTable
									arrayValues={[...romaneiosF]}
									headerList={headList}
									setArrayFilters={setFiltersNew}
									arrayFilters={filtersNew}
									setFiltersOld={setFilters}
									filtersOld={filters}
									fn={updateArrayRomaneios}
								/>
							</thead>
							<tbody ref={tbodyRef}>
								{filteredTable
									? filteredTable.map((item, index, self) => {
											if (index < indexList) {
												return (
													<BodyRowRomaneios
														key={index}
														bodyList={item}
														headList={headList}
														index={index}
														tableLength={self.length}
													/>
												);
											}
									  })
									: [...romaneiosF].reverse().map((item, index, self) => {
											if (index < indexList) {
												return (
													<BodyRowRomaneios
														key={index}
														bodyList={item}
														headList={headList}
														index={index}
														tableLength={self.length}
														setromaneioSelecionada={setNomeromaneioSelecionada}
														setArrayFilters={setFiltersNew}
													/>
												);
											}
									  })}

								<BodyRowDefault
									key={"index_bodyList_romaneios"}
									bodyList={{
										peso_nf: filteredTable
											? filteredTable.reduce((p, c) => p + c.peso_nf, 0)
											: romaneiosF.reduce((p, c) => p + c.peso_nf, 0),
									}}
									headList={headList}
								/>
							</tbody>
						</Table>
					)}
					{/* CASO NÃO HAJA MAIS VENDAS, REMOVER LOADING  */}
					{filteredTable ? (
						indexList < filteredTable.length ? (
							<Loading color={"black"} msg="Loading" />
						) : (
							""
						)
					) : indexList < romaneiosF.length ? (
						<Loading color={"black"} msg="Loading" />
					) : (
						""
					)}
				</div>
			</div>
		</>
	);
}

export function ListarProdutos({
	headList,
	height = 400,
	setNomeromaneioSelecionada = () => {},
	numero_pedido,
	cargas_id,
}) {
	const dispatch = useDispatch();
	const user = useSelector((state) => state.userSlice);

	const produtosRomaneio = useSelector((state) => state.userSlice.produtosList);
	const [loading, setLoading] = useState(true);
	const [indexList, setIndexList] = useState(10);
	const [filteredTable, setFilteredTable] = useState(false);
	const [filters, setFilters] = useState([]);
	// const [produtosRomaneio, setProdutosRomaneio] = useState([])

	useEffect(() => {
		const refTable = tableRefP.current;
		const scrollHandler = (e) => {
			const tbodyHeight = tbodyRefP.current.clientHeight;
			const divTableHeight = e.target.clientHeight;
			const scrollPosition = e.target.scrollTop;
			if (scrollPosition >= tbodyHeight - divTableHeight) {
				setTimeout(() => {
					setIndexList(indexList + 10);
				}, 1000);
			}
		};

		refTable.addEventListener("scroll", scrollHandler, false);

		return () => refTable.removeEventListener("scroll", scrollHandler, false);
	});

	const onClickRemoveFilter = (e) => {
		e.preventDefault();
		const value = e.target.parentNode.firstElementChild.innerText;
		const [key, keyValue] = value.split(":");
		//
		setFilters((last) => [
			...last.filter(
				(item) =>
					String(item[key]).replaceAll(" ", "") !== keyValue.replaceAll(" ", "")
			),
		]);
	};

	useEffect(() => {
		if (!filters.length) {
			setIndexList(10);
			setFilteredTable(false);
			return;
		}

		let testeFilters = {};

		filters.forEach((ele) => {
			// testeFilters[arguments[ele]]
			const param = Object.getOwnPropertyNames(ele)[0];
			testeFilters[param] = [];
		});

		filters.forEach((ele) => {
			// testeFilters[arguments[ele]]
			const param = Object.getOwnPropertyNames(ele)[0];
			// testeFilters[param].push(ele[param])
			testeFilters[param].push(String(ele[param]).toLowerCase());

			if (String(ele[param]).toLowerCase() == "false") {
				testeFilters[param].push("null");
			}
		});

		let arrayRetorno = [produtosRomaneio.data];

		Object.keys(testeFilters).forEach((key, index) => {
			arrayRetorno.push(
				filtroDinamicoArrays(
					testeFilters,
					key,
					arrayRetorno[arrayRetorno.length - 1]
				)
			);
		});

		const arrayF = removerDadosDuplicados(
			arrayRetorno[arrayRetorno.length - 1],
			"id"
		);
		setFilteredTable(arrayF.reverse());
	}, [filters]);

	const updateArrayProdutos = async () => {
		setLoading(true);

		let url = `produtos/${user.empresas_id || user.id}/${cargas_id}`;
		if (numero_pedido) {
			url = `${url}/${numero_pedido}`;
		}

		const produtosGet = await getAllData(url);
		dispatch(updateProdutosList(produtosGet.data));
		setLoading(false);
		return;
	};

	useLayoutEffect(() => {
		updateArrayProdutos(numero_pedido);
	}, []);

	const [filtersNew, setFiltersNew] = useState({});
	return (
		<>
			{loading ? <h1>Carregando...</h1> : ""}
			<div className="section-filters-table">
				<h3>Filtros Selecionados</h3>
				<hr />

				<Col>
					<div>
						{filters.map((item, key) =>
							Object.keys(item).map((key, index) => {
								return (
									<span className="filters-card" key={index}>
										<span className="filters">
											<span className="title">
												{key}: {item[key]}
											</span>
											<i
												className="bi bi-x-lg"
												onClick={onClickRemoveFilter}
											></i>
										</span>
									</span>
								);
							})
						)}
					</div>
				</Col>
			</div>
			<div className="section-filters-table">
				<h3 style={{ display: "flex", alignItems: "center" }}>
					Tabela de Produtos{" "}
					{loading ? (
						<Spinner
							style={{ marginLeft: "10px", fontSize: "1rem" }}
							animation="border"
							size="sm"
						/>
					) : (
						""
					)}
				</h3>

				<hr />

				<ScrollbarLeft
					tableRef={tableRefP}
					coluna2={9}
					maxima_distancia={650}
				/>
				<div
					className=" responsive-table"
					ref={tableRefP}
					style={{ position: "relative", height }}
				>
					{loading ? (
						""
					) : (
						<Table hover size="sm">
							<thead>
								<HeadTable
									arrayValues={produtosRomaneio.data}
									headerList={headList}
									setArrayFilters={setFiltersNew}
									arrayFilters={filtersNew}
									setFiltersOld={setFilters}
									filtersOld={filters}
								/>
							</thead>
							<tbody ref={tbodyRefP}>
								{filteredTable
									? filteredTable.map((item, index) => {
											if (index < indexList) {
												return (
													<BodyRowProduto
														key={index}
														bodyList={item}
														headList={headList}
														index={index}
													/>
												);
											}
									  })
									: produtosRomaneio.data.map((item, index) => {
											if (index < indexList) {
												return (
													<BodyRowProduto
														key={index}
														bodyList={item}
														headList={headList}
														index={index}
														tableLength={produtosRomaneio.data.length}
														setromaneioSelecionada={setNomeromaneioSelecionada}
														setArrayFilters={setFiltersNew}
													/>
												);
											}
									  })}

								<BodyRowDefault
									key={"index_bodyList_produtos"}
									bodyList={{
										peso: filteredTable
											? filteredTable.reduce((p, c) => p + c.peso, 0)
											: produtosRomaneio?.data?.reduce((p, c) => p + c.peso, 0),
									}}
									headList={headList}
								/>
							</tbody>
						</Table>
					)}
					{/* CASO NÃO HAJA MAIS VENDAS, REMOVER LOADING  */}
					{filteredTable ? (
						indexList < filteredTable.length ? (
							<Loading color={"black"} msg="Loading" />
						) : (
							""
						)
					) : indexList < produtosRomaneio.data.length ? (
						<Loading color={"black"} msg="Loading" />
					) : (
						""
					)}
				</div>
			</div>
		</>
	);
}
