import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DashboardCaminhoneiro from "./dashboards/DashboardCaminhoneiro";
import DashboardEmpresa from "./dashboards/DashboardEmpresa";
import './Dashboard.scss'
import DashboardSeparador from "./dashboards/DashboardSeparador";
import ModalLoading from "../modal/ModalLoading";
import DashboardGestor from "./dashboards/DashboardGestor";
import { getCaminhoes } from "../../services/Servicos";
import { updateCaminhoes } from "../../redux/slicers/user";


export default function Dashboard({data = 0}){
    const user = useSelector(state => state.userSlice)
    const [loading, setLoading] = useState({
        show: true
    })

    const dispatch = useDispatch()

    const firstRender = async () => {
        const [ caminhoes ]= await Promise.all([
            getCaminhoes(user.tipo)
        ])
        dispatch(updateCaminhoes(caminhoes))
    }

    useEffect(() => {
        firstRender()
    },[])
    
    
    
    return (
        <Fragment>
            {user.tipo.toLowerCase() === 'empresa' ? <DashboardEmpresa setLoading={setLoading} loading={loading}/>: ""}
            {user.tipo.toLowerCase() === 'caminhoneiro' ? <DashboardCaminhoneiro setLoading={setLoading} loading={loading}/>: ""}
            {user.tipo.toLowerCase() === 'carregador' ? <DashboardCaminhoneiro setLoading={setLoading} loading={loading}/>: ""}
            {user.tipo.toLowerCase() === 'separador' ? <DashboardSeparador setLoading={setLoading} loading={loading}/> : ""}
            {user.tipo.toLowerCase() === 'gestor' ? <DashboardGestor setLoading={setLoading} loading={loading}/> : ""}
            {loading.show ? <ModalLoading show={loading.show} setShow={setLoading}/> : ""}
            
        </Fragment>

    )

}