import { Fragment, useEffect, useRef, useState, Spinner } from "react";
import { Button, Col, Modal, Row, Card, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import {
	updateCaminhaoSelecionado,
	updateProdutosList,
	updateRomaneiosList,
} from "../../../redux/slicers/user";
import {
	getAllData,
	getStatusTrackingPedidos,
	verificaTipoUsuario,
} from "../../../services/Servicos";

import "../../modal/Modal.scss";
import CarregarCaminhaoGestor from "../../carregamento/CarregarCaminhaoGestor";
import { removeAll } from "../../../redux/slicers/carga";
import MonitoramentoCargaGestor from "../../carregamento/MonitoramentoCargaGestor";
import {
	filtroDinamicoArrays,
	removerDadosDuplicados,
} from "../../../services/helpers";
import Loading from "../../loading/Loading";
import ModalError from "../../modal/ModalError";
import ScrollbarLeft from "../../scrollbars/ScrollbarLeft";
import HeadTable from "../../table/head/HeadRow";
import BodyRowPedidosFromPedidos from "../../table/body/BodyRowPedidosFromPedidos";
import ExportToExcel from "../../exportToExcel/ExportToExcel";
import HeadRowNew from "../../table/head/HeadRowNew";

export default function ModalMonitorarPedidosVST({
	show,
	setShow,
	filial,
	operacoes,
}) {
	const dispatch = useDispatch();
	const user = useSelector((state) => state.userSlice);

	const handlerSimpleClose = () => {
		setShow((last) => ({
			...last,
			flag: false,
			target: 0,
			style: {
				scale: "1",
				zIndex: "1",
				// , transition: '0.5s'
			},
		}));
	};

	return (
		<>
			<Modal
				show={show}
				onHide={handlerSimpleClose}
				backdrop="static"
				keyboard={false}
				centered
				size="xl"
			>
				<Modal.Header closeButton>
					<Modal.Title>Area de Monitoramento - {filial}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<MonitoramentoPedidosLocal operacoesList={operacoes} />
				</Modal.Body>
				<Modal.Footer>
					<Col sm="3">
						<Button variant="primary" onClick={handlerSimpleClose}>
							Fechar
						</Button>
					</Col>
				</Modal.Footer>
			</Modal>
		</>
	);
}

function MonitoramentoPedidosLocal({ operacoesList }) {
	const [pedidos, setPedidos] = useState([]);
	const [pedidosFiltrados, setPedidosFiltrados] = useState([]);
	const [error, setError] = useState({ flag: false, msg: "", title: "" });
	const [sorted, setSorted] = useState({ option: "", order: 1 });

	useEffect(() => {
		Object.keys(operacoesList).forEach((el) => {
			setPedidos((last) => [...last, ...operacoesList[el].vendas]);
		});
	}, []);

	return (
		<Fragment>
			{pedidos.length ? (
				<ShowPedidosEmTabela
					pedidosFiltrados={pedidosFiltrados}
					pedidos={pedidos}
					sorted={sorted}
					setSorted={setSorted}
				/>
			) : (
				""
			)}

			{error.flag ? (
				<ModalError
					title={"ATENÇÃO"}
					show={error.flag}
					setShow={setError}
					msg={error.msg}
				/>
			) : (
				""
			)}
		</Fragment>
	);
}

function ShowPedidosEmTabela({ pedidos, setPedidos = () => {} }) {
	const tableRef = useRef();
	const tbody = useRef();

	const [pedidosL, setPedidosL] = useState([
		...pedidos.map((item) => ({ ...item })),
	]);
	const [indexList, setIndexList] = useState(15000);
	const [error, setError] = useState({
		flag: false,
		msg: "",
	});
	const [headList, setHead] = useState([
		"index",
		"codigoPedido",
		"data_cadastro",
		"dias_producao",
		"data_faturamento",
		"dias_logistica",
		"data_logistica",
		"clienteRetira",
		"status_pedido",
		"nomefunc",
		"tipoOperacao",
		"nomeRazao",
		"pesoBruto",
		"desccidade",
		"vlrVenda",
	]);
	const [filters, setFilters] = useState([]);

	const [filtersNew, setFiltersNew] = useState({});
	const [dataParam, setDataParam] = useState({
		show: false,
		data_inicial: null,
		data_final: null,
		filtro: "",
	});

	const [pedidosFiltrados, setPedidosFiltrados] = useState([]);

	const onClickRemoveFilter = (e) => {
		e.preventDefault();
		const value = e.target.parentNode.firstElementChild.innerText;
		const [key, keyValue] = value.split(":");
		//
		setFilters((last) => [
			...last.filter(
				(item) =>
					String(item[key]).replaceAll(" ", "") !== keyValue.replaceAll(" ", "")
			),
		]);
	};

	useEffect(() => {
		if (!filters.length) {
			setPedidosFiltrados([]);
			return;
		}

		let testeFilters = {};

		filters.forEach((ele) => {
			// testeFilters[arguments[ele]]
			const param = Object.getOwnPropertyNames(ele)[0];
			testeFilters[param] = [];
		});

		filters.forEach((ele) => {
			// testeFilters[arguments[ele]]
			const param = Object.getOwnPropertyNames(ele)[0];
			// testeFilters[param].push(ele[param])
			testeFilters[param].push(String(ele[param]).toLowerCase());

			if (String(ele[param]).toLowerCase() == "false") {
				testeFilters[param].push("null");
			}
		});

		let arrayRetorno = [pedidosL];

		Object.keys(testeFilters).forEach((key, index) => {
			arrayRetorno.push(
				filtroDinamicoArrays(
					testeFilters,
					key,
					arrayRetorno[arrayRetorno.length - 1]
				)
			);
		});

		const arrayF = removerDadosDuplicados(
			arrayRetorno[arrayRetorno.length - 1],
			"codigoPedido"
		);

		setPedidosFiltrados(arrayF);
	}, [filters]);

	return (
		<Fragment>
			<div className="section-filters-table">
				<Row className="mt-2 row-filters">
					<Col>
						<div>
							{filters.map((item, key) =>
								Object.keys(item).map((key, index) => {
									return (
										<span className="filters-card" key={index}>
											<span className="filters">
												<span className="title">
													{key}: {item[key]}
												</span>
												<i
													className="bi bi-x-lg"
													onClick={onClickRemoveFilter}
												></i>
											</span>
										</span>
									);
								})
							)}
						</div>
					</Col>
				</Row>
			</div>
			<div className="card-header pb-2">
				<Row className="justify-content-between">
					<Col sm="12" md="6" lg="8">
						<h3 style={{ display: "flex", alignItems: "center" }}>
							Tabela de Dados
						</h3>
					</Col>
					<Col sm="12" md="6" lg="2">
						<ExportToExcel
							tableName="Pedidos da Filial"
							reference={pedidosFiltrados.length ? pedidosFiltrados : pedidosL}
							paramExport={headList}
						/>
					</Col>
				</Row>
			</div>
			<div className="section-filters-table">
				<ScrollbarLeft tableRef={tableRef} coluna2={3} maxima_distancia={70} />
				<div
					className="responsive-table tes"
					ref={tableRef}
					style={{ height: "600px", maxHeight: "unset" }}
				>
					<Table hover size="sm" bordered>
						<thead style={{ zIndex: 49 }}>
							<HeadRowNew
								arrayValues={pedidosL}
								headerList={headList}
								setArrayFilters={setFiltersNew}
								arrayFilters={filtersNew}
								setFiltersOld={setFilters}
								filtersOld={filters}
								setDataParam={setDataParam}
								arrayPedidos={
									pedidosFiltrados.length ? pedidosFiltrados : pedidosL
								}
								setPedidos={
									pedidosFiltrados.length ? setPedidosFiltrados : setPedidosL
								}
							/>
						</thead>
						<tbody ref={tbody}>
							{pedidosFiltrados.length
								? pedidosFiltrados.map((item, index) => {
										if (index < indexList) {
											return (
												<BodyRowPedidosFromPedidos
													key={index}
													bodyList={item}
													headList={headList}
													index={index}
													tableLength={indexList}
													setArrayFilters={setFiltersNew}
													setPedidos={setPedidosL}
												/>
											);
										}
								  })
								: pedidosL.map((item, index) => {
										if (index < indexList) {
											return (
												<BodyRowPedidosFromPedidos
													key={index}
													bodyList={item}
													headList={headList}
													index={index}
													tableLength={indexList}
													setArrayFilters={setFiltersNew}
													setPedidos={setPedidosL}
												/>
											);
										}
								  })}
						</tbody>
					</Table>
					{/* CASO NÃO HAJA MAIS VENDAS, REMOVER LOADING  */}
					{pedidosFiltrados ? (
						indexList <= pedidosFiltrados.length ? (
							<Loading color={"black"} msg="Loading" />
						) : (
							""
						)
					) : indexList <= pedidos.length ? (
						<Loading color={"black"} msg="Loading" />
					) : (
						""
					)}
					{/* CASO NÃO HAJA MAIS VENDAS, REMOVER LOADING  */}
					{error.flag ? (
						<ModalError
							msg={error.msg}
							title={"ATENÇÃO"}
							show={error.flag}
							setShow={setError}
						/>
					) : (
						""
					)}
					<br />
				</div>
			</div>
		</Fragment>
	);
}
