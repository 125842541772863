import { Fragment, useRef, useState } from "react";
import ModalTableProdutos from "../../modal/ModalTableProdutos";

import "./BodyRow.scss";
import {
	getDanfe,
	getDanfeVST,
	getStatusPedidos,
	getXMLString,
	valorMoeda,
} from "../../../services/Servicos";
import { useSelector } from "react-redux";
import ModalTableProdutosInformacoes from "../../modal/ModalTableProdutosInformacoes";
import ModalVisualizarPDF from "../../modal/ModalVisualizarPDF";
import ModalVisualizarPDFComputer from "../../modal/ModalVisualizarPDFComputer";
import { Spinner } from "react-bootstrap";
import ModalVisualizarDanfe from "../../modal/ModalVisualizarDanfe";
import ModalError from "../../modal/ModalError";

export default function BodyRowPedidosVST({
	headList,
	bodyList,
	index,
	tableLength,
	cargas_id,
	setArrayFilters = () => {},
	setPedidos = () => {},
}) {
	const refRow = useRef(null);
	const refEditar = useRef(null);

	const [loading, setLoading] = useState(false);
	const [produtos, setProdutos] = useState({
		flag: "",
		ID_EMPRESA: "",
		ID_FILIAL: "",
		ID_PEDIDO: "",
	});
	const [informacoesProdutos, setInformacoesProdutos] = useState({
		flag: "",
		ID_EMPRESA: "",
		ID_FILIAL: "",
		ID_PEDIDO: "",
	});

	const verInformacoesProdutos = (e, bodyList) => {
		e.preventDefault();
		const date = new Date(bodyList.dtemissao).toLocaleDateString("pt-BR", {
			timeZone: "UTC",
		});

		setInformacoesProdutos((last) => ({
			...last,
			flag: true,
			ID_EMPRESA: bodyList.idEmpresa,
			ID_FILIAL: bodyList.idFilial,
			ID_PEDIDO: bodyList.codigoPedido,
		}));
	};
	const onClickVerMais = (e, bodyList) => {
		e.preventDefault();
		const date = new Date(bodyList.dtemissao).toLocaleDateString("pt-BR", {
			timeZone: "UTC",
		});

		setProdutos((last) => ({
			...last,
			flag: true,
			ID_EMPRESA: bodyList.idEmpresa,
			ID_FILIAL: bodyList.idFilial,
			ID_PEDIDO: bodyList.codigoPedido,
			pedido: {
				id_filial: bodyList.idFilial,
				numero_pedido: bodyList.codigoPedido,
				emissao: date,
				nome_razao: bodyList.nomeRazao,
				nome_fantasia: bodyList.apelidoFantasia,
				cpf_cnpj: bodyList.cpfCnpj,
				endereco: bodyList.endereco,
				cep: bodyList.cep,
				bairro: bodyList.descbairro,
				cidade: bodyList.desccidade,
				numero: bodyList.numero,
				tel_1: bodyList.fonecelular,
				tel_2: bodyList.foneresidencial,
				valor_total: bodyList.vlrVenda,
				cargas_id,
			},
		}));
	};

	const onClickTableRow = (e) => {
		e.preventDefault();
		refRow.current.classList += " bg-selected";
		let refRowNumber = refRow.current.children[0].innerText;

		let i = refRowNumber;
		let actualSibling = refRow.current.nextSibling;

		while (i < tableLength) {
			if (actualSibling === null) {
				break;
			}
			let classList = "";
			actualSibling.classList.forEach((e) => {
				classList += `${e} `;
			});
			actualSibling.classList = classList.replace("bg-selected", "");

			actualSibling = actualSibling.nextSibling;
			i++;
		}

		i = refRow.current.children[0].innerText;
		actualSibling = refRow.current.previousSibling;
		while (0 < i) {
			if (actualSibling === null) {
				break;
			}

			let classList = "";
			actualSibling.classList.forEach((e) => {
				classList += `${e} `;
			});
			actualSibling.classList = classList.replace("bg-selected", "");

			// actualSibling.classList = ''
			actualSibling = actualSibling.previousSibling;
			i--;
		}
	};

	const [verPDF, setVerPDF] = useState({
		show: false,
		PDF: "",
		title: "",
	});

	const [error, setError] = useState({
		flag: false,
	});

	const findDanfe = async (e, bodyList_) => {
		setLoading(true);

		const { codigoPedido, idFilial, apelidoFantasia } = bodyList_;

		setVerPDF({
			show: true,
			PDF: await getDanfeVST(idFilial, codigoPedido),
			title: "Danfe",
		});

		setError((last) => ({
			...last,
			numero_pedido: "",
		}));
		setLoading(false);
	};

	const user = useSelector((state) => state.userSlice);
	const filiaisList = user.filiaisList.data;

	return (
		<Fragment>
			{
				<tr
					ref={refRow}
					onClick={onClickTableRow}
					className={
						!bodyList.nfeNumero || bodyList.nfeMod != "55" ? "rowDanger" : ""
					}
					style={{ whiteSpace: "nowrap" }}
				>
					{headList.map((item, _index) => {
						if (item === "index") {
							return <td key={_index}> {index + 1}</td>;
						} else if (item.toLowerCase() === "admin") {
							return (
								<td key={_index}>
									<div style={{ alignItems: "baseline" }}>
										{/* {
                                            
                                            bodyList.stLogistica == -1 ? (
                                                )
                                                : ""
                                        } */}
										<span
											title="Pedido atrelado a uma carga"
											onClick={(e) => verInformacoesProdutos(e, bodyList)}
											className="editar"
										>
											<i
												className="bi bi-question-circle"
												style={{ cursor: "pointer" }}
											></i>
										</span>
										{loading ? (
											<Spinner animation="border" size="sm" />
										) : (
											<span title="Ver Danfe" className="editar">
												<i
													className="bi bi-file-earmark-pdf"
													onClick={(e) => {
														findDanfe(e, bodyList);
													}}
													style={{ cursor: "pointer" }}
												></i>
											</span>
										)}
									</div>
								</td>
							);
						} else if (item === "idFilial") {
							return (
								<td key={_index}>
									{filiaisList.filter(
										(item) => item.idFilial == bodyList.idFilial
									)[0]?.identificacaoInterna ?? "NÃO INFORMADO"}
								</td>
							);
						} else if (item === "vlrVenda") {
							return (
								<td key={_index} className="rowDataWidth">
									{valorMoeda(bodyList.vlrVenda)}
								</td>
							);
						} else if (item === "stLogistica") {
							return (
								<td key={_index} className="rowDataWidth">
									{getStatusPedidos(bodyList.stLogistica)}
								</td>
							);
						} else if (item === "dtemissao") {
							return bodyList[item] ? (
								<td key={_index}>
									{" "}
									{new Date(bodyList[item]).toLocaleDateString("pt-BR", {
										timeZone: "UTC",
									})}
								</td>
							) : (
								<td key={_index}></td>
							);
						}
						return (
							<td key={_index} className={item} style={{ minWidth: "100px" }}>
								{" "}
								{bodyList[item]}
							</td>
						);
					})}
				</tr>
			}
			{produtos.flag ? (
				<ModalTableProdutos
					show={produtos.flag}
					setShow={setProdutos}
					ID_EMPRESA={produtos.ID_EMPRESA}
					ID_FILIAL={produtos.ID_FILIAL}
					ID_PEDIDO={produtos.ID_PEDIDO}
					pedido={produtos.pedido}
					setPedidos={setPedidos}
				/>
			) : (
				""
			)}
			{informacoesProdutos.flag ? (
				<ModalTableProdutosInformacoes
					show={informacoesProdutos.flag}
					setShow={setInformacoesProdutos}
					ID_EMPRESA={informacoesProdutos.ID_EMPRESA}
					ID_FILIAL={informacoesProdutos.ID_FILIAL}
					ID_PEDIDO={informacoesProdutos.ID_PEDIDO}
					pedido={informacoesProdutos.pedido}
					setPedidos={setPedidos}
				/>
			) : (
				""
			)}
			{verPDF.show ? (
				<ModalVisualizarDanfe
					title={verPDF.title}
					pdf={verPDF.PDF}
					setShow={setVerPDF}
					show={verPDF.show}
				/>
			) : (
				""
			)}
			{error.flag ? (
				<ModalError
					title={error.title}
					msg={error.msg}
					setShow={setError}
					show={error.flag}
				/>
			) : (
				""
			)}
		</Fragment>
	);
}
