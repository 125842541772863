import axios from "axios"
import { useEffect, useLayoutEffect, useState } from "react"
import { Button, Modal, Table } from "react-bootstrap"
import { useSelector } from "react-redux"

import { insertPedidos, insertProdutos } from "../../services/Servicos"
import Loading from "../loading/Loading"

import './ModalError.scss'
import ModalError from "./ModalError"
import { unicos } from "../../services/helpers"
import ModalTipoDePedido from "./ModalTipoDePedido"

export default function ModalTableProdutosVendas({show, setShow, ID_EMPRESA, ID_FILIAL, ID_PEDIDO, pedido, carga_id = 1, setPedidos= () => {}, stLogistica}) {

    // /* to editing data */
    const [produtos, setProdutos] = useState([])
    const [produtosMedida, setProdutosMedida] = useState([])
    const [produtosVolumes, setProdutosVolumes] = useState([])
    const [headListProdutos, setHeadListProdutos] = useState(['INDEX'])
    const [headListMedidas, setHeadListMedidas] = useState(['INDEX'])
    const [headListVolumes, setHeadListVolumes] = useState(['INDEX'])
    const [error, setError] = useState(false)
    const user = useSelector(state => state.userSlice)

    const [cargaModal, setCargaModal] = useState({
        flag: false,
        pedido,
        produtos: []
    })  
   

    const handlerSimpleClose = () => setShow(last => ({ error: { flag: false } }))

    useLayoutEffect(() => {
        axios.get(`${process.env.REACT_APP_API_VST}/${ID_EMPRESA}/id_filial/${ID_FILIAL}/id_saida/${ID_PEDIDO}`)
        .then(resp =>{
            setProdutos(resp.data.Produtos)
            setHeadListProdutos(last => ([...Object.keys(resp.data.Produtos[0])]))

            if(!resp.data.ProdutosMedida) return            
            setProdutosMedida(resp.data.ProdutosMedida)
            setHeadListMedidas(last => ([...Object.keys(resp.data.ProdutosMedida[0])]))

            if(!resp.data.ProdutosVolumes) return            
            setProdutosVolumes(resp.data.ProdutosVolumes)
            setHeadListVolumes(last => ([...Object.keys(resp.data.ProdutosVolumes[0])]))
        }).catch(error => {
            setError({
                msg: error,
                flag: true,
                title:'Atenção - Problemas na api do VST'
            })
        })

    }, [])

    const returnValue = (unidade, referencia) => {
        if (unidade == 'KG' && referencia == 'PESO') {
            return true
        }
        if (unidade == 'MTS' && referencia == 'COMPRIMENTO') {
            return true
        }
        if ((unidade == 'UN' || unidade == 'BAR' || unidade == 'PC' || unidade == 'RL' || unidade == 'FD'|| unidade == 'LT') && referencia == 'QUANTIDADE') {
            return true
        }
        return false
        
    }

    
    const calcularPeso = (unidade, pesoItem, qtde, metros) => {
        switch (unidade) {
            case 'RL':
                return pesoItem * qtde
            case 'BAR':
                return pesoItem * qtde
            case 'MTS':
                return pesoItem * qtde
            case 'UN':
                return pesoItem * qtde
            case 'PC':
                return pesoItem * qtde
            case 'FD':
                return pesoItem * qtde
            case 'KG':
                return qtde
            default:
                return pesoItem
        }
    }

    
    const handlerAddProd = (e) => {

        if(!tipoPedido.tipo || !tipoPedido.tipo.length) return setError({flag: true, list:['msg: Tipo do pedido não informado'], title:"Error no Pedido"})
        let produtosSelecionados = []

        // calculo do metro quadrado
        
        let metros_quadrado = 0
        for (let index = 0; index < produtos.length; index++) {
            const produto = produtos[index];
            if (produto.descricaoProduto.includes('TELHA') && produto.descricaoProduto.includes('# ISOT')) {

                if (produto.undMedida == 'PC') {
                    const [_, comprimentoPeca] = produto.descricaoProduto.split('MM X ')
                    metros_quadrado += produto.quantidade * Number(comprimentoPeca)/ 1000 
                    continue
                }
                
                metros_quadrado += produto.quantidade
            }
        }

        // if(!produtos.length) return /* bloquear o 'espertão' ao habilitar um botao desabilitado */
        if (!produtosVolumes.length) {

            for (let index = 0; index < produtos.length; index++) {
                const produto = produtos[index];
                let obj = {
                    codigo: produto.codigoProduto
                    , descricao: produto.descricaoProduto
                    , numero_pedido: ID_PEDIDO
                    , sequencia: produto.sequencia
                    , qtde: returnValue(produto.undMedida, 'QUANTIDADE') ? produto.quantidade : 0
                    , comprimento: returnValue(produto.undMedida, 'COMPRIMENTO') ? produto.quantidade : 0
                    , peso: returnValue(produto.undMedida, 'PESO') ? produto.quantidade : 0
                    , abreviatura: produto.undMedida
                    , qr_sku: `E${ID_EMPRESA}F${ID_FILIAL}P${ID_PEDIDO}S${produto.sequencia}V${index}`
                    , cargas_id: pedido.cargas_id
                    , id_filial: pedido.id_filial
                    , peso: calcularPeso(produto.undMedida, produto.pesoItem, produto.quantidade)
                    , pesoItem: produto.pesoItem
                }
                if (produtosMedida.find(medida => medida.codigoProduto === produto.codigoProduto && medida.sequencia === produto.sequencia)) {
                    let medidas = produtosMedida.filter(medida => medida.codigoProduto === produto.codigoProduto && medida.sequencia === produto.sequencia)
                    for (let j = 0; j < medidas.length; j++) { 
                        const medida = medidas[j];
                        produtosSelecionados.push({
                            ...obj                            
                            , comprimento: medida.comprimentoProduto / 1000
                            , qtde: medida.qtdPecas
                            , peso: medida.tipocalculoquantde == 2 ? medida.qtdM2Peso*obj.pesoItem : (medida.tipocalculoquantde == 4 ? medida.qtdM2Peso : 0)
                            , qr_sku: `${obj.qr_sku}M${j}`
                        })
                    }
                } else {
                    produtosSelecionados.push(obj)
                }
                
            }

        } else {
            
            for (let k = 0; k < produtosVolumes.length; k++) {
                const volume = produtosVolumes[k];

                let obj = {
                    codigo: produtos.length > 1 ? unicos(produtos, 'codigoProduto').map(item => item.codigoProduto).join("; ") : produtos[0].codigoProduto
                    , descricao: produtos.length > 1 ? unicos(produtos, 'descricaoProduto').map(item => item.descricaoProduto).join("; "): produtos[0].descricaoProduto
                    , numero_pedido: ID_PEDIDO
                    , sequencia: produtos.map(item => item.sequencia).join(';')
                    , abreviatura: produtos[0].undMedida
                    , qr_sku: `E${ID_EMPRESA}F${ID_FILIAL}P${ID_PEDIDO}S${volume.seqVolume}V${k}`
                    , cargas_id: pedido.cargas_id
                    , id_filial: pedido.id_filial
                    , altura: volume.altura / 1000
                    , comprimento: volume.comprimento / 1000
                    , largura: volume.largura / 1000
                    , qtde: volume.quantidade
                    , peso: volume.peso
                    , volume: Number(volume.altura / 1000) * Number(volume.comprimento / 1000) * Number(volume.largura / 1000)
                }
                produtosSelecionados.push(obj)
                
            }
        }
        if (stLogistica == '24') { // se estiver descarregado na loja
            setCargaModal(last => ({...last
                , pedido: {...last.pedido, metros_quadrado, tipo: tipoPedido.tipo, status: '9' }
                , produtos: [...produtosSelecionados.map(item => ({...item, conferido: 1, user_conferencia: user.name, data_conferencia:new Date().toISOString()}))]
                , flag: true
            }))
            
            
        } else {

            setCargaModal(last => ({...last
                , pedido: { ...last.pedido, metros_quadrado, tipo: tipoPedido.tipo }
                , produtos: [...produtosSelecionados]
                , flag: true
            }))
            
            
        }
        // setCargaModal(last => ({...last
        //     , pedido: {...last.pedido, metros_quadrado, tipo: tipoPedido.tipo }
        //     , produtos: [...produtosSelecionados]
        //     , flag: true
        // }))
    }
    const handlerSetTipoPedido = () => {
        setTipoPedido({flag: true, tipo: ""})
    }
    const [tipoPedido, setTipoPedido] = useState({
        flag:false,
    })
    const [btnAtribuir, setBtnAtribuir] = useState({
        flag:false,
        msg:'Atribuir',
        style:'primary'
    })

    const handlerAttProdutos = async (e) => {

        setBtnAtribuir(last => ({ ...last, flag: true, msg: 'Atribuindo Pedido', style: 'info' }))  
       
        insertPedidos(cargaModal.pedido, user.tipo).then(resp => {
            setBtnAtribuir(last => ({ ...last, msg: 'Atribuindo Produtos'}))  
            const promiseAll = Promise.all([...cargaModal.produtos.map(item => insertProdutos(item, user.tipo))]) 
            promiseAll
            .then(resp => {
                setBtnAtribuir(last => ({...last, flag: false, msg:'Concluido', style:'success'}))   
                setTimeout(() => {
                    setPedidos(last => [
                        ...last
                            .map(item => {
                                if (item.idFilial == cargaModal.pedido.id_filial && item.codigoPedido == cargaModal.pedido.numero_pedido) { item.stLogistica = 0 }
                                return item
                            })])
                    
                    handlerSimpleClose()
                },750)
    
            })
            .catch(error => {
                setBtnAtribuir(last => ({ ...last, flag: false, msg: 'Erro!!!', style: 'danger' }))
                
                setError({
                    msg: error,
                    flag: true,
                    title:'Atenção'
                })
            })

        }).catch(error => {
            setBtnAtribuir(last => ({ ...last, flag: false, msg: 'Erro!!!', style: 'danger' }))     
            
            setError({
                msg: error,
                flag: true,
                title:'Atenção'
            })
        })

        
        
        
    }

    useEffect(() => {
        if(!cargaModal.produtos.length)return
        handlerAttProdutos()
    }, [cargaModal])


    return (
        <>
            <Modal
                show={show}
                onHide={handlerSimpleClose}
                backdrop='static'
                keyboard={false}
                centered
                size='xl'
            >
                <Modal.Header closeButton>
                    <Modal.Title>Pedido: {ID_PEDIDO}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <h4 className="card-header">Produtos</h4>
                <div className=" responsive-table" style={{height: 'auto', marginBottom:'2rem'}}>
                    <Table hover size='sm' style={{position: 'relative', height:'100px'}} >
                        <thead>
                            <tr>
                                {
                                    headListProdutos.map((item, _index) => {
                                        if(_index > 19 ){
                                            return 
                                        }                                       
                                        
                                        return <th key={_index} className={item} style={{minWidth:'100px'}}> {item}</th>
                                    })
                                }
                            </tr>

                        </thead>
                        <tbody>
                            {
                                produtos.map((item, index) => {
                                    
                                    return <tr key={index}>
                                        {
                                            headListProdutos.map((_item, _index) => {
                                                if (_item.toLowerCase() === 'index') {
                                                    return <td key={_index}> {index + 1}</td>
                                                } 
                                                return <td key={_index} className={item} style={{minWidth:'100px'}}> {item[_item]}</td>
                                            })
                                        }
                                    </tr> 
                                    
                                })
                                }
                        </tbody>
                    </Table>
                 </div>
                 <Medidas headListMedidas={headListMedidas} produtosMedidas={produtosMedida}/>
                 <Volumes headListVolumes={headListVolumes} produtosVolumes={produtosVolumes}/>
                </Modal.Body>
                <Modal.Footer className="modal-footer-no-border">
                    <Button variant={btnAtribuir.style} onClick={handlerSetTipoPedido} disabled={btnAtribuir.flag ? true : false }>
                        {btnAtribuir.flag ? <Loading msg={btnAtribuir.msg} color={'black'}/> : btnAtribuir.msg}
                    </Button>
                    <Button variant='secondary' onClick={handlerSimpleClose}>Fechar</Button>
                </Modal.Footer>
            {
                            
                error.flag ? 
                    <ModalError 
                        msg={error.msg} 
                        title={error.title}   
                        show={error.flag}   
                        setShow={setError}   
                        {...error}
                />:""
            }
            {
                            
                tipoPedido.flag ? 
                    <ModalTipoDePedido 
                            show={tipoPedido.flag}   
                            setShow={setTipoPedido}   
                            fnRetorno={handlerAddProd}
                            setTipoPedido={setTipoPedido}
                />:""
            }
            </Modal>
        </>
    )
}

function Volumes({headListVolumes, produtosVolumes}){
    return (
        
        <>
            <h4 className="card-header">Volumes</h4>
            <div className=" responsive-table" style={{height: 'auto', marginBottom:'2rem'}}>
                {
                    !produtosVolumes.length ? <p>Volumes dos produtos <i><b>não fornecidos!</b></i></p>:
                    <Table hover size='sm' style={{position: 'relative', height:'100px'}} >
                        <thead>
                            <tr>
                                {
                                    headListVolumes.map((item, _index) => {
                                        if(_index > 19 ){
                                            return 
                                        }                                       
                                        
                                        return <th key={_index} className={item} style={{minWidth:'100px'}}> {item}</th>
                                    })
                                }
                            </tr>

                        </thead>
                        <tbody>
                            {
                                produtosVolumes.map((item, index) => {
                                    
                                    return <tr key={index}>
                                        {
                                            headListVolumes.map((_item, _index) => {
                                                if (_item.toLowerCase() === 'index') {
                                                    return <td key={_index}> {index + 1}</td>
                                                } 
                                                return <td key={_index} className={item} style={{minWidth:'100px'}}> {item[_item]}</td>
                                            })
                                        }
                                    </tr> 
                                    
                                })
                                }
                        </tbody>
                    </Table>

                }
            </div>
        </>
    )
}

function Medidas({headListMedidas, produtosMedidas}){
    return (
        <>
            <h4 className="card-header">Medidas</h4>
            <div className=" responsive-table" style={{height: 'auto', marginBottom:'2rem'}}>
                {
                    !produtosMedidas.length ? <p>Medidas dos produtos <i><b>não fornecidos!</b></i></p>:
                    <Table hover size='sm' style={{position: 'relative', height:'100px'}} >
                        <thead>
                            <tr>
                                {
                                    headListMedidas.map((item, _index) => {
                                        if(_index > 19 ){
                                            return 
                                        }                                       
                                        
                                        return <th key={_index} className={item} style={{minWidth:'100px'}}> {item}</th>
                                    })
                                }
                            </tr>

                        </thead>
                        <tbody>
                            {
                                produtosMedidas.map((item, index) => {
                                    
                                    return <tr key={index}>
                                        {
                                            headListMedidas.map((_item, _index) => {
                                                if (_item.toLowerCase() === 'index') {
                                                    return <td key={_index}> {index + 1}</td>
                                                } 
                                                return <td key={_index} className={item} style={{minWidth:'100px'}}> {item[_item]}</td>
                                            })
                                        }
                                    </tr> 
                                    
                                })
                                }
                        </tbody>
                    </Table>

                }
            </div>
        </>
    )
}