import axios from "axios";
import {
	Fragment,
	createRef,
	useEffect,
	useLayoutEffect,
	useState,
} from "react";
import { Button, Col, Form, Row, Spinner, InputGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { update } from "../../redux/slicers/user";
import "./LogInOu.scss";
import { useNavigate } from "react-router-dom";
import Loading from "../loading/Loading";
import {
	createCompany,
	getImages,
	locationDev,
	login,
	setOnline,
} from "../../services/Servicos";
import logo from "../../assets/logo1.png";

const nameC = createRef(null);
const emailC = createRef(null);
const cnpjC = createRef(null);
const passwordC = createRef(null);
const enterUserEmail = createRef(null);
const enterUserPassword = createRef(null);
const enterUserTipo = createRef(null);

export function CreateUser() {
	const [company, setCompany] = useState({
		name: "",
		cnpj: "",
		email: "",
		password: "",
		token: "",
		error: {
			flag: false,
			msg: "",
		},
		btnLoading: false,
	});

	const onChangeHandler = (e) => {
		const cnpj = cnpjC.current.value;
		const email = emailC.current.value;
		const password = passwordC.current.value;
		const name = nameC.current.value;
		setCompany((last) => ({
			...last,
			name,
			cnpj,
			email,
			password,
			error: {
				flag: false,
				msg: "",
			},
		}));
	};

	const confirmUser = () => {
		createCompany(company).then((res) => {
			if (!res.error) {
				localStorage.clear();
				window.location.replace("/login");
			} else {
				if (res.msg.includes("cnpj")) {
					res.msg = "CNPJ já Cadastrasdo!";
				}
				setCompany((last) => ({
					...last,
					btnLoading: false,
					error: {
						flag: res.error,
						msg: res.msg,
					},
				}));
			}
		});
	};

	const onSubmitHandler = (e) => {
		e.preventDefault();

		setCompany((last) => ({
			...last,
			btnLoading: true,
			error: {
				cnpj: {
					flag: false,
					msg: "",
				},
				email: {
					flag: false,
					msg: "",
				},
			},
		}));

		hasCnpj(company.cnpj)
			.then((resp) => {
				confirmUser();
			})
			.catch((error) => {
				setCompany((last) => ({
					...last,
					btnLoading: false,
					error: {
						flag: true,
						msg: error.response.data.error_msg,
					},
				}));
			});
	};

	return (
		<>
			<Row className="login mx-auto">
				<Col lg={10} className="mx-auto">
					<Form className="login-form" onSubmit={onSubmitHandler}>
						<Row>
							<h1>
								Cadastrar Empresa ou{" "}
								<span>
									<a href="/login">Entrar</a>
								</span>
							</h1>
						</Row>
						<hr />
						<Form.Group className="mb-3">
							<Form.Label>Nome da Empresa:</Form.Label>
							<Form.Control
								required
								type="text"
								ref={nameC}
								onChange={onChangeHandler}
							/>
						</Form.Group>
						<Form.Group className="mb-3">
							<Form.Label>CNPJ:</Form.Label>
							<Form.Control
								maxLength={14}
								required
								type="text"
								placeholder="Digite apenas numeros"
								ref={cnpjC}
								onChange={onChangeHandler}
							/>
							{company.error.flag &&
							company.error.msg.toLocaleLowerCase().includes("cnpj") ? (
								<Form.Text className="text-muted error-form">
									{company.error.msg}
								</Form.Text>
							) : (
								""
							)}
						</Form.Group>
						<Form.Group className="mb-3">
							<Form.Label>Email:</Form.Label>
							<Form.Control
								required
								type="email"
								placeholder="empresa@gmail.com"
								ref={emailC}
								onChange={onChangeHandler}
							/>
							{company.error.flag && company.error.msg.includes("email") ? (
								<Form.Text className="text-muted error-form">
									Email já Cadastrado!
								</Form.Text>
							) : (
								""
							)}
						</Form.Group>
						<Form.Group className="mb-3">
							<Form.Label>Senha:</Form.Label>
							<Form.Control
								required
								type="password"
								placeholder="Letras e números"
								// minLength={8}
								ref={passwordC}
								onChange={onChangeHandler}
							/>
							{company.error.flag &&
							company.error.msg.includes("Receita Federal") ? (
								<Form.Text className="text-muted error-form">
									Falha na comunicação com a Receita Federal. Tente novamente em
									2 minutos!
								</Form.Text>
							) : (
								""
							)}
						</Form.Group>
						<Button variant="primary" type="submit">
							{company.btnLoading ? (
								<>
									{" "}
									Loading
									<Spinner animation="border" role="status" size="sm">
										<span className="visually-hidden">Loading...</span>
									</Spinner>
								</>
							) : (
								"Cadastrar"
							)}
						</Button>
					</Form>
				</Col>
			</Row>
		</>
	);
}

export function Login({ socket }) {
	const dispatch = useDispatch();
	const getUserState = useSelector((state) => state.userSlice);
	let navigate = useNavigate();
	const [pass, setPass] = useState(false);

	const [user, setUser] = useState({
		email: "",
		password: "",
		tipo: "",
		id: "",
		name: "",
		token: "",
		error: {
			hasEmailError: false,
			hasPasswordError: false,
			hasServerError: false,
			hasUserError: false,
		},
		btnLoading: false,
	});

	const onChangeHandler = () => {
		setUser((last) => ({
			...last,
			email: enterUserEmail.current.value,
			password: enterUserPassword.current.value,
			tipo: enterUserTipo.current.value,
			error: {},
		}));
	};

	const onClickBtn = (e) => {
		e.preventDefault();

		setUser((last) => ({ ...last, btnLoading: true }));

		login(user)
			.then((resp) => {
				if (resp.user.status === false) {
					setUser((last) => ({
						...last,
						btnLoading: false,
						error: { ...last.error, hasUserError: true },
					}));
					return;
				}
				setUser((last) => ({
					...last,
					name: resp.user.name,
					email: resp.user.email,
					id: resp.user.id,
					tipo: resp.user.tipo,
				}));

				resp.user.authenticated = true;
				resp.user.online = true;

				dispatch(update(resp.user));
			})
			.catch((error) => {
				setUser((last) => ({
					...last,
					btnLoading: false,
					error: {
						hasEmailError: error.msg === "Email" ? true : false,
						hasPasswordError: error.msg === "Password" ? true : false,
						hasServerError: error.msg === "Server" ? true : false,
					},
				}));
			});
	};

	useLayoutEffect(() => {
		window.localStorage.clear();
	}, []);

	useEffect(() => {
		if (getUserState.authenticated && getUserState.tipo !== "empresa") {
			setOnline(user, socket);
			navigate("/dashboard");
		} else if (getUserState.authenticated && getUserState.tipo === "empresa") {
			navigate("/dashboard");
		}
	}, [user]);
	return (
		<>
			<Row className="mx-auto">
				<Col md="5" className="brand-viwer-login p-0 d-none d-lg-block">
					<BrandView />
				</Col>
				<Col sm="12" lg="7">
					<Row className="login mx-auto">
						<Col lg={10} className="mx-auto">
							<Form className="login-form" onSubmit={onClickBtn}>
								<Row>
									<h1>
										Entrar ou{" "}
										<span>
											<a href="/create-user">Cadastrar</a>
										</span>
									</h1>
								</Row>
								<hr />
								<Form.Group className="mb-3">
									<Form.Label>Email:</Form.Label>
									<Form.Control
										required
										type="email"
										placeholder="Digite o email"
										ref={enterUserEmail}
										onChange={onChangeHandler}
									/>
									{user.error.hasEmailError ? (
										<Form.Text className="text-muted error-form">
											Email Inexistente
										</Form.Text>
									) : (
										""
									)}
								</Form.Group>
								<Form.Group className="mb-3">
									<Form.Label>Conta:</Form.Label>
									<Form.Select
										required
										ref={enterUserTipo}
										onChange={onChangeHandler}
									>
										<option value="funcionario">Funcionário</option>
										<option value="empresa">Empresa</option>
									</Form.Select>
								</Form.Group>
								<Form.Group className="mb-3">
									<Form.Label>Senha:</Form.Label>
									<Form.Group className="mb-3">
										<InputGroup>
											<Form.Control
												type={!pass ? "password" : "text"}
												placeholder="Digite a Senha"
												ref={enterUserPassword}
												onChange={onChangeHandler}
											></Form.Control>
											<InputGroup.Text
												className="form-icon"
												onClick={() => setPass((last) => !last)}
											>
												<i
													className={`bi bi-${
														!pass ? "eye" : "eye-slash-fill"
													}`}
												></i>
											</InputGroup.Text>
										</InputGroup>
									</Form.Group>
									{/* <Form.Control
                                    required
                                    type='password'
                                    placeholder="Senha"

                                /> */}
									<div className="div-error-forgot-pass">
										<Form.Text className="text-muted recuperar-senha">
											<a href="recuperar-senha" className="recuperar-senha-a">
												Esqueceu a Senha ?
											</a>
										</Form.Text>
										{user.error.hasPasswordError ? (
											<Form.Text className="text-muted recuperar-senha">
												Senha Incorreta
											</Form.Text>
										) : (
											""
										)}
										{user.error.hasServerError ? (
											<Form.Text className="text-muted recuperar-senha">
												Falha na comunicação com servidor!
											</Form.Text>
										) : (
											""
										)}
										{user.error.hasUserError ? (
											<Form.Text className="text-muted recuperar-senha">
												Usuário Inátivo! Entre em contato com sua empresa.
											</Form.Text>
										) : (
											""
										)}
									</div>
									<br />
								</Form.Group>

								<Button
									variant={user.btnLoading ? "warning" : "primary"}
									type="submit"
								>
									{user.btnLoading ? <Loading msg="Entrando" /> : "Entrar"}
								</Button>
							</Form>
						</Col>
					</Row>
				</Col>
			</Row>
		</>
	);
}

const hasCnpj = (cnpj) => {
	return axios({
		method: "GET",
		url: `${locationDev}/has-cnpj`,
		params: {
			cnpj: cnpj,
		},
	});
};

function BrandView() {
	return (
		<div className="text-center">
			<Row className="m-0 p-0">
				<Col sm="12">
					<img src={getImages("logos-empresa/b-log.png")} />
				</Col>
			</Row>
			<Row className="mx-auto text-center text-light">
				<Col>
					<h2>Traçando Novos Rumos</h2>
				</Col>
			</Row>
			<div style={{ position: "relative", padding: 0, marging: 0 }}>
				<div className="street-view"></div>
				{/* <div className='street-view-lines'></div> */}
				{/* {new Array(500).fill(1).map((item, index) => {
                    return <div className='street-view-lines street-view-lines-left-80' style={{left:`calc(150% - ${85*index}px)`}}></div>
                })} */}

				<div className="truck-image">
					<div
						className="wind"
						style={{ width: "20px", bottom: "100px" }}
					></div>
					<div
						className="wind2"
						style={{ width: "20px", bottom: "120px" }}
					></div>
					<div
						className="wind3"
						style={{ width: "30px", bottom: "150px" }}
					></div>
					{/* <div className="wind wind1"  style={{width:'50px', bottom: '110px'}}></div> */}
					<div
						className="wind wind4"
						style={{ width: "35px", bottom: "130px" }}
					></div>
					<div
						className="wind wind5"
						style={{ width: "23px", bottom: "140px" }}
					></div>
					<div
						className="wind wind6"
						style={{ width: "28px", bottom: "160px" }}
					></div>
					{/* <div className="wind2" style={{width:'40px', bottom: '170px'}}></div> */}
				</div>
			</div>
			{/* <div className='street-view-2'></div> */}
		</div>
	);
}
