import { createRef, useEffect, useState } from "react";
import { Col, Row, Spinner, Table } from "react-bootstrap";
import PlanoLositicaLayout from "../PlanoLositicaLayout";
import axios from "axios";
import Loading from "../../loading/Loading";
import {
	getAllData,
	getDateInitMonth,
	getDateInitSEMANAL,
} from "../../../services/Servicos";
import ModalErrorTotal from "../../modal/ModalErrorTotal";
import BodyRowPedidosFromPedidos from "../../table/body/BodyRowPedidosFromPedidos";
import HeadTable from "../../table/head/HeadRow";
import { useSelector } from "react-redux";
import ModalAtualizarDataEntradaVST from "../../modal/ModalAtualizarDataEntradaVST";
import ScrollbarLeft from "../../scrollbars/ScrollbarLeft";
import {
	filtroDinamicoArrays,
	removerDadosDuplicados,
	sortedArrByDate,
} from "../../../services/helpers";
import ModalError from "../../modal/ModalError";
import ModalErrorPretty from "../../modal/ModalErrorPretty";

const tableRef = createRef();
const tbody = createRef();

export default function PedidosJaIncluidos({
	id_empresa = 1,
	cargas_id = 0,
	filters_init = [],
}) {
	// alterar o id_empresa para ser automatico no cliente fermat

	const [pedidos, setPedidos] = useState([]);
	const [loading, setLoading] = useState(pedidos.length ? false : true);
	const [indexList, setIndexList] = useState(30);
	const [error, setError] = useState({
		flag: false,
		msg: "",
	});
	const [headList, setHead] = useState([]);
	const [filters, setFilters] = useState([...filters_init]);
	// const [dataParametros, setDataParametros] = useState({
	//     data_inicial:'',
	//     data_final: '',
	// })
	const [periodoInicial, setPeriodoInicial] = useState(``);

	const user = useSelector((state) => state.userSlice);
	const getVSTAPI = async (data_inicial, data_final) => {
		setLoading(true);
		let filial = user.tipo === "empresa" ? "" : `?filiais=(${user.id_filial})`;

		if (user.id_filial == 2) {
			filial = "";
		}

		const [anoI, mesI, diaI] = data_inicial.split("-");
		const dataI = new Date(anoI, Number(mesI) - 1, diaI);
		const [anoF, mesF, diaF] = data_final.split("-");
		const dataF = new Date(anoF, Number(mesF) - 1, diaF);

		setPeriodoInicial(
			`de ${dataI.toLocaleDateString()} até ${dataF.toLocaleDateString()}`
		);

		axios
			.get(
				`${process.env.REACT_APP_API_VST}/${id_empresa}/dtini/${data_inicial}/dtfim/${data_final}${filial}`,
				{ timeout: 60000 }
			)
			// getAllData('vst/get-pedidos', { data_inicial, data_final, filial })
			.then((resp) => {
				// )
				if (pedidos.length !== resp.data.Vendas.length) {
					setTimeout(() => {
						const headerG = Object.keys(resp.data.Vendas[0]);

						setHead((last) => [
							"index",
							"ADMIN",
							...headerG.filter((item) => item === "dtemissao"),
							...headerG.filter((item) => item === "dtprevisao"),
							...headerG.filter((item) => item === "idFilial"),
							...headerG.filter((item) => item === "stLogistica"),
							...headerG.filter((item) => item === "tipoOperacao"),
							...headerG.filter((item) => item === "tipoFrete"),
							...headerG.filter((item) => item === "nfeNumero"),
							...headerG.filter((item) => item === "observacao1"),
							...headerG.filter((item) => item === "endereco"),
							...headerG.filter((item) => item === "codigoPedido"),
							...headerG.filter((item) => item === "vlrVenda"),
							...headerG
								.filter((item) => item !== "idFilial")
								.filter((item) => item !== "dtemissao")
								.filter((item) => item !== "dtprevisao")
								.filter((item) => item !== "stLogistica")
								.filter((item) => item !== "tipoOperacao")
								.filter((item) => item !== "tipoFrete")
								.filter((item) => item !== "nfeNumero")
								.filter((item) => item !== "observacao1")
								.filter((item) => item !== "endereco")
								.filter((item) => item !== "idFilial")
								.filter((item) => item !== "codigoPedido")
								.filter((item) => item !== "vlrVenda"),
						]);

						let tableTrated = resp.data.Vendas.filter(
							(item) => item.stLogistica != "-1"
						).filter((item) => item.clienteRetira != "SIM");

						const arr = sortedArrByDate(tableTrated, "dtemissao");

						setPedidos(arr);
						// if (user.tipo === 'empresa' || user.id_filial == 2) {
						// } else {

						//     setPedidos(arr.filter(item => item.idFilial === user.id_filial))
						// }
						setFilters((last) => [...last]);
						setLoading(false);
					}, 500);
				}
			})
			.catch((error) => {
				let message = error?.response?.data?.message || error.message;
				if (error.message == "Network Error") {
					message = "API do VST não respondeu como esperado. Contate o suporte";
				}
				setError((last) => ({
					...last,
					flag: true,
					message: message,
				}));
				setLoading(false);
			});
	};

	const [filteredTable, setFilteredTable] = useState(false);

	const onClickRemoveFilter = (e) => {
		e.preventDefault();
		const value = e.target.parentNode.firstElementChild.innerText;
		const [key, keyValue] = value.split(":");
		//
		setFilters((last) => [
			...last.filter(
				(item) =>
					String(item[key]).replaceAll(" ", "") !== keyValue.replaceAll(" ", "")
			),
		]);
	};

	/* first charge */
	useEffect(
		() => {
			const date = new Date();
			const dateCorrect = getDateInitSEMANAL(date);

			getVSTAPI(dateCorrect.data_inicial, dateCorrect.data_final);
		},
		[
			/* dataParametros */
		]
	);

	/* scrollbar */
	useEffect(() => {
		const ref = tableRef.current;
		const handleScroll = (e) => {
			const heightTableBody = tbody.current?.clientHeight;
			const positionScroll = e.target.scrollTop;
			const heightDiv = e.target.clientHeight;

			if (positionScroll >= heightTableBody - heightDiv) {
				setTimeout(() => {
					setIndexList(indexList + 30);
				}, 1000);
			}
		};
		ref.addEventListener("scroll", handleScroll, false);
		return () => {
			ref.removeEventListener("scroll", handleScroll, false);
		};
	});

	const [filtersNew, setFiltersNew] = useState({});
	useEffect(() => {
		if (!filters.length) {
			setIndexList(10);
			setFilteredTable(false);
			return;
		}
		let testeFilters = {};

		filters.forEach((ele) => {
			// testeFilters[arguments[ele]]
			const param = Object.getOwnPropertyNames(ele)[0];
			testeFilters[param] = [];
		});

		filters.forEach((ele) => {
			// testeFilters[arguments[ele]]
			const param = Object.getOwnPropertyNames(ele)[0];
			// testeFilters[param].push(ele[param])
			testeFilters[param].push(String(ele[param]).toLowerCase());

			if (String(ele[param]).toLowerCase() == "false") {
				testeFilters[param].push("null");
			}
		});

		let arrayRetorno = [pedidos];

		Object.keys(testeFilters).forEach((key, index) => {
			arrayRetorno.push(
				filtroDinamicoArrays(
					testeFilters,
					key,
					arrayRetorno[arrayRetorno.length - 1]
				)
			);
		});

		const arrayF = removerDadosDuplicados(
			arrayRetorno[arrayRetorno.length - 1],
			"codigoPedido",
			"idFilial"
		);
		setFilteredTable(arrayF.reverse());
	}, [filters]);

	return (
		<PlanoLositicaLayout>
			{loading ? <h1>Carregando...</h1> : ""}
			<div className="section-filters-table">
				<div className="card-header">
					<h3>
						Filtros Selecionados -{" "}
						<i style={{ fontSize: "inherit" }}>{periodoInicial}</i>
					</h3>
				</div>
				<Row className="mt-2 row-filters">
					<Col>
						<div>
							{filters.map((item, key) =>
								Object.keys(item).map((key, index) => {
									return (
										<span className="filters-card" key={index}>
											<span className="filters">
												<span className="title">
													{key}: {item[key]}
												</span>
												<i
													className="bi bi-x-lg"
													onClick={onClickRemoveFilter}
												></i>
											</span>
										</span>
									);
								})
							)}
						</div>
					</Col>
				</Row>
			</div>
			<div className="section-filters-table">
				<div className="card-header">
					<h3 style={{ display: "flex", alignItems: "center" }}>
						Tabela de Vendas{" "}
						{loading ? (
							<Spinner
								style={{ marginLeft: "10px", fontSize: "1rem" }}
								animation="border"
								size="sm"
							/>
						) : (
							""
						)}
					</h3>
				</div>
				<ScrollbarLeft tableRef={tableRef} coluna2={3} maxima_distancia={70} />
				<div
					className=" responsive-table tes"
					ref={tableRef}
					style={{ height: "600px", maxHeight: "unset" }}
				>
					{loading ? (
						""
					) : (
						<Table hover size="sm" bordered>
							<thead style={{ zIndex: 49 }}>
								<HeadTable
									arrayValues={pedidos}
									headerList={headList}
									setArrayFilters={setFiltersNew}
									arrayFilters={filtersNew}
									setFiltersOld={setFilters}
									filtersOld={filters}
									fn={getVSTAPI}
								/>
							</thead>
							<tbody ref={tbody}>
								{filteredTable
									? filteredTable.map((item, index) => {
											if (index < indexList) {
												return (
													<BodyRowPedidosFromPedidos
														key={index}
														bodyList={item}
														headList={headList}
														index={index}
														tableLength={indexList}
														cargas_id={cargas_id}
														setArrayFilters={setFiltersNew}
														setPedidos={setPedidos}
													/>
												);
											}
									  })
									: pedidos.map((item, index) => {
											if (index < indexList) {
												return (
													<BodyRowPedidosFromPedidos
														key={index}
														bodyList={item}
														headList={headList}
														index={index}
														tableLength={indexList}
														cargas_id={cargas_id}
														setArrayFilters={setFiltersNew}
														setPedidos={setPedidos}
													/>
												);
											}
									  })}
							</tbody>
						</Table>
					)}
					{/* CASO NÃO HAJA MAIS VENDAS, REMOVER LOADING  */}
					{filteredTable ? (
						indexList <= filteredTable.length ? (
							<Loading color={"black"} msg="Loading" />
						) : (
							""
						)
					) : indexList <= pedidos.length ? (
						<Loading color={"black"} msg="Loading" />
					) : (
						""
					)}
					{/* CASO NÃO HAJA MAIS VENDAS, REMOVER LOADING  */}
					{error.flag ? (
						<ModalErrorPretty show={error.flag} setShow={setError} {...error} />
					) : (
						""
					)}
					<br />
				</div>
			</div>
			{/* {dataParam.show ? <ModalData} */}
		</PlanoLositicaLayout>
	);
}
