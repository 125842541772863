import { useEffect } from "react"

export default function ScrollbarLeft({ tableRef, coluna2 = 4, coluna3 = null, maxima_distancia = 250 } ){
    useEffect(() => {
        const refTable = tableRef.current
        const scrollHandler = (e) => {
            // const tbodyHeight = tbodyRef.current.clientWidth
            // const divTableHeight = e.target.clientWidth
            const scrollPosition = e.target.scrollLeft
            let firstElementWidth = Number(e.target.querySelector('table > thead > tr ').children[1].offsetWidth)
            let saecondElementWidth = Number(e.target.querySelector('table > thead > tr ').children[coluna2].offsetWidth) + firstElementWidth

            if (scrollPosition > 50) {
                // if (e.target.querySelector('table > thead > tr ').children[1].classList == 'fixed-th') {
                // } else {
                e.target.querySelector('table > thead > tr ').children[1].classList = 'fixed-th'
                e.target.querySelector('table > thead > tr ').children[1].style = `white-space:nowrap`
                console.log()
                    const teste = e.target.querySelectorAll('table > tbody > tr')
    
                    teste.forEach(e => {
                        e.children[1].classList = 'fixed-th'
                        e.children[1].style = `white-space:nowrap`
                    })
                    
                // }
            } else {
                e.target.querySelector('table > thead > tr ').children[1].classList = ''
                const teste = e.target.querySelectorAll('table > tbody > tr')

                teste.forEach(e => {
                    e.children[1].classList = ''
                })
            }

            if (scrollPosition > maxima_distancia) {
                // if (e.target.querySelector('table > thead > tr ').children[4].classList == 'fixed-th') {
                    
                // } else {
                    e.target.querySelector('table > thead > tr ').children[coluna2].classList = 'fixed-th'
                    e.target.querySelector('table > thead > tr ').children[coluna2].style = `left: ${firstElementWidth + 1}px`
                    const teste = e.target.querySelectorAll('table > tbody > tr')
    
                    teste.forEach(e => {
                        e.children[coluna2].classList = 'fixed-th'
                        e.children[coluna2].style = `left: ${firstElementWidth + 1}px; white-space:nowrap`
                    })
                    
                // }
            } else {
                e.target.querySelector('table > thead > tr ').children[coluna2].classList = ''
                const teste = e.target.querySelectorAll('table > tbody > tr')

                teste.forEach(e => {
                    e.children[coluna2].classList = ''
                })
            }

            if (scrollPosition > maxima_distancia * 2 && coluna3) {
                // if (e.target.querySelector('table > thead > tr ').children[4].classList == 'fixed-th') {
                    
                // } else {
                    e.target.querySelector('table > thead > tr ').children[coluna3].classList = 'fixed-th'
                    e.target.querySelector('table > thead > tr ').children[coluna3].style = `left: ${saecondElementWidth + 1}px`
                    const teste = e.target.querySelectorAll('table > tbody > tr')
    
                    teste.forEach(e => {
                        e.children[coluna3].classList = 'fixed-th'
                        e.children[coluna3].style = `left: ${saecondElementWidth + 1}px; white-space:nowrap`
                    })
                    
                // }
            } else {
                if (coluna3) {
                    e.target.querySelector('table > thead > tr ').children[coluna3].classList = ''
                    const teste = e.target.querySelectorAll('table > tbody > tr')
    
                    teste.forEach(e => {
                        e.children[coluna3].classList = ''
                    })
                }
            }


        }

        refTable.addEventListener('scroll', scrollHandler, false)

        return () => refTable.removeEventListener('scroll', scrollHandler, false)
    })
    return null
}