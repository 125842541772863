import {v4 as uuid} from 'uuid'

export function filtroDinamicoArrays(filters, key, array) {
    let arrayFiltrada = []
    if (!array || !key) return []
    if (['idFilial','id_filial','filial','id_filial_destino'].includes(key)) { 
        const idFilial = filters[key].map(item => item.split('-')[0]?.trim())
        arrayFiltrada.push(...array.filter(pedido => idFilial.indexOf(`${pedido[key]}`) >= 0))
    } else if (key === 'caminhoneiro') { 
        const funcionario = filters[key].map(item => item.split(' - ')[0]?.trim())
        arrayFiltrada.push(...array.filter(pedido => pedido?.caminhoneiro?.includes(funcionario)))
    } else{ 
        arrayFiltrada.push(...array.filter(pedido => {
            return filters[key].indexOf(`${pedido[key]}`.toLowerCase()) >= 0
        }))
    }
    return arrayFiltrada
}

export const removerDadosDuplicados = (arr, key, key2) => arr.filter((item, index, self) => self.findIndex(obj => obj[key] === item[key] && obj[key2] === item[key2]) === index)
export const removerDadosDuplicadosold = (arr, key) => arr.filter((item, index, self) => self.findIndex(obj => obj[key] === item[key]) === index)

export const sortedArrByDate = (arr, key) =>
    arr
        .sort((a, b) => {
            if(new Date(a[key]).getTime() > new Date(b[key]).getTime())  return 1
            if(new Date(a[key]).getTime() < new Date(b[key]).getTime())  return -1
            return 0
        })
        .reverse()


export const telefoneMask = (value) => {
    if (!value) return ""
    value = value.replace(/\D/g,'')
    value = value.replace(/(\d{2})(\d)/,'($1) $2')
    value = value.replace(/(\d)(\d{4})$/, '$1-$2')
    return value
}

export const returnDataWithCorrection = (date) => {
    const horas = new Date(date)
    horas.setHours(horas.getHours() - horas.getTimezoneOffset()/60)
    return horas
}

export const getDateUTC = (date) =>  new Date(date).toLocaleDateString('pt-br', { timeZone: 'UTC' })

export const unicos = (array, params, params2) => array.filter((i, n, s) => s.findIndex(o => o[params] === i[params] && o[params2] === i[params2]) === n)

export const getErrorMessage = (errorObj) => {
	if (errorObj.message == "Network Error") return "Servidor não respondeu como esperado. Contate o suporte";
	return (
		errorObj?.response?.data?.description ||
		errorObj?.response?.data?.message ||
		errorObj?.response?.data?.msg ||
		errorObj?.response?.data ||
		errorObj.message
	);
};

export const getSuccessMessage = (response) => {
	if (response.message == "Network Error") return "Servidor não respondeu como esperado. Contate o suporte";
	return (
		response?.data?.message ||
		response?.data?.msg ||
		response?.data ||
		response.statusText
	);
};

export function getNewId(){
    return uuid()
}

export function getPrettierNumber(num) {
    return  Number(num).toLocaleString("pt-br", { maximumFractionDigits: 2, minimumFractionDigits: 2,})
}

export function getFilialName(arrayFiliais, id_filial) {
    return arrayFiliais.find(i => i.idFilial === id_filial)?.identificacaoInterna || id_filial
}