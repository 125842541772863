import { Fragment } from "react";
import { Card } from "react-bootstrap";

export default function UserLayout({children}){
    return(
    <Fragment>
            <Card className='card-user'>
                {children}
            </Card>
    </Fragment>
    )    
}
