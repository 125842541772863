import { createRef, useEffect, useRef, useState } from "react";
import { Button, Card, Col, Form, Row, Spinner, Table } from "react-bootstrap";
import PlanoLositicaLayout from "../PlanoLositicaLayout";
import axios from "axios";
import Loading from "../../loading/Loading";
import {
	getAllData,
	getData,
	getDateInitMonth,
	getDateInitSEMANAL,
	getStatusPedidos,
	verificarPedido,
} from "../../../services/Servicos";
import ModalErrorTotal from "../../modal/ModalErrorTotal";
import BodyRowPedidosFromPedidos from "../../table/body/BodyRowPedidosFromPedidos";
import HeadTable from "../../table/head/HeadRow";
import { useSelector } from "react-redux";
import ModalAtualizarDataEntradaVST from "../../modal/ModalAtualizarDataEntradaVST";
import ScrollbarLeft from "../../scrollbars/ScrollbarLeft";
import {
	filtroDinamicoArrays,
	getErrorMessage,
	getFilialName,
	getSuccessMessage,
	removerDadosDuplicados,
	sortedArrByDate,
} from "../../../services/helpers";
import BodyRowPedidosVST from "../../table/body/BodyRowPedidosVST";
import ModalError from "../../modal/ModalError";
import ModalFinalizarPedidos from "../../modal/ModalFinalizarPedidos";
import ModalErrorPretty from "../../modal/ModalErrorPretty";
import ModalSuccessPretty from "../../modal/ModalSuccessPretty";

const tableRef = createRef();
const tbody = createRef();

export default function PedidosTotal({
	id_empresa = 1,
	cargas_id = 0,
	filters_init = [],
}) {
	// alterar o id_empresa para ser automatico no cliente fermat

	const refAttPed = useRef();
	const [indexList, setIndexList] = useState(50);
	const [pedidos, setPedidos] = useState([]);
	const [loading, setLoading] = useState(pedidos.length ? false : true);
	const [loadingConsulta, setLoadingConsulta] = useState({
		flag: false,
		style: "warning",
		msg: "Consultar",
	});
	const [success, setSuccess] = useState({ show: false, message: "" });
	const [error, setError] = useState({ flag: false, msg: "" });
	const [headList, setHead] = useState([]);
	const [filters, setFilters] = useState([...filters_init]);
	const [periodoInicial, setPeriodoInicial] = useState(``);
	const [finalizarPedidos, setFinalizarPedidos] = useState({
		flag: false,
	});

	const user = useSelector((state) => state.userSlice);
	const filiaisList = user.filiaisList.data;
	const getVSTAPI = async (data_inicial, data_final) => {
		setLoading(true);
		let filial = user.tipo === "empresa" ? "" : `?filiais=(${user.id_filial})`;

		if (user.id_filial == 2) {
			filial = "";
		}

		const [anoI, mesI, diaI] = data_inicial.split("-");
		const dataI = new Date(anoI, Number(mesI) - 1, diaI);
		const [anoF, mesF, diaF] = data_final.split("-");
		const dataF = new Date(anoF, Number(mesF) - 1, diaF);

		setPeriodoInicial(
			`de ${dataI.toLocaleDateString()} até ${dataF.toLocaleDateString()}`
		);

		axios
			.get(
				`${process.env.REACT_APP_API_VST}/${id_empresa}/dtini/${data_inicial}/dtfim/${data_final}${filial}`,
				{ timeout: 60000 }
			)
			// getAllData('vst/get-pedidos', { data_inicial, data_final, filial })
			.then((resp) => {
				if (pedidos.length !== resp.data.Vendas.length) {
					setTimeout(() => {
						const headerG = Object.keys(resp.data.Vendas[0]);

						setHead((last) => [
							"index",
							"ADMIN",
							"dtemissao",
							"idFilial",
							"stLogistica",
							"tipoOperacao",
							"codigoPedido",
							"nfeNumero",
							"descbairro",
							"clienteRetira",
							"pesoBruto",
						]);

						let tableTrated = resp.data.Vendas;

						const arr = sortedArrByDate(tableTrated, "dtemissao");
						setPedidos(arr);

						setFilters((last) => [...last]);
						setLoading(false);
					}, 500);
				}
			})
			.catch((error) => {
				let message = error?.response?.data?.message || error.message;
				if (error.message == "Network Error") {
					message = "API do VST não respondeu como esperado. Contate o suporte";
				}
				setError((last) => ({
					...last,
					flag: true,
					message: message,
				}));
				setLoading(false);
			});
	};

	const [filteredTable, setFilteredTable] = useState(false);

	const onClickRemoveFilter = (e) => {
		e.preventDefault();
		const value = e.target.parentNode.firstElementChild.innerText;
		const [key, keyValue] = value.split(":");
		//
		setFilters((last) => [
			...last.filter(
				(item) =>
					String(item[key]).replaceAll(" ", "") !== keyValue.replaceAll(" ", "")
			),
		]);
	};

	/* first charge */
	useEffect(
		() => {
			const date = new Date();
			const dateCorrect = getDateInitSEMANAL(date);

			getVSTAPI(dateCorrect.data_inicial, dateCorrect.data_final);
		},
		[
			/* dataParametros */
		]
	);

	/* scrollbar */
	useEffect(() => {
		const ref = tableRef.current;
		const handleScroll = (e) => {
			const heightTableBody = tbody.current?.clientHeight;
			const positionScroll = e.target.scrollTop;
			const heightDiv = e.target.clientHeight;

			if (positionScroll >= heightTableBody - heightDiv) {
				setTimeout(() => {
					setIndexList(indexList + 30);
				}, 1000);
			}
		};
		ref.addEventListener("scroll", handleScroll, false);
		return () => {
			ref.removeEventListener("scroll", handleScroll, false);
		};
	});

	const [filtersNew, setFiltersNew] = useState({});
	useEffect(() => {
		if (!filters.length) {
			setIndexList(30);
			setFilteredTable(false);
			return;
		}
		let testeFilters = {};

		filters.forEach((ele) => {
			// testeFilters[arguments[ele]]
			const param = Object.getOwnPropertyNames(ele)[0];
			testeFilters[param] = [];
		});

		filters.forEach((ele) => {
			// testeFilters[arguments[ele]]
			const param = Object.getOwnPropertyNames(ele)[0];
			// testeFilters[param].push(ele[param])
			testeFilters[param].push(String(ele[param]).toLowerCase());

			if (String(ele[param]).toLowerCase() == "false") {
				testeFilters[param].push("null");
			}
		});

		let arrayRetorno = [pedidos];

		Object.keys(testeFilters).forEach((key, index) => {
			arrayRetorno.push(
				filtroDinamicoArrays(
					testeFilters,
					key,
					arrayRetorno[arrayRetorno.length - 1]
				)
			);
		});

		const arrayF = removerDadosDuplicados(
			arrayRetorno[arrayRetorno.length - 1],
			"codigoPedido",
			"idFilial"
		);
		setFilteredTable(arrayF.reverse());
	}, [filters]);

	const [existeRomaneio, setExisteRomaneio] = useState({
		verificado: false,
		msg: "Verificar",
	});

	const onClickAtualizarPedido = async (e) => {
		e.preventDefault();
		const numero_pedido = refAttPed.current.numero_pedido.value;
		const filial = refAttPed.current.filial.value;

		if (!filial)
			return setError({
				flag: true,
				message: "Filial não informada",
				title: "Erro",
			});
		if (!numero_pedido)
			return setError({
				flag: true,
				message: "Numero do Pedido não informado!",
				title: "Erro",
			});
		if (!existeRomaneio.verificado)
			return setError({
				flag: true, 
				message: "Numero do pedido ainda não verificado",
				title: "Erro",
			});

		try {
			const data = await axios.get(`${process.env.REACT_APP_API_VST}st/1/id_filial/${filial}/id_saida/${numero_pedido}?status=-1`)
			refAttPed.current.reset();
			setLoadingConsulta({ flag: false, msg: "Verificar", style: "warning" });
			setSuccess({ show: true, message: `Pedido atualizado ${numero_pedido}: Disponível para Planejamento!` })
			
		} catch (error) {
			setError({flag: true, message: getErrorMessage(error)})
		}
	};

	const [romaneiosExistentes, setRomaneiosExistentes] = useState({
		filialOrigem: '',
		cargaOrigem: '',
		statusOrigem: '',
		filialDestino: '',
		cargaDestino: '',
		statusDestino: '',
		cliente: '',
		entregarNaLoja: false,
	})

	const verificaPedido = (e) => {
		const numero_pedido = refAttPed.current.numero_pedido.value;
		const filial = refAttPed.current.filial.value;
		setRomaneiosExistentes({
			filialOrigem: '',
			cargaOrigem: '',
			statusOrigem: '',
			filialDestino: '',
			cargaDestino: '',
			statusDestino: '',
			cliente: '',
			entregarNaLoja: false,
		})

		if (!filial)
			return setError({
				flag: true,
				message: "Filial não informada",
				title: "Erro",
			});
		if (!numero_pedido)
			return setError({
				flag: true,
				message: "Numero do Pedido não informado!",
				title: "Erro",
			});

		setLoadingConsulta({ flag: true, msg: "Verificando", style: "info" });
		verificarPedido(`pedidos/${filial}`, numero_pedido)
			.then((response) => {
				setSuccess({ show: true, message: getSuccessMessage(response) })
				setExisteRomaneio({verificado: true})
				refAttPed.current.atualizar.disabled = false;
				setLoadingConsulta({ flag: false, msg: "Consultar", style: "warning" });
				
				
			})
			.catch((error) => {
				setExisteRomaneio({verificado: false})

				console.log(error)
				if (error.message == 'Request failed with status code 409') {
					const [romaneioOrigem, romaneioDestino] = error?.response?.data
					let obj = {}
					if (romaneioDestino) {
						obj.cargaDestino = romaneioDestino.cargas_id
						obj.filialDestino = String(romaneioDestino.romaneio).padStart(6, '0')
						obj.statusDestino = getStatusPedidos(romaneioDestino.status)
					}
					setRomaneiosExistentes({
						cargaOrigem: romaneioOrigem.cargas_id,
						filialOrigem: getFilialName(filiaisList, romaneioOrigem.id_filial),
						cliente: romaneioOrigem.nome_razao.slice(0, 10) + '...',
						entregarNaLoja: romaneioOrigem.tipo.includes("ENTREGAR NA LOJA"),
						statusOrigem: getStatusPedidos(romaneioOrigem.status),
						...obj
						
					})
				}
				refAttPed.current.atualizar.disabled = true;
				setLoadingConsulta({ flag: false, msg: 'Consultar', style: "warning" });

			});
	};

	return (
		<PlanoLositicaLayout>
			{loading ? <h1>Carregando...</h1> : ""}
			<div className="section-filters-table">
				<div className="card-header">
					<h3>
						Filtros Selecionados -{" "}
						<i style={{ fontSize: "inherit" }}>{periodoInicial}</i>
					</h3>
				</div>
				<Row className="mt-2 row-filters">
					<Col>
						<div>
							{filters.map((item, key) =>
								Object.keys(item).map((key, index) => {
									return (
										<span className="filters-card" key={index}>
											<span className="filters">
												<span className="title">
													{key}: {item[key]}
												</span>
												<i
													className="bi bi-x-lg"
													onClick={onClickRemoveFilter}
												></i>
											</span>
										</span>
									);
								})
							)}
						</div>
					</Col>
				</Row>
			</div>
			<Row>
				<Col sm="12" xl="7">
					<div className="section-filters-table">
						<div className="card-header">
							<h3 style={{ display: "flex", alignItems: "center" }}>
								Tabela de Pedidos{" "}
								{loading ? (
									<Spinner
										style={{ marginLeft: "10px", fontSize: "1rem" }}
										animation="border"
										size="sm"
									/>
								) : (
									""
								)}
							</h3>
						</div>
						<ScrollbarLeft
							tableRef={tableRef}
							coluna2={3}
							maxima_distancia={70}
						/>
						<div
							className=" responsive-table"
							style={{ maxHeight: "600px" }}
							ref={tableRef}
						>
							{loading ? (
								""
							) : (
								<Table hover size="sm" bordered>
									<thead style={{ zIndex: 49 }}>
										<HeadTable
											arrayValues={pedidos}
											headerList={headList}
											setArrayFilters={setFiltersNew}
											arrayFilters={filtersNew}
											setFiltersOld={setFilters}
											filtersOld={filters}
											fn={getVSTAPI}
										/>
									</thead>
									<tbody ref={tbody}>
										{filteredTable
											? filteredTable.map((item, index) => {
													if (index < indexList) {
														return (
															<BodyRowPedidosVST
																key={index}
																bodyList={item}
																headList={headList}
																index={index}
																tableLength={indexList}
																cargas_id={cargas_id}
																setArrayFilters={setFiltersNew}
																setPedidos={setPedidos}
															/>
														);
													}
											  })
											: pedidos.map((item, index) => {
													if (index < indexList) {
														return (
															<BodyRowPedidosVST
																key={index}
																bodyList={item}
																headList={headList}
																index={index}
																tableLength={indexList}
																cargas_id={cargas_id}
																setArrayFilters={setFiltersNew}
																setPedidos={setPedidos}
															/>
														);
													}
											  })}
									</tbody>
								</Table>
							)}
							{/* CASO NÃO HAJA MAIS VENDAS, REMOVER LOADING  */}
							{filteredTable ? (
								indexList <= filteredTable.length ? (
									<Loading color={"black"} msg="Loading" />
								) : (
									""
								)
							) : indexList <= pedidos.length ? (
								<Loading color={"black"} msg="Loading" />
							) : (
								""
							)}
							{/* CASO NÃO HAJA MAIS VENDAS, REMOVER LOADING  */}
							{error.flag ? (
								<ModalErrorPretty
									show={error.flag}
									setShow={setError}
									{...error}
								/>
							) : (
								""
							)}
							<br />
						</div>
					</div>
				</Col>
				<Col sm="12" xl="5">
					<div className="section-filters-table">
						<div className="card-header">
							<h3 style={{ display: "flex", alignItems: "center" }}>
								Atualizar Status do Pedido{" "}
								{loading ? (
									<Spinner
										style={{ marginLeft: "10px", fontSize: "1rem" }}
										animation="border"
										size="sm"
									/>
								) : (
									""
								)}
							</h3>
						</div>
						<Form
							style={{
								padding: "25px",
								border: "0.5px solid #e5d8d8",
								boxShadow: "0 0 5px #e5d8d8",
							}}
							ref={refAttPed}
							onChange={() => {
								refAttPed.current.atualizar.disabled = true;
								setExisteRomaneio({ verificado: false, msg: "Verificar" });
								setLoadingConsulta({
									msg: "Verificar",
									style: "warning",
									flag: false,
								});
								refAttPed.current.status.value = getStatusPedidos(-1);
							}}
						>
							<Form.Group style={{ marginBottom: "20px" }} className="mb-2">
								<Form.Label>Filial</Form.Label>
								<Form.Select name="filial" required>
									<option></option>
									{filiaisList.map((item, index) => (
										<option key={index} value={item.idFilial}>
											{item.identificacaoInterna}
										</option>
									))}
								</Form.Select>
							</Form.Group>
							<Form.Group style={{ marginBottom: "20px" }} className="mb-2">
								<Form.Label>Numero do Pedido</Form.Label>
								<Form.Control
									name="numero_pedido"
									onChange={(e) => {
										e.preventDefault();
										refAttPed.current.numero_pedido.value =
											e.currentTarget.value.replace(/\D/, "");
									}}
									required
								></Form.Control>
							</Form.Group>
							{!!romaneiosExistentes.cargaOrigem && (
								<div className='d-flex align-items-center' style={{border:'1px solid black', padding: '10px', marginBlock:'20px', display:'flex', justifyContent:'space-between'}}>
									<div className='d-flex flex-column align-items-center'>
										<i className="bi bi-shop"></i>
										<div style={{ fontSize: '0.7rem' }}>{romaneiosExistentes.filialOrigem}</div>	
									</div>
									<div className="d-flex flex-column align-items-center">
										<div style={{ fontSize: '0.7rem' }}>Carga {romaneiosExistentes.cargaOrigem}</div>	
										<i className="bi bi-arrow-right"></i>
										<div style={{ fontSize: '0.7rem' }}>{ romaneiosExistentes.statusOrigem}</div>	
									</div>
									{romaneiosExistentes.entregarNaLoja && (
										<>
											<div className="d-flex flex-column align-items-center">
												<i className="bi bi-shop"></i>
												<div style={{ fontSize: '0.7rem' }}>{romaneiosExistentes.filialDestino}</div>	
											</div>
											<div className="d-flex flex-column align-items-center">
												<div style={{ fontSize: '0.7rem' }}>Carga {romaneiosExistentes.cargaDestino}</div>	
												<i className="bi bi-arrow-right"></i>
												<div style={{ fontSize: '0.7rem' }}>{romaneiosExistentes.statusDestino}</div>	
											</div>
										</>
									)}
									<div className='d-flex flex-column align-items-center'>
										<i className="bi bi-person-circle"></i>
										<div style={{ fontSize: '0.7rem' }}>{romaneiosExistentes.cliente}</div>	
									</div>
								</div>
							)}
							{/* <Form.Group style={{ marginBottom: "20px" }} className="mb-2">
								<Form.Label>Status</Form.Label>
								<Form.Control
									defaultValue={getStatusPedidos(-1)}
									disabled
									name="status"
								></Form.Control>
							</Form.Group>
							<Form.Group style={{ marginBottom: "20px" }} className="mb-4">
								<Form.Label>Existe Romaneio ?</Form.Label>
								<Form.Control
									disabled
									value={existeRomaneio.msg}
									name="existe_romaneio"
								/>
							</Form.Group> */}
							<Row>
								<Col sm="12" lg="4" className="mb-2">
									<Button
										type="button"
										variant={loadingConsulta.style}
										onClick={() => verificaPedido()}
									>
										{loadingConsulta.msg}
										{loadingConsulta.flag ? (
											<Spinner animation="border" size="sm" />
										) : (
											""
										)}
										{loadingConsulta.style == "success" ? (
											<i className="bi bi-check"></i>
										) : (
											""
										)}
									</Button>
								</Col>
								<Col sm="12" lg="4" className="mb-2">
									<Button
										type="button"
										variant="primary"
										name="atualizar"
										onClick={(e) => onClickAtualizarPedido(e)}
									>
										Atualizar
									</Button>
								</Col>
								<Col sm="12" lg="4" className="mb-2">
									<Button type="reset" variant="secondary">
										Apagar
									</Button>
								</Col>
								<Col sm="12">
									<Button
										variant="danger"
										onClick={() => {
											setFinalizarPedidos({
												flag: true,
												pedidos: filteredTable.length ? filteredTable : pedidos,
											});
										}}
									>
										Finalizar Pedidos
									</Button>
								</Col>
							</Row>
						</Form>
					</div>
				</Col>
			</Row>

			{finalizarPedidos.flag ? (
				<ModalFinalizarPedidos
					show={finalizarPedidos.flag}
					setShow={setFinalizarPedidos}
					{...finalizarPedidos}
				/>
			) : (
				""
			)}
			{
				success.show ? <ModalSuccessPretty setShow={setSuccess} {...success}/> : ""
			}
		</PlanoLositicaLayout>
	);
}
