import { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import {
	createManifesto,
	getData,
	numerosEditadoBR,
	valorMoeda,
	verificaTipoUsuario,
} from "../../services/Servicos";
import Loading from "../loading/Loading";

import "./Modal.scss";
import ModalError from "./ModalError";
import axios from "axios";

export default function ModalMontaManifesto(props) {
	const caminhao = useSelector(
		(state) => state.userSlice.caminhoesList.data
	).find((item) => item.status && props.body.caminhao === item.placa);
	const user = useSelector((state) => state.userSlice);
	const [firstLoading, setFirstLoading] = useState(true);
	const [loading, setLoading] = useState({
		isLoading: false,
		msg: "Salvar",
		style: "primary",
	});
	const [municipios, setMunicipios] = useState([]);
	const [estados, setEstados] = useState([]);
	const [estadosDestino, setEstadosDestino] = useState([]);
	const [municipiosDestino, setMunicipiosDestino] = useState([]);

	const [municipiosDescar, setMunicipiosDescar] = useState([]);
	const [pedidos, setPedidos] = useState([]);
	const [body, setBody] = useState({
		cnpj: "03658692000158",
		// tpTransp: "ETC",
		// tpEmit: "2",
		// tipo: "",
		identificacao: {
			inicio_viagem: "",
			municipio_carregamento: {
				codigo_ibge: "",
				nome: "",
			},
			percurso: {
				inicio: "MT",
				fim: "MT",
				trajeto: [],
			},
			canal_verde: "1",
			carrega_posterior: "1",
		},
		modal: {
			rodo: {
				antt: {
					rntrc: "03139653",
					ciot: {
						numero: "123456789012",
						cpf: "740.834.520-56",
					},
					contratante: {
						cnpj: "09.230.232/0003-72",
					},
				},
				veiculo: {
					tracao: {
						codigo: "01",
						tipo_rodado: "02", //02 toco/truck/bitruck - 03 cavalo mecanico
						tipo_carroceria: "02",
						placa: "AVK-9A88",
						uf: "MT",
						tara: "9000",
						capacidade_kg: "14000",
						condutor: {
							nome: user.funcionariosList.data.find(
								(item) => item.cpf === props.body.caminhoneiro
							).name,
							cpf: props.body.caminhoneiro,
						},
					},
					reboques: [],
				},
				lacres: ["1502401"],
				// lacres: [""]
			},
		},
		docs: [
			// {
			//     cod_municipio_ibge: "5107958",
			//     municipio: "Tangará da Serra",
			//     nfe: [
			//         {
			//         chave: "51231003658692000158550010002105431003355573",
			//         unidades_transporte: [
			//             {
			//             tipo: "1",
			//             identificacao: "AVK-9A88",
			//             rateio: "3.50",
			//             lacres: ["00000003","00000004"],
			//             unidade_carga: [
			//                 {
			//                     tipo: "1",
			//                     identificacao: "01234567890123456789",
			//                     lacres: ["00000003","00000004"],
			//                     rateio: "3.50"
			//                 }
			//             ]}
			//         ]}
			//     ]
			// }
		],
		// seguro_carga: {
		//     responsavel: { tipo: "1"},
		//     seguradora: {
		//         nome: "SOMPO SEGUROS",
		//         cnpj: "89.482.627/0001-30"
		//     },
		//     numero_apolice: "11223344559",
		//     numero_averbacoes: ["0572012190000000000007257001000199899141", "0572012190000000000007257001000199708142"]
		// },
		totalizadores: {
			nfe: "1",
			valor_carga: "",
			unidade_peso: "",
			peso_carga: "",
		},

		// linhas comentadas com o manifesto ja em funcionamento 26/12/23

		// lacres: {
		//     nLacre: "0000002"
		// },
		autorizados: [
			{
				cnpj: "18.615.599/0001-33",
			},
			{
				cpf: "005.153.171-28",
			},
		],
		informacoes_adicionais: {
			fisco: "222",
			complementar: "",
			// fisco: "Contrato No 007018 2 CARR BBB1111",
			// complementar: "Contrato No 007018 2 CARR BBB1111"
		},
	});
	const [error, setError] = useState(false);
	const formRef = useRef();

	const handlerSimpleClose = () =>
		props.setShow((last) => ({ ...last, flag: false }));

	const submitDataEdit = () => {
		if (!props.body.caminhao) {
			setError({
				flag: true,
				title: "Erro",
				list: {
					erro: "Gerar Manifesto",
					motivo: "Caminhão não especificado!",
				},
			});
			return;
		}
		if (!props.body.caminhoneiro) {
			setError({
				flag: true,
				title: "Erro",
				list: {
					erro: "Gerar Manifesto",
					motivo: "Condutor não especificado!",
				},
			});
			return;
		}
		if (!body.identificacao.inicio_viagem) {
			setError({
				flag: true,
				title: "Erro",
				list: {
					erro: "Gerar Manifesto",
					motivo: "Data de carregamento não especificada",
				},
			});
			return;
		}
		if (pedidos.filter((item) => !item.codigo_ibge).length) {
			setError({
				flag: true,
				title: "Erro",
				list: {
					erro: "Gerar Manifesto",
					motivo: "Pedido da Carga sem municipio de descarregamento",
				},
			});
			return;
		}
		if (pedidos.filter((item) => !item.peso_nf).length) {
			setError({
				flag: true,
				title: "Erro",
				list: {
					erro: "Gerar Manifesto",
					motivo: "Pedido da Carga sem PESO DA NOTA FISCAL",
					PEDIDOS: pedidos
						.filter((item) => !item.peso_nf)
						.map((item) => item.numero_pedido)
						.join("  -  "),
				},
			});
			return;
		}

		setLoading({
			isLoading: true,
			msg: "Enviando",
			style: "warning",
		});

		const bodySent = {
			...body,
			identificacao: {
				...body.identificacao,
				inicio_viagem: new Date(body.identificacao.inicio_viagem).toISOString(),
				percurso: {
					...body.identificacao.percurso,
					trajeto:
						body.identificacao.percurso.inicio ==
						body.identificacao.percurso.fim
							? null
							: body.identificacao.percurso.trajeto,
				},
			},
			docs: municipiosDescar,
		};

		createManifesto(
			{
				manifesto: bodySent,
				carga: {
					caminhao: props.body.caminhao,
					caminhoneiro: props.body.caminhoneiro,
					cargas_id: props.body.id,
					emissao: new Date().toISOString(),
					filial: props.body.id_filial,
				},
			},
			user.tipo
		)
			.then((response) => {
				setLoading({
					isLoading: false,
					msg: "Enviado",
					style: "success",
				});
				props.setCodigoMDFE(response.data);
				setTimeout(() => {
					handlerSimpleClose();
				}, 1000);
			})
			.catch((error) => {
				setLoading({
					isLoading: false,
					msg: "Error",
					style: "danger",
				});

				setError({
					title: "Erro",
					flag: true,
					list: error.response.data ?? "Erro no banco de dados",
				});

				setTimeout(() => {
					setLoading({
						isLoading: false,
						msg: "Enviar",
						style: "primary",
					});
				}, 1000);
			});
	};

	const onChangeInput = (e) => {
		if (e.target.name == "inicio_viagem") {
			setBody((last) => ({
				...last,
				identificacao: {
					...last.identificacao,
					inicio_viagem: e.target.value,
				},
			}));
			return;
		}
		if (e.target.name == "municipio_carregamento") {
			setBody((last) => ({
				...last,
				identificacao: {
					...last.identificacao,
					municipio_carregamento: {
						codigo_ibge: `${e.target.value}`,
						nome: municipios.find((item) => item.id == e.target.value)?.nome,
					},
				},
			}));
			return;
		}
		if (e.target.name == "uf_fim_descarregamento") {
			setBody((last) => ({
				...last,
				identificacao: {
					...last.identificacao,
					percurso: {
						...last.identificacao.percurso,
						fim: `${e.target.value}`,
					},
				},
			}));
			return;
		}
		if (e.target.name == "uf_placa_caminhao") {
			setBody((last) => ({
				...last,
				modal: {
					rodo: {
						veiculo: {
							tracao: {
								...last.modal.rodo.veiculo.tracao,
								uf: e.target.value,
							},
						},
					},
				},
			}));
			return;
		}
		setBody((last) => ({
			...last,
			[e.target.name]: e.target.value,
		}));
	};

	const handlerAddPedidoMunicipio = (e) => {
		const codigoIbge = formRef.current.municipio_descarregamento.value;
		const chaveNfe = formRef.current.chave_nfe.value;
		const lastArray = municipiosDescar.find(
			(item) => item.cod_municipio_ibge == codigoIbge
		)?.nfe;

		if (!codigoIbge)
			return setError({
				flag: true,
				title: "Erro",
				list: "Selecione a cidade destino",
			});
		if (!chaveNfe)
			return setError({
				flag: true,
				title: "Erro",
				list: "Selecione o pedido",
			});

		// let newArray = []
		// if (municipiosDescar.find(item => item.cod_municipio_ibge == codigoIbge)) {
		//     newArray =
		// }
		// docs: [
		//     ...romaneiosGet.data.map((pedido, index) => ({
		//         // cod_municipio_ibge: `510795${index}`,
		//         // municipio: pedido.cidade,
		//         nfe: [{
		//                 chave: pedido.chave_nfe
		//                 , unidades_transporte: [{
		//                     tipo: "1"
		//                     , identificacao: "AVK-9A88"
		//                     , rateio: "3.50"
		//                     , lacres: ["00000003","00000004"],
		//                     unidade_carga: [{
		//                             tipo: "1",
		//                             identificacao: "01234567890123456789",
		//                             lacres: ["00000003","00000004"],
		//                             rateio: "3.50"
		//                     }]
		//             }]
		//         }]
		//     }))
		// ]
		if (lastArray) {
			setMunicipiosDescar((last) => [
				...last.filter((item) => item.cod_municipio_ibge != codigoIbge),
				{
					cod_municipio_ibge: codigoIbge,
					municipio: municipiosDestino.find(
						(municipio) => municipio.id == codigoIbge
					).nome,
					nfe: [
						...lastArray,
						{
							chave: chaveNfe,
							unidades_transporte: [
								{
									tipo: "1",
									identificacao: body.modal.rodo.veiculo.tracao.placa,
									// , rateio: "3.50"
									// , lacres: ["00000003", "00000004"]
									unidade_carga: [
										{
											tipo: "1",
											identificacao: body.modal.rodo.veiculo.tracao.placa,
											// , lacres: ["00000003","00000004"]
											// , rateio: "3.50"
										},
									],
								},
							],
						},
					],
				},
			]);
		} else {
			setMunicipiosDescar((last) => [
				...last.filter((item) => item.cod_municipio_ibge != codigoIbge),
				{
					cod_municipio_ibge: codigoIbge,
					municipio: municipiosDestino.find(
						(municipio) => municipio.id == codigoIbge
					).nome,
					nfe: [
						{
							chave: chaveNfe,
							unidades_transporte: [
								{
									tipo: "1",
									identificacao: body.modal.rodo.veiculo.tracao.placa,
									// , rateio: "3.50"
									// , lacres: ["00000003", "00000004"]
									unidade_carga: [
										{
											tipo: "1",
											identificacao: body.modal.rodo.veiculo.tracao.placa,
											// , lacres: ["00000003","00000004"]
											// , rateio: "3.50"
										},
									],
								},
							],
						},
					],
				},
			]);
		}

		setPedidos((last) => [
			...last.filter((item) => item.chave_nfe != chaveNfe),
			...last
				.filter((item) => item.chave_nfe == chaveNfe)
				.map((item) => ({ ...item, codigo_ibge: codigoIbge })),
		]);
		formRef.current.municipio_descarregamento.value = "";
		formRef.current.chave_nfe.value = "";
	};

	const handlerAdicionarPercurso = (e) => {
		const codigoIbge = formRef.current.trajeto.value;

		if (!codigoIbge)
			return setError({
				flag: true,
				title: "Erro",
				list: "Selecione a UF do Percurso",
			});
		if (body.identificacao.percurso.trajeto.find((item) => item == codigoIbge))
			return setError({
				flag: true,
				title: "Erro",
				list: "UF ja selecionada no percurso",
			});
		setBody((last) => ({
			...last,
			identificacao: {
				...last.identificacao,
				percurso: {
					...last.identificacao.percurso,
					trajeto: [...last.identificacao.percurso.trajeto, codigoIbge],
				},
			},
		}));
		formRef.current.trajeto.value = "";
	};

	useEffect(() => {
		axios
			.get(
				`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${body.identificacao.percurso.fim}/municipios`
			)
			.then(async (response) => {
				setMunicipiosDestino(response.data);
			});
	}, [body.identificacao.percurso.fim]);

	useEffect(() => {
		axios
			.get("https://servicodados.ibge.gov.br/api/v1/localidades/estados")
			.then((response) => {
				setEstados(
					response.data.sort((a, b) => {
						if (a.sigla > b.sigla) return 1;
						if (a.sigla < b.sigla) return -1;
						return 0;
					})
				);
				setEstadosDestino(
					response.data.sort((a, b) => {
						if (a.sigla > b.sigla) return 1;
						if (a.sigla < b.sigla) return -1;
						return 0;
					})
				);
			});
		axios
			.get(
				"https://servicodados.ibge.gov.br/api/v1/localidades/estados/MT/municipios"
			)
			.then(async (response) => {
				setMunicipios(response.data);
				const municipiosF = response.data;

				// primeiro as informações de identificação de carga -> identificacao
				const cidadeOrigem = user.rotasList.data.find(
					(rota) => rota.rota === props.body.rota && rota.step == 1
				);
				const municipio = response.data.find(
					(municipio) => municipio.nome == cidadeOrigem?.cidade
				);

				const [dia, mes, ano] = props.body.data_saida.split("/");
				// Informações de viagem -> modal
				// Informações de viagem -> docs
				// Informações de viagem -> seguro
				// Informações de viagem -> totalizadores
				const romaneiosGet = await getData(
					verificaTipoUsuario(user) + "cargas/pedidos/carga",
					props.body.id
				);
				// Informações de viagem -> lacres
				// Informações de viagem -> autorizados
				// Informações de viagem -> informações adicionais

				setPedidos(romaneiosGet.data);

				const municipiosDscarga = romaneiosGet.data.map((item) => {
					const muni = municipiosF.find(
						(municipio) =>
							String(municipio.nome)
								.normalize("NFD")
								.replace(/[\u0300-\u036f]/g, "")
								.toLowerCase() ==
							String(item.cidade)
								.normalize("NFD")
								.replace(/[\u0300-\u036f]/g, "")
								.toLowerCase()
					);
					return {
						...item,
						municipio: muni?.nome ?? null,
						codigo_ibge: muni?.id ?? null,
						chave: item.chave_nfe,
					};
				});
				setPedidos(municipiosDscarga);

				const municipiosDiferentes = municipiosDscarga
					.filter(
						(i, n, s) => s.findIndex((o) => o.municipio === i.municipio) == n
					)
					.filter((item) => item.municipio);

				const final = municipiosDiferentes.map((item) => ({
					cod_municipio_ibge: "" + item.codigo_ibge,
					municipio: item.municipio,
					nfe: [
						...municipiosDscarga
							.filter((item2) => item.municipio === item2.municipio)
							.map((item2) => ({
								chave: item2.chave,
								unidades_transporte: [
									{
										tipo: "1",
										identificacao: body.modal.rodo.veiculo.tracao.placa,
										unidade_carga: [
											{
												tipo: "1",
												identificacao: body.modal.rodo.veiculo.tracao.placa,
											},
										],
									},
								],
							})),
					],
				}));
				setMunicipiosDescar(final);

				setBody((last) => ({
					...last,
					identificacao: {
						...last.identificacao,
						inicio_viagem: props.body.data_saida
							? `${ano}-${mes}-${dia}`
							: null,
						municipio_carregamento: {
							codigo_ibge: `${municipio?.id}`,
							nome: municipio?.nome,
						},
					},
					modal: {
						rodo: {
							...last.modal.rodo,
							veiculo: {
								tracao: {
									...last.modal.rodo.veiculo.tracao,
									tara: `${Number(caminhao.tara * 1000)
										.toLocaleString("pt-BR")
										.replace(".", "")}`,
									placa: caminhao.placa,
									capacidade_kg: `${Number(caminhao.capacidade * 1000)
										.toLocaleString("pt-BR")
										.replace(".", "")}`,
									condutor: {
										...last.modal.rodo.veiculo.tracao.condutor,
									},
								},
							},
						},
					},
					totalizadores: {
						unidade_peso: "01",
						nfe: `${props.body.qtde_pedidos}`,
						peso_carga: `${Number(
							romaneiosGet.data.reduce((p, c) => p + c.peso_nf, 0)
						).toFixed(2)}`,
						valor_carga: `${Number(
							romaneiosGet.data.reduce((p, c) => p + c.valor_total, 0)
						).toFixed(2)}`,
					},
					// docs: [
					//     ...romaneiosGet.data.map((pedido, index) => ({
					//         // cod_municipio_ibge: `510795${index}`,
					//         // municipio: pedido.cidade,
					//         nfe: [{
					//                 chave: pedido.chave_nfe
					//                 , unidades_transporte: [{
					//                     tipo: "1"
					//                     , identificacao: "AVK-9A88"
					//                     , rateio: "3.50"
					//                     , lacres: ["00000003","00000004"],
					//                     unidade_carga: [{
					//                             tipo: "1",
					//                             identificacao: "01234567890123456789",
					//                             lacres: ["00000003","00000004"],
					//                             rateio: "3.50"
					//                     }]
					//             }]
					//         }]
					//     }))
					// ]
				}));

				setFirstLoading(false);
			});
	}, []);

	const onClickRemoveFilter = (e) => {
		e.preventDefault();
		const value = e.target.parentNode.firstElementChild.innerText;
		setBody((last) => ({
			...last,
			identificacao: {
				...last.identificacao,
				percurso: {
					...last.identificacao.percurso,
					trajeto: last.identificacao.percurso.trajeto.filter(
						(item) => item != value
					),
				},
			},
		}));
	};

	return (
		<>
			<Modal
				show={props.show}
				onHide={handlerSimpleClose}
				backdrop="static"
				keyboard={false}
				centered
				size="md"
			>
				<Modal.Header closeButton>
					<Modal.Title>
						Gerar Manifesto <br></br>Carga N° {props.body.id} -{" "}
						{props.body.nome}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form ref={formRef}>
						<div className="card-header mt-3 d-flex align-items-center">
							<h4 className="m-0">Viagem </h4>
							{firstLoading ? <Spinner animation="border" size="sm" /> : ""}
						</div>
						<Row className="mb-2">
							<Col sm="4">
								<Form.Label>Inicio da Viagem:</Form.Label>
							</Col>
							<Col>
								<Form.Control
									type="date"
									name="inicio_viagem"
									defaultValue={body.identificacao.inicio_viagem}
									onChange={onChangeInput}
								/>
							</Col>
						</Row>
						<Row className="mb-2">
							<Col sm="4">
								<Form.Label>Município Carregamento:</Form.Label>
							</Col>
							<Col>
								<Form.Select
									type="text"
									name="municipio_carregamento"
									onChange={onChangeInput}
									value={
										body?.identificacao?.municipio_carregamento?.codigo_ibge
									}
								>
									<option value=""></option>
									{municipios.map((item, key) => (
										<option value={item.id} key={key}>
											{item.nome}
										</option>
									))}
								</Form.Select>
							</Col>
						</Row>
						<div className="card-header mt-3 d-flex align-items-center">
							<h4 className="m-0">Caminhão </h4>
							{firstLoading ? <Spinner animation="border" size="sm" /> : ""}
						</div>
						<Row className="mb-2">
							<Col sm="3">
								<Form.Label>Placa:</Form.Label>
								<Form.Control
									type="text"
									name="placa"
									disabled
									value={body.modal.rodo.veiculo.tracao.placa}
								/>
							</Col>
							<Col sm="3">
								<Form.Label>UF:</Form.Label>
								<Form.Select
									type="text"
									name="uf_placa_caminhao"
									onChange={onChangeInput}
									value={body.modal.rodo.veiculo.tracao.uf}
								>
									<option value=""></option>
									{estados.map((item, key) => (
										<option value={item.sigla} key={key}>
											{item.sigla}
										</option>
									))}
								</Form.Select>
							</Col>
							<Col sm="3">
								<Form.Label>Tara:</Form.Label>
								<Form.Control
									type="text"
									name="placa"
									disabled
									value={body.modal.rodo.veiculo.tracao.tara}
								/>
							</Col>
							<Col sm="3">
								<Form.Label>Capacidade:</Form.Label>
								<Form.Control
									type="text"
									name="placa"
									disabled
									value={body.modal.rodo.veiculo.tracao.capacidade_kg}
								/>
							</Col>
						</Row>

						<div className="card-header mt-3 d-flex align-items-center">
							<h4 className="m-0">Condutor </h4>
							{firstLoading ? <Spinner animation="border" size="sm" /> : ""}
						</div>

						<Row className="mb-2">
							<Col sm="6">
								<Form.Label>CPF:</Form.Label>
								<Form.Control
									type="text"
									name="cpf_condutor"
									disabled
									value={body.modal.rodo.veiculo.tracao.condutor.cpf}
								/>
							</Col>
							<Col sm="6">
								<Form.Label>Nome:</Form.Label>
								<Form.Control
									type="text"
									name="nome_condutor"
									disabled
									value={body.modal.rodo.veiculo.tracao.condutor.nome}
								/>
							</Col>
						</Row>

						<div className="card-header mt-3 d-flex align-items-center">
							<h4 className="m-0">Notas Fiscais e Destinos </h4>
							{firstLoading ? <Spinner animation="border" size="sm" /> : ""}
						</div>

						<Row className="mb-2">
							<Col sm="12" className="mb-2">
								<Row className="mb-2">
									<Col sm="4">
										<Form.Label>Município Descarregamento:</Form.Label>
									</Col>
									<Col sm="3">
										<Form.Select
											type="text"
											name="uf_fim_descarregamento"
											onChange={onChangeInput}
											value={body.identificacao.percurso.fim}
										>
											<option value=""></option>
											{estadosDestino.map((item, key) => (
												<option value={item.sigla} key={key}>
													{item.sigla}
												</option>
											))}
										</Form.Select>
									</Col>
									<Col>
										<Form.Select
											type="text"
											name="municipio_descarregamento"
											// onChange={onChangeInputExtraBody}
										>
											<option value=""></option>
											{municipiosDestino.map((item, key) => (
												<option value={item.id} key={key}>
													{item.nome}
												</option>
											))}
										</Form.Select>
									</Col>
								</Row>
								<Row className="align-items-baseline">
									<Col sm="8">
										<Form.Label>Pedido:</Form.Label>
										<Form.Select name="chave_nfe">
											<option></option>
											{pedidos
												.filter((item) => !item.codigo_ibge)
												.map((i, k) => (
													<option
														key={k}
														disabled={i?.codigo_ibge ? true : false}
														value={i.chave_nfe}
													>
														{i.numero_pedido} - {i.nome_razao}: {i.cidade}
													</option>
												))}
										</Form.Select>
									</Col>
									<Col sm="4">
										<Form.Label style={{ color: "transparent" }}>
											Only
										</Form.Label>
										<Button
											variant="primary"
											onClick={handlerAddPedidoMunicipio}
										>
											+Add
										</Button>
									</Col>
								</Row>
								<br />
								<br />
								<Row className="align-items-baseline">
									<Col sm="4">
										<Form.Label>Percurso:</Form.Label>
										<Form.Select name="trajeto">
											{estadosDestino.map((item, key) => (
												<option value={item.sigla} key={key}>
													{item.sigla}
												</option>
											))}
										</Form.Select>
									</Col>
									<Col sm="4">
										<Form.Label style={{ color: "transparent" }}>
											Only
										</Form.Label>
										<Button
											variant="primary"
											onClick={handlerAdicionarPercurso}
										>
											+Add
										</Button>
									</Col>
									<Col sm="12">
										{body.identificacao.percurso.trajeto.map((key, index) => {
											return (
												<span className="filters-card" key={index}>
													<span className="filters">
														<span className="title">{key}</span>
														<i
															className="bi bi-x-lg"
															onClick={onClickRemoveFilter}
														></i>
													</span>
												</span>
											);
										})}
									</Col>
								</Row>
								<Row className="mt-4">
									{municipiosDescar.map((item, key) => {
										return (
											<div className="" key={key}>
												<h6 className="card-header  mb-1 my-4">
													{item.municipio}
												</h6>
												{item.nfe.map((item2, key2) => (
													<div key={key2}>- {item2.chave}</div>
												))}
											</div>
										);
									})}
								</Row>
							</Col>
						</Row>
						<div className="card-header mt-3 d-flex align-items-center">
							<h4 className="m-0">Totalizadores</h4>
							{firstLoading ? <Spinner animation="border" size="sm" /> : ""}
						</div>

						<Row className="mb-2">
							<Col sm="4">
								<Form.Label>NFe:</Form.Label>
								<Form.Control
									type="text"
									name="cpf_condutor"
									disabled
									value={body.totalizadores.nfe}
								/>
							</Col>
							<Col sm="4">
								<Form.Label>Valor da Carga:</Form.Label>
								<Form.Control
									type="text"
									name="cpf_condutor"
									disabled
									value={valorMoeda(body.totalizadores.valor_carga)}
								/>
							</Col>
							<Col sm="4">
								<Form.Label>Peso da Carga:</Form.Label>
								<Form.Control
									type="text"
									name="cpf_condutor"
									disabled
									value={numerosEditadoBR(body.totalizadores.peso_carga)}
								/>
							</Col>
						</Row>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Row style={{ width: "100%" }}>
						<Col>
							<Button variant={loading.style} onClick={submitDataEdit}>
								{loading.isLoading ? (
									<Loading msg={loading.msg} />
								) : (
									loading.msg
								)}
							</Button>
						</Col>
						<Col>
							<Button variant="secondary" onClick={handlerSimpleClose}>
								Cancelar
							</Button>
						</Col>
					</Row>
				</Modal.Footer>
				{error ? (
					<ModalError show={error.flag} setShow={setError} {...error} />
				) : (
					""
				)}
			</Modal>
		</>
	);
}
