import { Fragment, createRef, useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import Loading from "../../loading/Loading";
import {
	apenasNumeros,
	createTransferencia,
	getTransferencias,
	insertProdutosTransferencia,
	numerosDecimal3,
} from "../../../services/Servicos";
import { useSelector } from "react-redux";
import {
	filtroDinamicoArrays,
	getErrorMessage,
	removerDadosDuplicados,
	telefoneMask,
} from "../../../services/helpers";
import TransferenciasLayout from "../layout/TransferenciasLayout";
import ModalError from "../../modal/ModalError";
import HeadTable from "../../table/head/HeadRow";

import * as XLSX from "xlsx/xlsx.mjs";
import ModalErrorPretty from "../../modal/ModalErrorPretty";
import ModalSuccessPretty from "../../modal/ModalSuccessPretty";

const tableRef = createRef();
const tbodyRef = createRef();
const headList = [
	"admin",
	"index",
	"codigo",
	"descricao",
	"unidade",
	"quantidade",
	"peso",
	"peso_unitario",
	"qr_sku",
];

export default function TransferenciasCadastro() {
	const userS = useSelector((state) => state.userSlice);
	const funcionarios = userS.funcionariosList.data;

	const [transferencia, setTransferencia] = useState({
		descricao: "",
		tipo: "",
		cpf_responsavel: "",
		nome_responsavel: "",
		id_filial: userS.id_filial,
		responsavel: userS.name,
		emissao: new Date().toLocaleDateString(),
		status: 0,
	});

	const [percentual, setPercentual] = useState(0);
	const [listaProdutos, setListaProdutos] = useState([]);
	const [error, setError] = useState({ show: false, message: ""});
	const [success, setSuccess] = useState({ show: false, message: ""});

	const [loading, setLoading] = useState({
		flag: false,
		style: "primary",
		msg: "Salvar",
	});

	const onChangeFile = (e) => {
		e.preventDefault();

		var files = e.target.files;
		var i, f;
		for (i = 0; i != files.length; ++i) {
			var reader = new FileReader();
			f = files[i];
			reader.readAsBinaryString(f);
			// var name = f.name;
			reader.onload = async function (e) {
				var data = e.target.result;

				var workbook = XLSX.read(data, { type: "binary" });
				var first_sheet_name = workbook.SheetNames[0];
				/* Get worksheet */
				var worksheet = workbook.Sheets[first_sheet_name];
				const arrayReceived = XLSX.utils.sheet_to_json(worksheet, {
					raw: true,
				});
				for await (let jsonR of arrayReceived) {
					Object.keys(jsonR).forEach((key) => {
						if (key == "quantidade") {
							jsonR.qtde_planejada = jsonR.quantidade;
							return;
						}
						if (typeof jsonR[key] !== "string") return;
						jsonR[key] = String(jsonR[key]).toUpperCase();
					});

					setListaProdutos((last) => [
						...last,
						{
							...jsonR,
							qr_sku: `T${listaProdutos.length}-O${transferencia.id_filial}-D${
								transferencia.id_filial_destino
							}-P${jsonR.codigo}-D${String(
								new Date().toLocaleDateString("pt-BR")
							).replaceAll("/", "")}`,
							id_filial: transferencia.id_filial, // filial origem
							peso_unitario: Number(jsonR.peso / jsonR.qtde_planejada).toFixed(
								2
							),
						},
					]);
				}

				/* DO SOMETHING WITH workbook HERE */
			};
		}
	};

	const onChangeInput = (e) => {
		e.preventDefault();
		if (e.target.name == "nome_responsavel") {
			setTransferencia((last) => ({
				...last,
				cpf_responsavel: funcionarios.find(
					(funcionario) => funcionario.name == e.target.value
				)?.cpf,
			}));
		} else if (e.target.name == "telefone") {
			setTransferencia((last) => ({
				...last,
				[e.target.name]: telefoneMask(e.target.value),
			}));
			return;
		}
		setTransferencia((last) => ({
			...last,
			[e.target.name]: String(e.target.value).toUpperCase(),
		}));
	};

	const showPrittierEnd = async () => {
		setSuccess({
			show: true, message:"Transferência Cadastrada com sucesso"
		})
		setTimeout(() => {
			window.location.replace("/transferencias");
		}, 1500)
	};

	const handlerAddProdutos = async (transferencia) => {
		let qtd = 1;

		const salvarProdutos = listaProdutos
			.map((item) => {
				return { ...item, descricao: item.descricao.trim() };
			})
			.sort((a, b) => {
				if (a.descricao > b.descricao) return 1;
				if (a.descricao < b.descricao) return -1;
				return 0;
			});

		for await (const produto_ of salvarProdutos) {
			insertProdutosTransferencia({ ...produto_, transferencia }, userS.tipo)
				.then((resp) => {})
				.catch((err) => {
					setLoading({
						flag: false,
						style: "primary",
						msg: "Salvar",
					});

					setError({ show: true, message: err });
				})
				.finally(() => {
					setPercentual(Number((qtd / listaProdutos.length) * 100).toFixed(1));
					qtd++;
					if (qtd >= listaProdutos.length) {
						setLoading((last) => ({
							...last,
							flag: false,
							msg: `Atualizando Pedidos`,
						}));

						showPrittierEnd();
					}
				});
		}
	};

	const submitHandler = (e) => {
		e.preventDefault();

		if (!listaProdutos.length)
			return setError({
				show: true,
				message: "Não é possivel criar um pedido de transferencia sem produtos",
			});
		setLoading({
			flag: true,
			style: "warning",
			msg: "salvando",
		});

		createTransferencia(userS.tipo, {
			...transferencia,
			peso_total: listaProdutos.reduce((p, c) => p + c.peso, 0),
			emissao: new Date().toISOString(),
		})
			.then((response) => {
				setLoading({
					flag: false,
					style: "info",
					msg: "Adiconando Produtos",
				});
				handlerAddProdutos("TRANSF_" + response.id);
			})
			.catch((error) => {
				setError({ show: true, message: getErrorMessage(error) });
				setLoading({
					flag: false,
					style: "primary",
					msg: "Salvar",
				});
			})
	};

	const exluirProduto = (produto) => {
		setListaProdutos((last) =>
			last.filter((item) => item.qr_sku !== produto.qr_sku)
		);
	};

	const [indexList, setIndexList] = useState(10);
	const [filteredTable, setFilteredTable] = useState(false);
	const [filters, setFilters] = useState([]);
	const [filtersNew, setFiltersNew] = useState({});

	useEffect(() => {
		if (!filters.length) {
			setIndexList(10);
			setFilteredTable(false);
			return;
		}

		let testeFilters = {};

		filters.forEach((ele) => {
			// testeFilters[arguments[ele]]
			const param = Object.getOwnPropertyNames(ele)[0];
			testeFilters[param] = [];
		});

		filters.forEach((ele) => {
			// testeFilters[arguments[ele]]
			const param = Object.getOwnPropertyNames(ele)[0];
			// testeFilters[param].push(ele[param])
			testeFilters[param].push(String(ele[param]).toLowerCase());

			if (String(ele[param]).toLowerCase() == "false") {
				testeFilters[param].push("null");
			}
		});

		let arrayRetorno = [listaProdutos];

		Object.keys(testeFilters).forEach((key, index) => {
			arrayRetorno.push(
				filtroDinamicoArrays(
					testeFilters,
					key,
					arrayRetorno[arrayRetorno.length - 1]
				)
			);
		});

		const arrayF = removerDadosDuplicados(
			arrayRetorno[arrayRetorno.length - 1],
			"id"
		);
		setFilteredTable(arrayF.reverse());
	}, [filters]);

	useEffect(() => {
		if (userS.tipo.toLowerCase() != "empresa" && userS.id_filial != 2)
			return window.location.replace("/dashboard");
	}, []);
	return (
		<TransferenciasLayout>
			<Form onSubmit={submitHandler}>
				<div className="card-header">
					<h5>Dados do Pedido</h5>
				</div>
				<Row className="my-2">
					<Col sm="12" md="4" className="mb-3">
						<Form.Label>Filial Origem:</Form.Label>
						<Form.Select
							type="text"
							placeholder=""
							name="id_filial"
							value={transferencia.id_filial}
							onChange={onChangeInput}
							disabled={
								userS.id_filial != 2 && userS.tipo != "empresa" ? true : false
							}
							required
						>
							<option value=""></option>
							<option value="2">Fermat Matriz</option>
							{userS.filiaisList.data.map((ele, key) => {
								return (
									<option value={ele.idFilial} key={key}>
										{ele.idFilial} - {ele.identificacaoInterna}
									</option>
								);
							})}
						</Form.Select>
					</Col>
					<Col sm="12" md="4" className="mb-3">
						<Form.Label>Filial Destino:</Form.Label>
						<Form.Select
							type="text"
							placeholder=""
							name="id_filial_destino"
							value={transferencia.id_filial_destino}
							onChange={onChangeInput}
							required
						>
							<option value=""></option>
							<option value="2">Fermat Matriz</option>
							{userS.filiaisList.data.map((ele, key) => {
								return (
									<option value={ele.idFilial} key={key}>
										{ele.idFilial} - {ele.identificacaoInterna}
									</option>
								);
							})}
						</Form.Select>
					</Col>
					<Col sm="12" md="4" className="mb-3">
						<Form.Label>Descricao:</Form.Label>
						<Form.Control
							type="text"
							name="descricao"
							placeholder=""
							value={transferencia.descricao}
							onChange={onChangeInput}
						/>
					</Col>
					<Col sm="12" md="4" className="mb-3">
						<Form.Label>Responsável:</Form.Label>
						<Form.Control
							type="text"
							value={transferencia.responsavel}
							disabled
						/>
					</Col>
					<Col sm="12" md="4" className="mb-3">
						<Form.Label>Data de Emissão:</Form.Label>
						<Form.Control type="text" value={transferencia.emissao} disabled />
					</Col>
					<Col sm="12" md="4" className="mb-3">
						<Form.Label>Arquivo .xlsx:</Form.Label>
						<Form.Control
							type="file"
							name="excel"
							onChange={onChangeFile}
							accept=".xlsx, .docx"
							required
							disabled={
								!transferencia.id_filial || !transferencia.id_filial_destino
									? true
									: false
							}
						/>
					</Col>
				</Row>
				<Row>
					<Col sm="12" md="10">
						{/* <ScrollbarLeft tableRef={tableRef} coluna2={4} maxima_distancia={150}/> */}
						<div
							className=" responsive-table"
							ref={tableRef}
							style={{
								position: "relative",
								height: "500",
								border: "0.1px solid #0000002f",
							}}
						>
							<Table hover size="sm">
								<thead style={{ zIndex: "49" }}>
									<HeadTable
										arrayValues={[...listaProdutos]}
										headerList={headList}
										setArrayFilters={setFiltersNew}
										arrayFilters={filtersNew}
										setFiltersOld={setFilters}
										filtersOld={filters}
									/>
								</thead>
								<tbody ref={tbodyRef}>
									{listaProdutos.reverse().map((item, index, s) => {
										return (
											<BodyRowProdutosTransferencias
												key={index}
												bodyList={item}
												headList={headList}
												index={index}
												tableLength={s.length}
												exluirProduto={exluirProduto}
											/>
										);
									})}
								</tbody>
							</Table>
						</div>
					</Col>
					<Col sm="12" md="2">
						<Button variant={loading.style} type="submit" className="mb-2">
							{loading.flag ? <Loading msg="Salvando" /> : loading.msg}
							{loading.style === "success" ? (
								<i className="bi bi-check"></i>
							) : (
								""
							)}
						</Button>
						<Button
							variant="secondary"
							type="reset"
							onClick={() => setListaProdutos([])}
						>
							Apagar
						</Button>
						{percentual > 0 ? <LoadingCircle percentual={percentual} /> : ""}
					</Col>
				</Row>
			</Form>

			{error.show ? (
				<ModalErrorPretty
					setShow={setError}
					{...error}
				/>
			) : (
				""
			)}
			{success.show ? (
				<ModalSuccessPretty
					setShow={setSuccess}
					{...success}
				/>
			) : (
				""
			)}
		</TransferenciasLayout>
	);
}

function BodyRowProdutosTransferencias({
	headList,
	bodyList,
	index,
	qr_sku = 0,
	exluirProduto = () => {},
}) {
	const userEmail = useSelector((state) => state.userSlice.email);
	const user = useSelector((state) => state.userSlice);
	const filiaisList = user.filiaisList.data;
	const transferencias = user.transferenciasList;
	const refRow = useRef();

	return (
		<Fragment>
			{
				<tr
					ref={refRow}
					className={
						bodyList.data_cancelamento
							? "bg-cancelado"
							: bodyList.planejado
							? "bg-planejado"
							: bodyList.conferido
							? "bg-conferido"
							: ""
					}
				>
					{headList.map((item, _index) => {
						// if(index > 19 ){
						//     return false
						// }

						if (item === "index") {
							return <td key={_index}> {index + 1}</td>;
						} else if (item.toLowerCase() === "admin") {
							return (
								<td key={_index}>
									<div className="admin" style={{ alignItems: "baseline" }}>
										<span className="excluir">
											<i
												className="bi bi-x"
												title="Cancelar Produto"
												onClick={(e) => exluirProduto(bodyList)}
											>
												{" "}
											</i>
										</span>
									</div>
								</td>
							);
						} else if (item === "id_filial") {
							return (
								<td key={_index}>
									{filiaisList.find(
										(item) => item.idFilial == bodyList.id_filial
									)?.identificacaoInterna ?? "NÃO INFORMADO"}
								</td>
							);
						} else if (item === "peso" || item === "peso_unitario") {
							return (
								<td key={_index}>
									{Number(bodyList[item]).toLocaleString("pt-BR")}
								</td>
							);
						} else if (item === "descricao") {
							return (
								<td
									key={_index}
									className={item}
									style={{ whiteSpace: "nowrap" }}
								>
									{" "}
									{bodyList[item]}
								</td>
							);
						}
						return (
							<td key={_index} className={item} style={{ minWidth: "100px" }}>
								{" "}
								{bodyList[item] ?? "NÃO"}
							</td>
						);
					})}
				</tr>
			}
		</Fragment>
	);
}

function LoadingCircle({ percentual = 0 }) {
	return (
		<div className="box mt-5">
			<div className="percentual">
				<svg>
					<circle cx="40" cy="40" r="40"></circle>
					<circle
						cx="40"
						cy="40"
						r="40"
						style={{
							strokeDashoffset: `calc(250 - (250 * ${percentual}) / 100)`,
						}}
					></circle>
				</svg>
				<div className="numero">
					<h2>
						{percentual}
						<span>%</span>
					</h2>
				</div>
			</div>
		</div>
	);
}
